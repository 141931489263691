import React, { useState, useContext, useEffect } from 'react';
import Loader from '../../Shared/Loader';
import { observer } from 'mobx-react';
import { sport_light, Placeholder } from '../../../assets/img';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import moment from 'moment';
import {
  formatDate,
  formatTime,
  priceFormatter,
  getTrainingType,
  getAthleteName,
} from '../../../utils/utilities';
import { TrainingAndLocationSelect, TrainerBanner, TrainingBadge } from '../../Shared';
import { toJS } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroller';
import ScrollUpButton from 'react-scroll-up-button';

const BookNow = ({ bookNow }) => (
  <button
    onClick={e => {
      e.stopPropagation();
      bookNow();
    }}
    className="primary-button w-full mt-3 md:w-24 md:mt-0 bg-highlight-color">
    Book now
  </button>
);

const ScheduleInfo = ({
  athlete,
  history,
  provided_services,
  booking_date,
  from_time,
  to_time,
  entry_color,
}) => {
  const [selectionModal, setSelectionModal] = useState(false);

  const getTrainings = () => {
    return provided_services.data.map((training, index, arr) => (
      <small className="primary-text-regular text-t-gray pr-1 ">
        {training.attributes.name} {index < arr.length - 1 && '•'}
      </small>
    ));
  };

  const getDurations = () => {
    return provided_services.data.map(training => (
      <div className="flex flex-wrap items-center justify-center">
        <div className="flex items-center">
          <small style={{ fontSize: 11 }} className="text-t-gray font-sf-regular pr-2">
            {training.attributes.duration} min {'•'}
          </small>
        </div>
      </div>
    ));
  };

  const getPrices = () => {
    const pricesString = provided_services.data.reduce((result, training) => {
      result = [...result, priceFormatter(training.attributes.price)]
      return result
    }, [])

    return <small style={{ fontSize: 11 }} className="text-t-gray primary-text-regular pr-2">
      {pricesString.join(' • ')}
    </small>
  };

  const getLocations = () => {
    return provided_services.data.map(training => (
      <div className="flex flex-wrap items-center">
        {training.attributes.provided_service_locations.data.map((location, locationIndex) => (
          <small className="primary-text-regular opacity-75 pr-2">
            {location.attributes.location}
            {locationIndex !== training.attributes.provided_service_locations.data.length - 1 &&
              '•'}
          </small>
        ))}
      </div>
    ));
  };

  const bookNow = () => {
    setSelectionModal(true);
  };

  return (
    <div className="w-full pr-5 pl-5 rounded overflow-hidden shadow bg-white">
      <div className="p-2 border-b border-light-gray mr-1 flex flex-col items-center md:flex-row justify-between">
        <div className="flex flex-wrap items-center w-full">
          <div className="flex flex-col justify-center items-center md:w-1/12 mr-3">
            <span className="text-xs text-t-gray">{athlete.sport.name}</span>

            <TrainingBadge trainingType={getTrainingType(entry_color)} small />
          </div>

          <div
            className="flex w-3/4 flex-col md:flex-row flex-wrap border-l border-gray-700
                       border-dotted content-start md:items-center pl-5 justify-between">
            <div className="flex w-full md:w-1/2 md:flex-row flex-col items-center text-center md:text-left">
              <ProgressiveImage
                src={athlete.profile_image ? athlete.profile_image : Placeholder}
                placeholder={sport_light}>
                {src => (
                  <img src={src} className="h-20 w-20 rounded-full mb-2 border object-cover ml-6 md:ml-0" />
                )}
              </ProgressiveImage>

              <div className="flex flex-wrap flex-col ml-5 w-full">
                <span className="primary-text-regular text-lg">{getAthleteName(athlete)}</span>

                <div className="flex md:justify-start justify-center">
                  {athlete.position.map(position => (
                    <small className="primary-text-regular ml-1">{`${position} • `}</small>
                  ))}
                  <small className="primary-text-regular ml-1">{athlete.passing_year} {athlete.jersey_number && ' • '}</small>
                  {athlete.jersey_number && <small className="primary-text-regular ml-1">#{athlete.jersey_number}</small>}
                </div>

                <div className="flex md:justify-start justify-center">
                  {getTrainings()}
                </div>

                <span className="primary-text-regular">
                  {formatTime(from_time)} {' - '}
                  {formatTime(to_time)}
                </span>
                <span className="primary-text-regular">
                  {moment(formatDate(booking_date)).format('dddd, ll')}
                </span>
              </div>
            </div>

            <div className="flex w-full md:w-1/2 mt-3 md:mt-0 flex-col md:border-dotted md:border-l md:border-black pl-5">
              <div className="flex flex-wrap md:justify-start justify-center">
                {getDurations()}
                {getPrices()}
              </div>

              <div>
                {entry_color !== 'virtual_color' ? (
                  <div className="flex flex-wrap md:justify-start justify-center">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ color: 'gray' }}
                      size="xs"
                      className="mr-1"
                    />
                    {getLocations()}
                  </div>
                ) : (
                  <div className='flex flex-wrap md:justify-start justify-center'>
                    <small className="opacity-50 primary-text-regular">Virtual training</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full md:w-auto justify-center md:items-center">
          <BookNow id={athlete.id} history={history} bookNow={bookNow} />
        </div>
      </div>

      {selectionModal && (
        <TrainingAndLocationSelect
          visible={selectionModal}
          loader={false}
          slug={athlete.slug}
          trainings={provided_services}
          closeModal={() => setSelectionModal(false)}
        />
      )}
    </div>
  );
};

function FullSchedule(props) {
  const store = useContext(StoreContext);
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  let athelete = toJS(store.atheleteStore.athelete);
  let athleteSchedule = toJS(store.atheleteStore.athleteSchedule);
  let athleteSchedulePagy = toJS(store.atheleteStore.athleteSchedulePagy);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    async function getFullSchedule() {
      store.atheleteStore.getAthelete(params.id);
      setLoader(true);
      await store.atheleteStore.getAthleteSchedule(1, params.id);
      setLoader(false);
    }
    getFullSchedule();
  }, []);

  const loadMoreSchedule = async () => {
    if (athleteSchedulePagy && athleteSchedulePagy.next) {
      await store.atheleteStore.getAthleteSchedule(athleteSchedulePagy.next, params.id);
    }
  };

  return (
    <div className="bg-main-background min-h-screen flex flex-col items-center">
      <div className="flex flex-col px-0 w-full md:w-3/4 pt-24">
        {athelete && <TrainerBanner athlete={athelete} />}
      </div>

      {!loader ? (
        <div className="flex flex-col px-0 w-full md:w-3/4 pt-4">
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMoreSchedule}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            hasMore={athleteSchedulePagy && athleteSchedulePagy.next}>
            {athelete &&
              athleteSchedule.map(
                ({
                  attributes: { provided_services, booking_date, from_time, to_time, entry_color },
                }) => {
                  return (
                    <ScheduleInfo
                      provided_services={provided_services}
                      booking_date={booking_date}
                      from_time={from_time}
                      to_time={to_time}
                      entry_color={entry_color}
                      athlete={athelete}
                      history={history}
                    />
                  );
                }
              )}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <ScrollUpButton />
    </div>
  );
}

export default observer(FullSchedule);
