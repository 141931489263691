import React, { useState } from 'react';
import { InputLabel } from '../../Shared';
import 'react-datepicker/dist/react-datepicker.css';
import { appendSymInStart, validateSocialHandles } from '../../../utils/utilities';
import { SocialLink } from '../../Shared';

function SocialInfo({ provider, onChange, currentUser }) {
  const [error, setError] = useState('');

  return (
    <div>
      <div className="pb-16">
        <div className="mt-4">
          <div className="flex w-full justify-end">
            <small className="self-end text-red-600">
              {error ? `Invalid ${error} username` : ''}
            </small>
          </div>

          <SocialLink
            id="facebook"
            value={
              provider.facebookLink !== null
                ? provider.facebookLink
                : currentUser && currentUser.facebook
            }
            onChange={e => {
              let result = validateSocialHandles(e.target.value);
              setError(!result ? 'facebook' : '');
              onChange('facebookLink', e.target.value, !result);
            }}
          />

          <SocialLink
            id="twitter"
            value={
              provider.twitterLink !== null
                ? provider.twitterLink
                : currentUser && currentUser.twitter
            }
            onChange={e => {
              let result = validateSocialHandles(e.target.value);
              setError(!result ? 'twitter' : '');
              onChange('twitterLink', e.target.value, !result);
            }}
          />

          <SocialLink
            id="instagram"
            value={
              provider.instagramLink !== null
                ? provider.instagramLink
                : currentUser && currentUser.instagram
            }
            onChange={e => {
              let result = validateSocialHandles(e.target.value);
              setError(!result ? 'instagram' : '');
              onChange('instagramLink', e.target.value, !result);
            }}
          />

          <SocialLink
            id="tiktok"
            value={
              provider.tiktokLink !== null ? provider.tiktokLink : currentUser && currentUser.tiktok
            }
            onChange={e => {
              onChange('tiktokLink', appendSymInStart(e.target.value, '@'), true);
            }}
          />

          <SocialLink
            id="website"
            value={
              provider.websiteLink !== null
                ? provider.websiteLink
                : currentUser && currentUser.website
            }
            placeholder="Website Link"
            onChange={e => onChange('websiteLink', e.target.value)}
          />

          <div className="mt-2">
            <InputLabel label="About" />

            <textarea
              className="text-input focus:border-primary-color"
              rows={4}
              value={provider.about !== null ? provider.about : currentUser && currentUser.about}
              onChange={e => onChange('about', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialInfo;
