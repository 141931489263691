import api from 'store/api';
import { decorate, observable, action, toJS } from 'mobx';
import { client } from 'store';
import qs from 'qs';
import { persist } from 'mobx-persist';
import { errorHandler } from 'utils/middlewares/errorHandler';

class AtheleteStore {
  atheletes = [];
  athelete = null;
  athletePagy = {};
  bookedAthelete = null;
  bookings = [];
  requestedBookings = [];
  school = null;
  athleteSchedule = [];
  athleteSchedulePagy = null;

  clearAthelete = () => {
    this.athelete = null;
  };

  saveRequestedBookings = id => {
    this.requestedBookings.push(id);
  };

  setRequestedBookings = requestedBookings => {
    this.requestedBookings = requestedBookings;
  };

  clearRequestedBookings = () => {
    this.requestedBookings = [];
  };

  deleteBookingRequestEntry = id => {
    return client()
      .delete(`${api.bookingRequestEntry()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getAtheletes = async (page, payload) => {
    let params = payload;
    params.page = page;
    params.per_page = 10;

    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.atheletes()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (page > 1) this.atheletes = this.atheletes.concat(data.data);
          else this.atheletes = data.data;
          this.athletePagy = data.pagy;
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  groupItemBy = (array, property) => {
    var hash = {},
      props = property.split('.');
    for (var i = 0; i < array.length; i++) {
      var key = props.reduce(function (acc, prop) {
        return acc && acc[prop];
      }, array[i]);
      if (!hash[key]) hash[key] = [];
      hash[key].push(array[i]);
    }
    return hash;
  };

  getAthletesBySchool = async payload => {
    let paramsString = qs.stringify(payload);

    return client()
      .get(`${api.atheletes()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return {
            data: this.groupItemBy(data.data, 'attributes.available_on'),
            pagy: data.pagy,
          };
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getCollegeSchedule = async (slug, page = 1, lastDate, perPage = 10) => {
    let payload = {
      page,
      per_page: perPage,
      last_date: lastDate
    };
    let paramsString = qs.stringify(payload);

    return client()
      .get(`${api.college_schedules(slug)}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return {
            data: data.schedules,
            pagy: data.pagy,
          };
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  }

  saveBooking = (atheleteId, bookingId) => {
    this.bookings.push({ atheleteId, bookingId });
  };

  setBookingsLocal = bookings => {
    this.bookings = bookings;
  };

  getAthelete = async (slug, serviceSlug) => {
    return client()
      .get(
        serviceSlug
          ? `${api.atheletes()}/${slug}?provided_service=${serviceSlug}`
          : `${api.atheletes()}/${slug}`
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          this.athelete = data.data && data.data.attributes;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getBookedRequest = async bookingId => {
    return client()
      .get(`${api.bookingRequest()}/${bookingId}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.bookedAthelete = data.data && data.data.attributes;

          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  changeRequestedBookingStatus = async (bookingId, cardId, note) => {
    return client()
      .put(api.changeRequestedBookingStatus(bookingId), {
        booking_request: {
          notes: note,
        },
      })
      .then(
        action('fetchSuccess', ({ data }) => {
          if (data.data && data.data.attributes) {
            this.bookedAthelete = data.data.attributes;
            if (data.data.attributes.payment.data)
              this.createCharge(
                data.data.attributes.payment.data.id,
                parseFloat(data.data.attributes.amount),
                cardId
              );
          }

          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  createCharge = async (paymentId, amount, cardId) => {
    return client()
      .post(api.createCharge(paymentId), {
        payment: {
          amount,
          card_id: cardId,
        },
      })
      .then(
        action('fetchSuccess', ({ data }) => {
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  createBookingRequest = async (payload, cardId) => {
    return client()
      .post(api.bookingRequest(), { ...payload, card_id: cardId })
      .then(
        action('fetchSuccess', ({ data }) => {
          this.bookedAthelete = data.data && data.data.attributes;
          return data.data
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  submitReview = async payload => {
    return client()
      .post(api.submitReview(), payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  updateBookingRequest = async (payload, bookingId) => {
    return client()
      .put(`${api.bookingRequest()}/${bookingId}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.bookedAthelete = data.data && data.data.attributes;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  checkout = async (id, payload) => {
    return client()
      .post(api.checkout(id), payload)
      .then(
        action('fetchSuccess', ({ data }) => { }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getSchool = async slug => {
    return client()
      .get(`${api.college()}/${slug}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.school = data.data && data.data.attributes;
          this.athletePagy = {};
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getAthleteSchedule = async (page = 1, athleteId) => {
    let payload = {
      page,
      per_page: 10,
      id: athleteId,
    };
    let paramsString = qs.stringify(payload);
    return client()
      .get(`${api.entries()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          let schedule = toJS(this.athleteSchedule);
          schedule = page > 1 ? schedule.concat(data.data) : data.data;
          this.athleteSchedule = schedule;
          this.athleteSchedulePagy = data.pagy;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };
}

decorate(AtheleteStore, {
  atheletes: [persist('object'), observable],
  athelete: [persist('object'), observable],
  athletePagy: [persist('object'), observable],
  bookings: [persist('list'), observable],
  bookedAthelete: [persist('object'), observable],
  school: [persist('object'), observable],
  requestedBookings: [persist('list'), observable],
  athleteSchedule: [persist('list'), observable],
  athleteSchedulePagy: [persist('object'), observable],
  clearAthelete: action,
});

export default AtheleteStore;
