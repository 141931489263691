import React, { Component, useState } from 'react';

const ToolTip = (props) => {
  const [tooltipStatus, setTooltipStatus] = useState(0);
  return (
    <>
      <div className="w-full md:w-auto md:flex items-center justify-center">
        {/*Code Block for white tooltip starts*/}
        <div className="relative mt-3 md:mt-0" onMouseEnter={() => setTooltipStatus(1)} onMouseLeave={() => setTooltipStatus(0)}>
          <div className="w-full mr-2 cursor-pointer">
            {props.children}
          </div>
          {tooltipStatus == 1 && (
            <div role="tooltip" className="z-20 md:-mt-20 mr-3 w-64 absolute right-0 mr-40 shadow-lg bg-white p-4 rounded">
              <svg className="absolute right-0 hidden md:visible -mr-2 bottom-0 top-0 h-full" width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFF">
                    <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                      <g id="Group-2" transform="translate(24.000000, 0.000000)">
                        <polygon id="Triangle" transform="translate(4.500000, 62.000000) rotate(90.000000) translate(-4.500000, -62.000000) " points="4.5 57.5 12.5 66.5 -3.5 66.5" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <p className="text-sm font-bold text-gray-800 pb-1">{props.tooltipHeading}</p>
              <p className="text-xs leading-4 text-gray-600 pb-3">{props.tooltipText}</p>
            </div>
          )}{" "}
        </div>
        {/*Code Block for white tooltip ends*/}
      </div>
    </>
  );
}
export default ToolTip;
