import Store from './user';
import AtheleteStore from './athelete';
import PaymentStore from './payment';
import { create } from 'mobx-persist';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class RootStore {
  Store = new Store(this);
  AtheleteStore = new AtheleteStore(this);
  PaymentStore = new PaymentStore(this);

  constructor() {
    hydrate('Store', this.Store);
    hydrate('AtheleteStore', this.AtheleteStore);
    hydrate('PaymentStore', this.PaymentStore);
  }
}

export default RootStore;
