import { notify } from '../utilities';

export const errorHandler = response => {
  if (response.data.errors && response.data.errors[0] === 'Client not authorized') {
    localStorage.clear();
  }

  notify(
    parseErrors(
      response.data && response.data.errors
        ? response.data.errors
        : response.data || response.data.statusText
    ).join(',')
  );
};

export const parseErrors = list => {
  if (typeof list === 'string') {
    list = [list];
  } else {
    if (list && list.length > 0 && typeof list[0] === 'string') {
      list = list;
    } else {
      list = Object.entries(list).map(([key, value]) => {
        return value.map(err => {
          return key === 'base'
            ? value
            : `${key
                .replace(/\./g, ' ')
                .replace(/_/g, ' ')
                .replace(/(?: |\b)(\w)/g, key => {
                  return key.toUpperCase();
                })} ${err}`;
        });
      });
    }
  }
  return list;
};
