import React from 'react';

function InputLabel({ label }) {
  return (
    <small className="text-xs font-sf-regular capitalize text-black" style={{ fontSize: '12px' }}>
      {label}
    </small>
  );
}
export default InputLabel;
