import React from 'react';
import { NormalText, Heading } from './Typography';

const PrivacyPolicyLink = () => (
  <a className="text-primary ml-1" href="/privacy-policy">
    Privacy Policy
  </a>
);

const TermsService = () => {
  return (
    <div className="min-h-screen bg-main-background pt-3 text-black terms-and-privacy-container pt-20">
      <div className="container mx-auto mb-3 md:mb-5">
        <h2 className="primary-text-regular text-center text-black md:text-4xl text-xl mb-4">
          TERMS AND CONDITIONS OF USE AGREEMENT
        </h2>
        <hr />

        <div className="md:mx-5 mx-4 mt-5">
          <p className="text-lg">
            <b>Last updated:</b> May 10, 2021
          </p>

          <NormalText>
            Thank you for visiting trayvo.com (the “Site”), owned and operated by Trayvo Holdings,
            LLC (“Trayvo” or “we”). These terms of use, the
            <PrivacyPolicyLink />, the terms of use posted in connection with particular areas of
            the Site, and any other documents incorporated by reference into these terms of use
            contain the complete terms and conditions (collectively, the “Agreement”) that apply to
            your use of the Site.
          </NormalText>

          <NormalText>
            Use of the Site or clicking on “I agree” constitutes a knowing acceptance and
            acknowledgement of this Agreement. If you do not agree to this Agreement, you may not
            use this Site. If for any reason you do not agree with or cannot abide by this Agreement
            (or any posted modifications to it), please exit this Site immediately.
          </NormalText>

          <Heading className="font-sf-normal"> 1. Scope of Agreement </Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b>General. This Agreement is between you and Trayvo and governs
            your access to and use of the Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Privacy Policy. Please review our
            <PrivacyPolicyLink />, which is hereby incorporated into and made a part of this
            Agreement. The Privacy Policy explains how Trayvo collects, uses and discloses your
            individual personal information. By accessing and using the Site, you consent to
            Trayvo’s actions with respect to your personal information in compliance with the
            Privacy Policy.
          </NormalText>

          <Heading> 2. Modification of Site or Agreement. </Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Trayvo may, at any time and in its sole discretion, modify,
            revise or otherwise change the Site (including without limitation adding or
            discontinuing any or all of the products, services, information, or transactions), in
            whole or in part, without notice or liability to you.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Trayvo reserves the right to modify this Agreement at any
            time at its sole discretion. If Trayvo modifies this Agreement, Trayvo will post the
            date of the latest revision at the top of this Agreement and will post the revised
            version on the Site. The modified version of the Agreement will apply to all access and
            use of the Site thereafter. Check this page periodically to be aware of any
            modifications to the Agreement. By continuing to access and use the Site once the
            modified Agreement is posted, you indicate your assent and your agreement to be bound by
            this Agreement as modified.
          </NormalText>

          <Heading> 3. Permissions. </Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Individual Use. You may access and use this Site only for
            displaying the Site on your internet browser for personal, non-commercial purposes. You
            May not commercialize the Site or its contents in any way. If you breach this Agreement,
            your right to use the Site under these permissions terminates immediately.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Restrictions. Except as permitted above, you shall not copy,
            reproduce, distribute, display, perform, sell, lease, transmit or create derivative
            works from this Site (in whole or in part) or translate, modify, reverse engineer,
            disassemble, or decompile this Site. You must use this Site only in accordance with the
            terms and conditions of this Agreement and only for lawful purposes. you may not use the
            Site (a) to reverse engineer or decompile it, or to gain (or attempt to gain)
            unauthorized access to areas or materials provided on the Site or Trayvo’s services for
            which you do not have the proper authorization; (b) to impersonate any person or entity
            via user names or otherwise, or falsely state or otherwise misrepresent yourself, your
            age, your affiliation with any person or entity, or your authority to bind another
            person or entity; (c) to link postings or sites together without consent of author of
            the postings; (d) in a manner that violates any national, state, local or international
            law, rule or regulation (including laws regarding the export of data or software); (e)
            for any commercial purpose, including to advertise, promote or sell products or services
            or to distribute solicitations in the nature of “junk mail,” “chain letters” or “spam;”
            (f) to further or promote any criminal or illegal activity or to provide instructional
            information about illegal activities; (g) in a manner that interferes with, disables,
            disrupts, impairs, or creates an undue burden on the networks or services that support
            the Site or Trayvo’s services; (h) to harvest or collect email addresses or other
            contact information of other users by electronic or other means for the purposes of
            sending unsolicited emails or other unsolicited communications; (i) to send, knowingly
            receive, upload, download, use or re-use material that conflicts with the requirements,
            restrictions, or standards set forth in in this Agreement; or (j) via robot, spider or
            other automatic device, process or means to monitor or copy material on the Site; (k) in
            a way that attacks the Site via a denial-of-service attack or a distributed
            denial-of-service attack; (l) to exploit, harm, or violate the legal rights of others,
            (m) to transmit promotional materials or malicious code; (n) to transmit sexually
            explicit or pornographic material, obscene, defamatory, indecent, abusive, offensive,
            harassing, violent, hateful, inflammatory, discriminatory, infringing material, or any
            material that could give rise to liability for Trayvo; (o) to deceive any person, or
            cause annoyance, inconvenience, or needless anxiety to any person, (p) in a manner that
            creates the impression they emanate from or are endorsed by Trayvo or others if that is
            not the case, (q) in a manner that Trayvo determines, in its sole discretion, restricts
            or inhibits any other user from using or enjoying the Site, the information provided
            through the Site, or Trayvo’s products or services.
          </NormalText>

          <Heading>4. Availability and Use of Site.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Availability. The availability of this Site depends on many
            factors, including some factors that are beyond Trayvo’s control, such as your
            connection to the Internet and the Internet backbone. You are solely responsible for
            arranging access to the Site. You are responsible for all use of the Site through your
            Internet connection. Trayvo shall not be liable to you if you cannot use this Site for
            any reason.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> User Account. In order to use certain features of this Site,
            you will be asked to register by providing Trayvo with certain identifying information
            about yourself. In order to register, you must be at least 13 years of age. You shall
            treat your password, and related information as confidential, and shall not disclose
            this information to any other person. You acknowledge that your account is personal to
            you and shall not provide any other person with access to this Site or portions of it
            using your user name, password or other security information. You agree to notify us
            immediately of any unauthorized access to or use of your user name or password or any
            other breach of security. You also agree to ensure that you properly log out of your
            account at the end of each session. You should use particular caution when accessing
            your account from a public or shared computer so that others are not able to view or
            record your password or other personal information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> Communications. By creating a Trayvo account or filling out
            the “contact us” section, you may receive periodic emails from Trayvo. These emails may
            include notifications about your account or your use of the Trayvo Site, updates to this
            Agreement, the Privacy Policy, or other affiliated policies. These emails may also
            include information pertaining to Trayvo’s services, your training sessions,
            confirmations, newsletters, or other messages. Trayvo may also use your email address to
            respond to your customer service inquiries. To learn how to stop receiving messages from
            Trayvo, please visit our
            <PrivacyPolicyLink />.
          </NormalText>

          <Heading>5. Term; Termination.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Termination. In addition to any other legal or equitable
            remedies, Trayvo may, without prior notice to you, immediately terminate this Agreement,
            disable any user name, password or other identifier, or revoke any or all of your rights
            granted under this Agreement.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Effect of Termination. Upon any termination of this
            Agreement, you shall immediately cease all access to and use of this Site and Trayvo
            may, in addition to any other legal or equitable remedies, deny your access to and use
            of this Site in whole or in part. Any termination of this Agreement shall not affect the
            respective rights and obligations of the parties arising before the effective date of
            termination. The provisions of that, by their nature, are intended to survive
            termination of this Agreement, shall survive the termination of this Agreement.
          </NormalText>

          <Heading>6. Intellectual Property.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Intellectual Property Laws. U.S. and international
            copyright, trademark and other intellectual and proprietary laws protect this Site and
            the materials provided therein, and any unauthorized access to or use of this Site or
            the materials therein may violate such laws. Trayvo reserves the right to enforce its
            intellectual and proprietary rights to the fullest extent of the law.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Ownership. All information and data that is part of this
            Site, including without limitation, recipes, photographs, caricatures, text, software,
            graphics, illustrations, images, audio clips (collectively, “Content”), and the design,
            selection, and arrangement of the Content, and all trademarks, service marks, trade
            dress, logos and tag lines displayed in this Site (collectively, the “Trademarks”), as
            well as the copyrights, patents, trademark rights, and other intellectual property
            rights arising out of the foregoing are the sole and exclusive property of Trayvo or its
            licensors. You are not granted any right, either express or implied, in any Content or
            any copyright, Trademarks, patent, trade secret, right of publicity or other
            intellectual property or proprietary right of Trayvo or any of the goodwill associated
            with any of the foregoing. You shall not use the Trademarks or any confusingly similar
            version of them. To the extent that you use any Content or any copyright, Trademarks,
            patent, trade secret, right of publicity or other intellectual or proprietary right of
            Trayvo, such use and all goodwill associated therewith shall inure solely and
            exclusively to the benefit of Trayvo.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> Notices. You shall not remove any copyright, trademark, or
            other proprietary legends or notices that appear on, in or as part of this Site, whether
            on printable materials or otherwise.
          </NormalText>

          <Heading className="inline">7. User Contributions.</Heading>

          <NormalText classes="inline">
            The Site may contain interactive features that allow you to post, submit, publish,
            display, or transmit (“post”) content or materials, including but not limited to text,
            photographs, and videos (collectively, “User Materials”) on or through the Site.{' '}
          </NormalText>

          <NormalText classes="mt-3 ml-4">
            <b className="mr-2">(a)</b> Standards. All User Materials you post to or through the
            Site must comply with the content standards set forth in this Agreement. The User
            Materials must comply with all federal, state, local, and international laws and
            regulations. Without limiting the foregoing, the User Materials must not: (i) promote or
            engage in any illegal or unauthorized use or any way that violates applicable federal,
            state, local, or international law or regulations (including without limitation, any
            laws regarding the export or re-export of data or software to and from the US or other
            countries); (ii) impersonate any person or entity; (iii) stalk or harass any other
            person; (iv) harm or exploit minors in any way, including by displaying inappropriate
            content; (v) falsely state or misrepresent your affiliation with another person or
            entity or to impersonate or attempt to impersonate Trayvo, a Trayvo employee, another
            user, or any other person or entity, whether by using that person’s email address,
            screen name, or other indicia associated with the foregoing or otherwise or that
            misleadingly give the impression that they emanate from or are endorsed by Trayvo or any
            other person or entity if that is not the case; (vi) provide any false or misleading
            data or materials likely to deceive others; (vii) infringe any patent, trademark, trade
            secret, service mark, copyright, or other Intellectual Property right of another person;
            (viii) access or use the account of another user; (ix) distribute or procure the sending
            of advertising, surveys, sales, contests, promotional materials, sweepstakes, barter,
            “junk mail”, “spam”, “chain letters”, “pyramid schemes”, or other messages for any
            commercial purposes; (x) distribute computer viruses or other code, files, or programs
            that interrupt, destroy, or limit the functionality of any computer software or hardware
            or telecommunications equipment; (xi) “hack” or access without permission Trayvo’s
            proprietary or confidential records or those of any other third party; (xii) contain any
            libelous, abusive, offensive, harassing, violent, inflammatory, threatening, defamatory,
            obscene, indecent, hateful, sexually explicit, pornographic, or otherwise objectionable
            materials (as determined by Trayvo in its sole discretion), materials that promote
            discrimination on the basis of race, sex, religion, nationality, disability, sexual
            orientation, age, or other materials that could give rise to any civil or criminal
            liability under U.S. or international law; (xiii) promote any illegal activity or
            advocate, promote, or assist any unlawful act; (xiv) cause annoyance, inconvenience, or
            needless anxiety or be likely to upset, embarrass, alarm or annoy any other person; or
            (xv) violate the legal rights (including privacy rights and publicity rights) of others
            or contain any material that could give rise to liability or conflict with this
            Agreement.
          </NormalText>

          <NormalText classes="mt-3 ml-4">
            <b className="mr-2">(b)</b> Permission. All User Materials you post for public display
            to or through the Site will be considered non-confidential and non-proprietary. By
            posting any User Materials on or through the Site, you grant Trayvo and its service
            providers and each of their licensees, successors, and assigns the irrevocable,
            perpetual right to copy, reproduce, modify, perform, publicly display, publicly
            distribute, create derivative works from, and otherwise use and disclose to third
            parties any such User Materials (including the name, image, or other likeness (the
            “Likeness”) of all individuals identified in the User Materials for any purpose. Without
            limiting the foregoing, you authorize Trayvo to edit the User Materials you post to or
            through the Site and to use such User Materials and such Likenesses to advocate,
            advertise, or promote Trayvo, its programs, its mission, and its services online, via
            social media, through promotional materials, in print, on television, on the radio, and
            in any media now known or hereafter developed without any compensation to you or others
            identified in the User Materials. You represent and warrant that Trayvo’s authorized use
            of the User Materials will not infringe, misappropriate, or otherwise violate the
            intellectual property rights, privacy rights, or publicity rights of any third party.
            You hereby release Trayvo from all claims for remuneration, intellectual property
            infringement, trade secret misappropriation, privacy rights, and publicity rights
            arising out of Trayvo’s use of the User Materials.
          </NormalText>

          <NormalText classes="mt-3 ml-4">
            <b className="mr-2">(c)</b> Representations. You represent and warrant that you own or
            control all rights in and to the User Materials (including any privacy rights and
            publicity rights associated with individuals mentioned in such User Materials) and have
            the right to grant the permission set forth above, that your User Materials will comply
            with the content standards set forth in this Agreement, and that the User Materials do
            not infringe the copyright, trademark, trade secret, privacy rights, publicity rights,
            or other intellectual property or other rights of any third party. You acknowledge that
            you are responsible for any User Materials you submit or contribute and that you, not
            Trayvo, have full responsibility for such content, including its legality, reliability,
            accuracy, and propriety. Trayvo is not responsible or liable to any third party for the
            content or accuracy of any User Materials by you or any other user of the Site.
          </NormalText>

          <NormalText classes="mt-3 ml-4">
            <b className="mr-2">(d)</b> Monitoring. Trayvo has the right to remove or refuse to post
            any User Materials for any reason or no reason in its sole discretion. Trayvo may take
            any action with respect to User Materials that it deems necessary or appropriate in its
            sole discretion, including if it believes that any User Materials violate this Agreement
            (including the Standards), infringes the intellectual property or other rights of any
            person, threatens the personal safety of users of the Site or the public or could create
            liability for Trayvo. Trayvo may disclose your identity or other information about you
            to any third party who claims that User Materials you post violate their rights,
            including their right their intellectual property rights or privacy rights. Trayvo may
            take legal action, including without limitation, referral to law enforcement, for any
            illegal or unauthorized use of the Site. Trayvo may terminate or suspend your access to
            all or part of the site for any or no reason, including without limitation any violation
            of this Agreement. Without limiting the foregoing, Trayvo has the right to cooperate
            fully with any law enforcement authorities or court order requesting or directing Trayvo
            to disclose the identity or other information of anyone posting any posting any
            materials on or through the Site. You waive and hold harmless Trayvo and its licensees
            and service providers from any and all claims resulting from any action taken by Trayvo
            or a third party during or as a consequences of investigations of either Trayvo or law
            enforcement authorities. However, you acknowledge and agree that Trayvo cannot review
            all User Material before it is posted on the Site and cannot ensure prompt removal of
            objectionable User Material after it has been posted. Accordingly, Trayvo assumes no
            liability for any action or inaction regarding transmissions, communications, or content
            provided by any user or third party. We have no liability or responsibility to anyone
            for performance or nonperformance of the activities described in this section.
          </NormalText>

          <Heading>8. Bookings; Fees.</Heading>

          <NormalText classes="mt-3">
            Customers are charged a 3% processing fee per training. All fees are shown in U.S.
            dollars are applicable taxes and professing fees are additional. Prices and fees may be
            adjusted at any time and for any reason (or no reason at all), without prior notice to
            you. Training sessions are subject to availability, and Trayvo reserves the right to
            cancel all or part of a session and to discontinue certain services available through
            the Site without prior notice. All payments are non-refundable. By providing a payment
            method, you expressly authorize us to charge the applicable fees on said payment method
            and represent and warrant that you are the owner of said payment method and have the
            right and ability to make charges to said payment method.
          </NormalText>

          <Heading>9. Links to Other Internet Sites.</Heading>

          <NormalText classes="mt-3">
            This Site may contain links to Internet sites owned, operated or maintained by third
            parties not under Trayvo’s control. The links are not and shall not be deemed to be
            Trayvo’s endorsement of the organization associated with the linked site. You assume
            sole responsibility and liability for your use of such linked sites. If you click on a
            link to a third party site, you are leaving the Trayvo Site.
          </NormalText>

          <Heading>10. Warranty Disclaimer; Limitation of Liability.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Disclaimer. All Content, information, services, products,
            and transactions are provided on an “as-is” and “as available” basis without any
            warranties of any kind. Trayvo makes no endorsement of and no representations or
            warranties with regard to the Trainers provided through the Site. This Site may include
            inaccuracies, mistakes, or typographical errors. You acknowledge that you use this Site
            at your own risk. Trayvo does not warrant that the Content, including your use of the
            Site, will be uninterrupted or error free, accurate, useful, complete, that defects will
            be corrected, that the Site or its server are free of viruses or other harmful
            components, or that the Site will meet your needs or expectations. Trayvo disclaims any
            and all representations and warranties, express or implied, including without limitation
            the implied warranties of merchantability, fitness for a particular purpose,
            noninfringement, title, quiet enjoyment, data accuracy, data completeness, security,
            reliability, quality, availability, and system integration. Trayvo shall not be liable
            for any loss or damage caused by a distributed denial-of-service attack, viruses, or
            other technologically harmful material that may infect your computer or mobile device,
            computer programs, data, or other proprietary material due to your use of the Site or
            any services or items obtained through the Site or by downloading any material posted on
            the Site or on any site linked to the Site. While Trayvo attempts to make your access to
            and use of the Site safe, it cannot and does not represent or warrant that the services
            it provides or the access to or use of the Site is safe. You assume the entire risk as
            to your use of the Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Limitation of Liability. To the maximum extent permitted by
            applicable law, Trayvo and its respective officers, directors, employees, and agents and
            their respective successors and assigns shall not be liable for punitive, consequential,
            incidental, exemplary, indirect, or special damages (including without limitation
            damages for lost profits, revenues, business, use, data, or other intangibles), whether
            or not such damages were foreseeable and even if Trayvo had been advised of the
            possibility or likelihood of such damages. To the maximum extent permitted by applicable
            law, Trayvo shall not be liable to you for any damages arising from any use of the Site,
            from your reliance on any information provided on the Site, or for any damage or injury
            resulting from any use of the Site, including, but not limited to, any scheduling,
            meeting, or session resulting from use of the Site.
          </NormalText>

          <Heading>11. Indemnity.</Heading>

          <NormalText>
            You agree to indemnify, defend and hold harmless Trayvo and their respective officers,
            directors, shareholders, employees and agents, and all of their respective successors
            and assigns, from and against any and all claims, liabilities, losses, awards,
            judgments, settlements, costs, fees, expenses (including reasonable attorneys’ fees) and
            damages arising out of or relating to (i) your access or connection to, or use of this
            Site, including without limitation claims arising out of information, Content, provided
            to Trayvo for public display, distribution, or other use, (ii) your violation of a third
            party’s intellectual property or other rights, (iii) any claims alleging facts that, if
            true, would constitute a breach by you of the terms and conditions of this Agreement,
            (iv) injury to persons (including death) or property, including loss or corruption of
            data caused by you. Trayvo reserves the right to assume the exclusive defense and
            control of any matter otherwise subject to indemnification for attorneys’ fees and costs
            of defense by you, and in such case, you agree to cooperate with Trayvo’s defense of
            such claims.
          </NormalText>

          <Heading>12. Relationship Between the Parties.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> You acknowledge that no joint venture, partnership,
            employment, or agency relationship exists between you and Trayvo as a result of this
            Agreement or any use of this Site or the Trayvo Materials therein. you agree not to hold
            yourself out as a representative, agent, or employee of Trayvo and Trayvo shall not be
            liable for any representation, act or omission by you to the contrary.
          </NormalText>

          <Heading>13. Assignment.</Heading>

          <NormalText>
            You shall not assign or otherwise transfer this Agreement or assign, delegate or
            otherwise transfer any of your rights, interests or obligations under this Agreement and
            any such assignment, delegation or other transfer shall be void. This Agreement shall
            inure to the benefit of Trayvo’s successors, assigns and licensees.
          </NormalText>

          <Heading>14. Injunctive Relief; Remedies.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Injunctive Relief. You agree that Trayvo’s remedy at law for
            any actual or threatened breach of this Agreement would be inadequate and that Trayvo
            shall be entitled to specific performance or injunctive relief, or both (without posting
            security), in addition to any damages that Trayvo may be legally entitled to recover,
            together with reasonable expenses of any form of dispute resolution, including but not
            limited to attorneys’ fees.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Cumulative Remedies. All rights and remedies granted to
            Trayvo under this Agreement are cumulative and not alternative, and are in addition to
            all other rights and remedies available to Trayvo at law or in equity.
          </NormalText>

          <Heading>15. Governing Law and Jurisdiction; Limit on Commencing Actions.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Governing Law. This Agreement is governed by the laws of the
            Commonwealth of Kentucky, U.S.A. without regard to its conflicts of laws principles. You
            consent to the exclusive jurisdiction and venue of the state and federal courts in
            Kentucky, in all disputes arising out of or relating to the use of this Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Limitation on Actions. you must commence any cause of action
            or claim against Trayvo within one (1) year after the cause of action or claim arises,
            otherwise you agree that your cause of action or claim shall be barred.
          </NormalText>

          <Heading>16. International Access.</Heading>

          <NormalText>
            This Site can be accessed from countries other than the United States. This Site may
            contain products or services, or references to products or services, that are not
            available outside of the United States. Any such references do not imply that such
            products or services will be made available outside the United States. If you access and
            use this Site outside the United States, you are responsible for complying with your
            local laws and regulations.
          </NormalText>

          <Heading>17. International Access.</Heading>

          <NormalText>
            Please send any notices, questions, comments, or concerns regarding the Site to:{' '}
            <span className="text-primary">support@trayvo.com</span>.
          </NormalText>

          <Heading>18. Waiver; Severability; Integration; Entire Agreement.</Heading>

          <NormalText>
            No waivers shall be implied, whether from any custom or course of dealing or any delay
            or failure in Trayvo’s exercise of its rights and remedies hereunder or otherwise. Any
            waiver granted by Trayvo shall not obligate Trayvo to grant any further, similar, or
            other waivers. If any provision of this Agreement is held by a court or other tribunal
            of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such
            provision shall be eliminated or limited to the minimum extent such that the remaining
            provisions of this Agreement will continue in full force and effect. This Agreement,
            together with the Privacy Policy, and all terms and conditions of use that govern
            specific parts of the Site forms the entire agreement between the parties with respect
            to the subject matter hereof and supersedes all prior or contemporaneous agreements
            pertaining hereto. This Agreement may only be amended by Trayvo in writing and will be
            effective immediately upon posting the revised version to the Trayvo Site. Your use of
            the Site shall be subject to the current version of this Agreement at your time of use
            of the Site.
          </NormalText>

          <Heading>19. Reservation of Rights.</Heading>

          <NormalText>
            Trayvo reserves to itself any and all rights not expressly granted herein.
          </NormalText>
        </div>
      </div>
    </div>
  );
};

export default TermsService;
