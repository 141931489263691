import { internshipUrl } from '../../constants';
import React from 'react';
import { NormalText, Heading } from '../TermsAndPrivacy/Typography';
import { LogoRound } from '../../assets/img';

const Program = () => {
  return (
    <div className="min-h-screen bg-main-background pt-3 text-black terms-and-privacy-container pt-20">
      <div className="container mx-auto mb-3 md:mb-5">
        <img className="object-cover h-56 mx-auto" src={LogoRound} />

        <h2 className="primary-text-regular normal-case text-center text-primary-color underline md:text-4xl text-xl my-20">
          trayvo Brand Ambassador Program
        </h2>
        <hr />

        <Heading className="font-sf-bold text-primary-color text-center underline">
          WHAT IS TRAYVO?
        </Heading>

        <div className="md:mx-5 mx-4 mt-5">
          <NormalText>
            trayvo provides college student-athletes with guaranteed NIL income generating
            opportunities through training/lessons.
          </NormalText>

          <NormalText>An awesome opportunity for college student-athletes who are...</NormalText>

          <NormalText classes="">
            ● Excited by the prospects of NIL
            <br /> ● Connected with other student-athletes on campus
            <br /> ● Looking for business experience doing what you love
            <br /> ● Aiming to connect with a network of like-minded student-athletes
          </NormalText>

          <Heading className="font-sf-bold text-primary-color text-center underline">
            PROGRAM OVERVIEW
          </Heading>

          <NormalText classes="my-4">
            trayvo is looking for ambitious and energetic college student-athletes to join our
            2021-22 campus ambassador team. You will represent the trayvo brand and play a central
            part in our mission to champion an authentic NIL opportunity for every student-athlete.
            Along with guidance from the trayvo staff, you will help build a team and marketing plan
            to drive awareness of and engagement with trayvo at your school. Joining trayvo’s
            ambassador program enhances your skills and provides experiences to help you at the next
            level, as well as being a part of our new college athletics landscape. Opportunities are
            open to participate for the full calendar year or just a semester.
          </NormalText>

          <Heading className="font-sf-bold text-primary-color text-center underline">
            RESPONSIBILITIES
          </Heading>

          <NormalText classes="my-4">
            ● Create unique marketing strategies to increase trayvo engagement across your campus
            collaboratively with your team or other ambassadors
            <br />● Create unique marketing strategies to increase trayvo usage across your campus,
            community, and fan base
            <br />● Educate student-athletes on the benefits of trayvo and how it works
            <br />● Collaborate with your team or ambassadors on other campuses
            <br />● Provide feedback on how trayvo can be improved and provide additional benefits
            <br />● Use and share trayvo content on social media
            <br />● Rep the brand as the face of trayvo on campus
          </NormalText>

          <Heading className="font-sf-bold text-primary-color text-center underline">
            QUALIFICATIONS
          </Heading>

          <NormalText classes="my-4">
            ● Full-time student in good academic standing
            <br />● Member of an intercollegiate team
            <br />● Ability to contribute working independently or within a team
            <br />● Highly energetic, driven, and looking to make an impact
            <br />● Focus on sales, marketing, business, and/or communications
            <br />● Enthusiastic user of trayvo and belief in mission
          </NormalText>

          <Heading className="font-sf-bold text-primary-color text-center underline">
            BENEFITS
          </Heading>

          <NormalText classes="my-4">
            ● Be at the forefront of the future of college athletics
            <br />● Enhance your student-athlete experience
            <br />● Boost your resume and income generating opportunities
            <br />● Letter of recommendation
            <br />● Expand your network on campus and across the country
            <br />● Long-term opportunities for highest performers
            <br />● College credits (varies by school)
          </NormalText>

          <Heading className="font-sf-bold text-primary-color text-center underline">
            HOW TO APPLY
          </Heading>

          <NormalText classes="text-center mb-12">
            To get started, fill out our application by clicking the button below. Feel free to
            share the application with your friends and/or fellow student-athletes you think would
            be a good fiat for our team.
          </NormalText>
        </div>

        <div className="flex items-center justify-center  w-full pb-32">
          <button
            onClick={() => {
              window.open(internshipUrl, '_blank');
            }}
            className="text-blue-700 underline font-sf-bold text-lg hover:text-gray-500 w-64">
            CLICK HERE TO APPLY
          </button>
        </div>
      </div>
    </div>
  );
};

export default Program;
