export default {
  signUp: () => `/client/profiles`,
  signIn: () => `/client/sessions`,
  profile: () => `/client/profiles`,
  createGuest: () => `/client/profiles/create_guest`,
  profileSignedUrl: () => `/client/profiles/signed_url?file_type=jpg`,

  //bookings
  bookings: () => `/service_professional/bookings`,
  book: () => `/client/bookings`,
  bookingRequest: () => `/client/bookings`,
  bookingRequestEntry: () => `/client/booking_request_entries`,
  changeRequestedBookingStatus: id => `/client/booking_requests/${id}/request_booking`,

  //availabilities
  availabilities: () => `/service_professional/availabilities`,
  entries: () => `/client/calendar_entries`,

  //connect-account
  account: () => `/service_professional/profiles/connect_account`,

  //atheletes
  atheletes: () => `/client/athletes`,

  //checkout
  checkout: id => `/client/payments/${id}/checkout`,

  //credit cards
  cards: () => `/client/cards`,
  cardDelete: id => `/client/cards/${id}`,

  //charge
  createCharge: id => `/client/payments/${id}/create_charge/`,
  transferCharge: id => `/payments/${id}/transfer_charge/`,

  highSchool: () => `/high_schools`,
  getColleges: () => `/colleges`,
  sports: () => `/sports`,

  //reviews
  submitReview: () => `/client/reviews`,

  //subscribed provided service notifications
  subscribedTraining: () => `/client/subscribed_provided_services`,

  college: () => `/colleges`,
  college_schedules: (slug) => `/client/colleges/${slug}`,

  //reset password
  sendResetPassword: () => `/client/profiles/send_reset_password`,
  updatePassword: () => `/client/profiles/update_password`,
  resetPassword: () => `/client/profiles/reset_password`,
};
