import React from 'react';
import Loader from 'react-loader-spinner';

function LoaderIndicator({ color, small }) {
  return (
    <Loader
      visible={true}
      type="ThreeDots"
      color={color ? color : '#042E60'}
      height={small ? 18 : 80}
      width={small ? 38 : 80}
    />
  );
}

export default LoaderIndicator;
