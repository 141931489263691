import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClientSignin } from '../Client/SignIn';

function App() {
  const history = useHistory();
  return <ClientSignin />;
}

export default App;
