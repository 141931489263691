import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { notificationEnabled } from '../../stringConstants';
import { SuccessTick } from '../../assets/img';

function NotificationsEnabled({ show, close, serviceName, athlete }) {
  let fullName = (athlete && athlete.first_name + ' ' + athlete.last_name) || '';
  return (
    <SweetAlert show={show} showConfirm={false}>
      <div className="px-4">
        <h5 className="text-primary-color font-sf-medium">Training Notifications Enabled</h5>

        <small className="text-xs text-t-gray font-sf-regular">
          {notificationEnabled(serviceName, fullName)}
        </small>

        <div className="flex justify-center">
          <img src={SuccessTick} className="rounded object-cover my-5 self-center h-20 w-20" />
        </div>

        <div className="flex justify-center my-4">
          <button
            onClick={close}
            className="rounded-md border border-light-gray text-xs py-3 w-full md:w-32 bg-primary-color hover:text-gray-400 text-white font-normal my-3 md:my-0">
            Close
          </button>
        </div>
      </div>
    </SweetAlert>
  );
}

export default NotificationsEnabled;
