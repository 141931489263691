import React from 'react';
import SignUpForm from './SignUpForm';

function Signup(props) {
  return (
    <div>
      <div className="flex justify-center items-center overflow-x-hidden inset-0 min-h-screen bg-main-background">
        <div className="w-full px-2 md:px-10 sm:px-0">
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default Signup;
