import React from 'react';
import { InputLabel } from '../Shared';
import { getSocialUrl } from '../../utils/utilities';

const LineConnector = ({ connection }) => {
  return (
    <div className="flex items-end w-full overflow-hidden mr-3">
      <div className="w-full h-8 line-connector" />

      <small className="text-gray-600 text-xs font-sf-regular px-1">{connection}</small>
    </div>
  );
};

function SocialLink({ id, value, onChange }) {
  return (
    <div className="mt-3">
      <InputLabel label={id} color="black" />

      <div className="flex items-end pl-4">
        <LineConnector connection={getSocialUrl(id)} />

        <input
          className="text-input form-control"
          name={id}
          placeholder={
            id === 'website' ? 'Enter website url' : `Enter ${id && id.toLowerCase()} username`
          }
          value={value}
          style={{ height: '36px' }}
          onChange={e => onChange(e)}
        />
      </div>
    </div>
  );
}

export default SocialLink;
