import React from 'react';
import { SocialLink } from '../../Shared';

function SocialInfo({ provider, onChange }) {
  return (
    <div>
      <div className="mt-4">
        <div className="flex w-full justify-end">
          <small className="self-end text-red-600">
            {provider.socialError ? `Invalid ${provider.socialError} username` : ''}
          </small>
        </div>

        <SocialLink id="facebook" value={provider.facebook} onChange={e => onChange(e)} />

        <SocialLink id="twitter" value={provider.twitter} onChange={e => onChange(e)} />

        <SocialLink id="instagram" value={provider.instagram} onChange={e => onChange(e)} />

        <SocialLink id="tiktok" value={provider.tiktok} onChange={e => onChange(e)} />

        <SocialLink id="website" value={provider.website} onChange={e => onChange(e)} />
      </div>
    </div>
  );
}

export default SocialInfo;
