import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { App } from '../components/App';
import { ClientSignin } from '../components/Client/SignIn';
import { ClientSignup } from '../components/Client/SignUp';
import { TrainerProfile, FullSchedule } from '../components/Client/Profile/index';
import { Bookings, BookingsDetail } from '../components/Client/Bookings';
import { PaymentSettings, Profile } from 'components/Client/Settings';
import { Checkout } from '../components/Client/Checkout';
import { ClientHeader } from '../components/Client/Header';
import { AboutUs } from '../components/Client/AboutUs';
import { Faqs } from '../components/Client/FAQ';
import { NotFound } from '../components/Client/NotFound';
import { BookService } from '../components/Client/BookService';
import { ForgotPassword, NewPassword } from 'components/Client/ForgotPassword';
import { Feedback, PrivateFeedback } from '../components/Client/Feedback';
import { School, Schedule } from '../components/Client/School';
import { DisableNotifications } from '../components/Client/DisableNotifications';
import { StoreContext } from '../index';
import {
  TermsService,
  PrivacyPolicy,
  CaliforniaResidentsNotice,
} from '../components/TermsAndPrivacy';
import { Program } from '../components/InternshipProgram';

const Routes = props => {
  const store = useContext(StoreContext);
  let profilePopup = toJS(store.userStore.profilePopup);
  let headerHidden = toJS(store.userStore.headerHidden);
  let auth_token = localStorage.getItem('auth_token');

  useEffect(() => {
    if (!window.location.href.includes('schools')) store.userStore.changeHeaderColor(null);

    if (auth_token)
      (async () => {
        await store.userStore.refreshClient();
      })();
  }, []);

  return (
    <Router>
      <Route
        render={({ location, history }) => (
          <React.Fragment>
            {location.pathname !== '/login' &&
              location.pathname !== '/signup' &&
              location.pathname !== '/' &&
              !headerHidden && <ClientHeader />}
            <div
              onClick={() => {
                profilePopup && store.userStore.setProfilePopup(false);
              }}>
              <Switch>
                <PublicRoute restricted={true} component={App} path="/" exact />

                <PublicRoute restricted={true} component={ClientSignin} path="/login" exact />

                <PublicRoute restricted={true} component={ClientSignup} path="/signup" exact />

                <PublicRoute
                  restricted={true}
                  component={ForgotPassword}
                  path="/forgot_password"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={TermsService}
                  path="/terms-of-services"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={PrivacyPolicy}
                  path="/privacy-policy"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={CaliforniaResidentsNotice}
                  path="/california-residents-notice"
                  exact
                />

                <PublicRoute restricted={true} component={Program} path="/program" exact />

                <PublicRoute restricted={true} component={NewPassword} path="/new_password" exact />

                <PublicRoute component={AboutUs} path="/aboutus" exact />

                <PublicRoute component={Faqs} path="/faqs" exact />

                <PublicRoute component={Checkout} path="/bookings/checkout" />

                <PublicRoute
                  component={Feedback}
                  path="/feedback/:booked_service_id/:athlete_slug/:provided_service_id"
                  exact
                />

                <PublicRoute
                  component={PrivateFeedback}
                  path="/private_feedback/:booked_service_id/:athlete_slug/:provided_service_id"
                  exact
                />

                <PublicRoute component={School} path="/schools/:school_slug" exact />

                <PublicRoute component={Schedule} path="/schools/:school_slug/schedule" exact />

                <PrivateRoute component={Bookings} path="/bookings" exact />

                <PrivateRoute component={PaymentSettings} path="/payment_settings" exact />

                <PrivateRoute component={Profile} path="/profile" exact />

                <PrivateRoute component={BookingsDetail} path="/bookings/:id" exact />

                <PublicRoute component={FullSchedule} path="/profile/schedule/:id" exact />

                <PublicRoute component={TrainerProfile} path="/:athleteSlug" exact />

                <PublicRoute
                  component={DisableNotifications}
                  path="/disable_notifications/:id"
                  exact
                />

                <PublicRoute component={BookService} path="/:athleteSlug/:serviceSlug" exact />

                <Route component={NotFound} />
              </Switch>
            </div>
          </React.Fragment>
        )}
      />
    </Router>
  );
};

export default observer(Routes);
