import { API_URL } from '../constant';
import axios from 'axios';

const makeHeaders = () => {
  let auth_token = localStorage.getItem('auth_token') || null;
  if (auth_token !== null)
    return {
      'Content-Type': 'application/json',
      'AUTH-TOKEN': localStorage.getItem('auth_token'),
    };
  else
    return {
      'Content-Type': 'application/json',
    };
};

const client = () => {
  return axios.create({
    baseURL: API_URL,
    headers: makeHeaders(),
  });
};

export { client };
