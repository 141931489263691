import React, { useState, useContext, useEffect } from 'react';
import Loader from '../../Shared/Loader';
import { notify } from '../../../utils/utilities';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import AuthModal from '../Checkout/AuthModal';
import { useHistory } from 'react-router-dom';
import { InputLabel } from '../../Shared';

function Feedback(props) {
  const store = useContext(StoreContext);
  const history = useHistory();
  const [review, setReview] = useState('');
  const [loader, setLoader] = useState(false);
  const [rating, setRating] = useState(0);
  const [showAuthModal, setAuthModal] = useState(false);
  const [loginModal, showLoginModal] = useState(true);

  const [bookedServiceId, setBooking] = useState(0);
  const [athleteSlug, setSlug] = useState('');
  const [serviceId, setServiceId] = useState(0);
  const [athleteName, setAthlete] = useState('');
  const [service, setService] = useState(null);

  let user = toJS(store.userStore.currentUser);

  const onSubmit = async e => {
    e.preventDefault();

    if (user.data) {
      setLoader(true);
      let payload = {
        review: {
          rating: 1,
          comment: review,
          booking_id: bookedServiceId,
        },
      };

      store.atheleteStore.submitReview(payload).then(result => {
        if (result.data && result.data.id) {
          notify('Your feedback has been successfully submitted');
          history.push('/');
        }
        setLoader(false);
      });
    } else {
      setAuthModal(true);
    }
  };

  useEffect(() => {
    setLoader(true);
    let url = window.location.href;

    let split = url.split('/');
    setBooking(split[4]);
    setSlug(split[5]);
    setServiceId(split[6]);

    store.atheleteStore.getAthelete(split[5]).then(response => {
      if (response.attributes) {
        setAthlete(response.attributes.first_name + ' ' + response.attributes.last_name);
        let serviceFiltered =
          response.attributes &&
          response.attributes.provided_services.data.filter(item => item.id === split[6]);
        setService(serviceFiltered);
      }
      setLoader(false);
    });
  }, []);

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-8 outline-none focus:outline-none bg-main-background">
      <div className="w-full px-10 sm:px-0 sm:w-1/2 md:w-2/3">
        <div className="bg-white shadow-lg ml-auto mr-auto rounded-lg overflow-scroll max-h-screen">
          <div className="flex-row d-flex justify-center">
            <div>
              <h1 className="text-center medium-black pt-3">{athleteName}</h1>

              <h1 className="text-center font-bold text-primary-color">
                {service && service.length > 0 && service[0].attributes.name}
              </h1>

              <div className="w-full flex items-center justify-center pt-3">
                <small className="primary-text-regular font-hairline">
                  {service && service.length > 0 && service[0].attributes.service_duration} session
                </small>
              </div>

              <div className="px-8 mb-4 text-left">
                <p className="my-4 text-sm primary-text-regular">
                  How was your last session with {athleteName}?
                </p>
              </div>

              <form className="px-8 pt-6 pb-8 rounded" onSubmit={e => onSubmit(e)}>
                <div className="mb-6">
                  <InputLabel label="Your review" />

                  <textarea
                    value={review}
                    onChange={e => setReview(e.target.value)}
                    autoFocus
                    required
                    className="text-primary-color text-xs flex-1 p-2 m-1 bg-transparent w-full border-gray-500 border"
                    style={{ minHeight: '100px' }}></textarea>
                </div>

                <div className="my-4 flex-row-reverse flex">
                  <button className="primary-dark-button w-32" type="submit">
                    Submit
                    {loader && (
                      <div className="ml-2">
                        <Loader small />
                      </div>
                    )}
                  </button>
                </div>
              </form>

              <AuthModal
                visible={showAuthModal}
                loginModal={loginModal}
                showLoginModal={value => showLoginModal(value)}
                setAuthModal={value => setAuthModal(value)}
                onSuccess={() => {
                  setAuthModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Feedback);
