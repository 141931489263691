import React from 'react';
import Loader from 'components/Shared/Loader';
import { ModalBackground } from '../Shared';

const ChangePassword = props => {
  return (
    <div
      style={{ zIndex: 2 }}
      className={
        props.visible
          ? `fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center`
          : 'hidden'
      }>
      <div onClick={() => props.setModal(false)}>
        <ModalBackground />
      </div>

      <div
        className="bg-white rounded-lg shadow-xl transform transition-all max-w-lg w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div className="overflow-scroll max-h-screen py-8">
          <form className="w-full" onSubmit={e => props.submit(e)}>
            <div className="px-4">
              <div>
                <div className="text-center">
                  <h3 className="primary-text-regular text-lg" id="modal-headline">
                    Change Password
                  </h3>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 sm:px-6 flex flex-col">
              <div className="mt-2">
                <input
                  className="text-input form-control"
                  id="oldPassword"
                  type="password"
                  value={props.oldPass}
                  placeholder="Old Password"
                  onChange={e => props.setOldPass(e.target.value)}
                  required
                />
              </div>

              <div className="mt-5">
                <input
                  className="text-input form-control"
                  id="password"
                  type="password"
                  value={props.password}
                  placeholder="Password"
                  onChange={e => props.setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="mt-5">
                <input
                  className="text-input form-control"
                  id="confirmPassword"
                  type="password"
                  value={props.confirmPass}
                  placeholder="Password Confirmation"
                  onChange={e => props.setConfirmPass(e.target.value)}
                  required
                />
              </div>

              <span className="flex w-full rounded-md shadow-sm mt-5">
                <button type="submit" className="primary-dark-button w-full">
                  Update Password
                </button>
              </span>
            </div>

            {props.loader && (
              <div className="flex justify-center items-center">
                <Loader small />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
