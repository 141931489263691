import React, { Fragment } from 'react';
import { TrayvoBadge } from './index';
import { Tiktok } from '../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMapPin } from '@fortawesome/free-solid-svg-icons';
import {
  getSocialUrl,
  getAthleteName,
  getCollegeName,
  getAthleteImageSrc,
  getHighSchoolName,
} from '../../utils/utilities';

const SmallText = ({ text, black, semibold, margin }) => (
  <span
    className={`text-xs capitalize ${black ? 'text-black' : 'text-t-gray'} ${
      semibold ? 'font-sf-semibold' : 'font-sf-regular'
    }`}
    style={{ fontSize: 11, marginTop: margin && '2px' }}>
    {text}
  </span>
);

const Dot = () => (
  <span className={'capitalize px-1 text-t-gray font-sf-semibold'} style={{ fontSize: 11 }}>
    {'•'}
  </span>
);
const TextWithIcon = ({ icon, text }) => (
  <div className={'flex flex-wrap items-center mb-4 -ml-4 md:ml-0'}>
    <FontAwesomeIcon icon={icon} size="xs" className="primary-color-icon mr-1 fa-w-18" />
    <p className="text-t-gray font-sf-regular break-all" style={{ fontSize: 11 }}>
      {text}
    </p>
  </div>
);

function TrainerBanner({ athlete, padding }) {
  return (
    <div className={`${padding && 'px-3'}`}>
      <div className="px-8 py-6 rounded-lg shadow-md w-full flex items-center justify-center md:justify-between flex-wrap bg-white relative">
        <div className="absolute top-0 right-0">
          <TrayvoBadge />
        </div>

        {getAthleteImageSrc(athlete, 'trainerBanner')}

        <div className="w-full md:w-7/12">
          <div className="flex flex-col md:flex-row items-center add-margin-on-small">
            <div className="flex flex-col trainer-banner-info">
              <span
                className="font-sf-semibold text-black capitalize"
                style={{ marginBottom: '5px' }}>
                {getAthleteName(athlete)}
              </span>

              <SmallText text={athlete.sport.name} black semibold />

              <div className="flex" style={{ marginTop: '5px' }}>
                <SmallText text={getHighSchoolName(athlete)} black />

                {getHighSchoolName(athlete) && <Dot />}

                <SmallText text={getCollegeName(athlete)} black />

                {athlete.club && <Dot />}

                <SmallText text={athlete.club} black />
              </div>

              <div style={{ marginTop: '5px' }} className="flex items-center text-t-gray">
                {athlete.position &&
                  athlete.position.map((position, index, positions) => (
                    <div className="flex items-center pr-1">
                      <SmallText text={`${position}${index !== positions.length - 1 ? ',' : ''}`} />
                    </div>
                  ))}

                <Dot />

                <SmallText text={`${athlete.passing_year}`} />

                {athlete?.jersey_number?.toString() && (
                  <Fragment>
                    <Dot />
                    <SmallText text={`#${athlete.jersey_number}`} />
                  </Fragment>
                )}
              </div>

              <span
                style={{ fontSize: 12, marginTop: '5px' }}
                className="text-xs text-t-gray md:text-left text-center font-sf-regular normal-case">
                {athlete && athlete.profile_description && athlete.profile_description}.
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:w-3/12 md:mt-4 pl-6 overflow-hidden w-full mt-4">
          <div className="flex flex-col justify-center md:justify-start items-center md:items-start pl-0 md:pl-5">
            {athlete.address && (
              <TextWithIcon
                icon={faMapPin}
                text={`${athlete.city} ${athlete.city && ', '} ${athlete.state}`}
              />
            )}

            {athlete.hometown && (
              <TextWithIcon icon={faHome} text={athlete.hometown.replace(', USA', '')} />
            )}

            <div className="flex items-center content-center">
              {athlete.facebook && (
                <a
                  href={`${getSocialUrl('facebook')}${athlete.facebook}`}
                  className="fa fa-facebook"
                  target="_blank"
                />
              )}
              {athlete.twitter && (
                <a
                  href={`${getSocialUrl('twitter')}${athlete.twitter}`}
                  className="fa fa-twitter"
                  target="_blank"
                />
              )}
              {athlete.instagram && (
                <a
                  href={`${getSocialUrl('instagram')}${athlete.instagram}`}
                  className="fa fa-instagram"
                  target="_blank"
                />
              )}
              {athlete.tiktok && (
                <a
                  href={`${getSocialUrl('tiktok')}${athlete.tiktok}`}
                  className="fa fa-tiktok"
                  target="_blank">
                  <img src={Tiktok} className="w-4 h-4" />
                </a>
              )}
              {athlete.website && (
                <a href={athlete.website} className="fa fa-link" target="_blank" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainerBanner;
