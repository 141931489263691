import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { StoreContext } from '../../../index';
import MyAccount from '../../Popup/MyAccount';
import ProgressiveImage from 'react-progressive-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { profileIcon, profileIcon_light, logoWhite } from '../../../assets/img';
import { shadeColor } from '../../../utils/utilities';

function Header(props) {
  const store = useContext(StoreContext);
  const history = useHistory();
  const [isDrawerMenuOpen, setDrawerMenu] = useState(false);

  let user = toJS(store.userStore.currentUser);
  let profilePopup = toJS(store.userStore.profilePopup);
  let headerColor = toJS(store.userStore.headerColor);

  return (
    <nav
      className="flex justify-between flex-wrap absolute w-full z-10 bg-primary-color"
      style={headerColor && { backgroundColor: headerColor }}>
      <div className="w-full px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              onClick={() => setDrawerMenu(!isDrawerMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false">
              {!isDrawerMenuOpen && (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}

              {isDrawerMenuOpen && (
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="flex-1 flex items-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0">
              <div
                onClick={() => history.push('/')}
                className="cursor-pointer flex items-center flex-shrink-0 ml-5 md:ml-0 text-white mr-20">
                <ProgressiveImage src={logoWhite} placeholder={logoWhite}>
                  {src => <img src={src} className="h-12 w-20 object-contain" />}
                </ProgressiveImage>
              </div>
            </div>
          </div>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="ml-3 relative flex flex-row">
              <div className="hidden sm:block sm:ml-6">
                <div className="flex mr-4 items-center">
                  <a
                    href="/bookings"
                    style={{ fontFamily: 'SF-light' }}
                    className={
                      !user.data || (user.data && user.data.attributes.guest_user)
                        ? 'hidden'
                        : `ml-4 px-3 py-2 rounded-md text-sm leading-5 text-white hover:underline focus:outline-none focus:text-white transition duration-150 ease-in-out`
                    }>
                    Bookings
                  </a>
                </div>
              </div>

              {user.data ? (
                <div className="lg:mt-0">
                  <a
                    onClick={() => {
                      store.userStore.setProfilePopup(true);
                    }}
                    style={{ fontFamily: 'SF-medium' }}
                    className={`block lg:inline-block lg:mt-0 text-white hover:text-gray-400`}>
                    <div>
                      <div className="dropdown inline-block relative">
                        <div className="flex items-center">
                          <ProgressiveImage
                            src={
                              user.data && user.data.attributes.profile_image
                                ? user.data.attributes.profile_image
                                : profileIcon
                            }
                            placeholder={profileIcon_light}>
                            {src => (
                              <img
                                src={src}
                                className="h-8 w-8 rounded-full object-cover border border-white bg-gray-500"
                              />
                            )}
                          </ProgressiveImage>

                          <FontAwesomeIcon
                            icon={faCaretDown}
                            size="sm"
                            color="white"
                            className="ml-1"
                          />
                        </div>

                        <div
                          className={`${profilePopup ? 'dropdown-menu' : 'hidden'
                            } absolute text-gray-700`}>
                          <MyAccount />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ) : (
                <div className="md:ml-10 flex flex-col md:flex-row items-center">
                  <a
                    style={{ fontFamily: 'SF-light', fontSize: 14 }}
                    href="/"
                    className="inline-block hover:underline mt-0 text-xs px-3 py-2 leading-none sm:py-3 text-white">
                    Sign up
                  </a>

                  <span className="hidden md:flex text-white">|</span>

                  <button
                    style={{ fontFamily: 'SF-light', fontSize: 14 }}
                    onClick={() => history.push('/login')}
                    className="px-3 text-white focus:outline-none hover:underline">
                    <FontAwesomeIcon icon={faUser} size="sm" color="white" className="mr-2" />
                    Sign in
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {headerColor && (
        <div className="w-full h-2" style={{ backgroundColor: shadeColor(headerColor, -40) }} />
      )}

      {isDrawerMenuOpen && (
        <div className="block sm:hidden">
          <div className="px-2 pt-2 pb-3">
            <a
              style={{ fontFamily: 'SF-light', fontSize: 12 }}
              href="/aboutus"
              className="block mr-10 mt-3 lg:inline-block lg:mt-0 text-white hover:text-gray-400 transition duration-150 ease-in-out">
              About us
            </a>

            <a
              style={{ fontFamily: 'SF-light', fontSize: 12 }}
              href="/faqs"
              className="block mr-10 mt-3 lg:inline-block lg:mt-0 text-white hover:text-gray-400 transition duration-150 ease-in-out">
              FAQ
            </a>

            <a
              href="/bookings"
              style={{ fontFamily: 'SF-light', fontSize: 12 }}
              className={
                !user.data || (user.data && user.data.attributes.guest_user)
                  ? 'hidden'
                  : `block mr-8 mt-3 lg:inline-block lg:mt-0 text-white hover:text-gray-400 transition duration-150 ease-in-out`
              }>
              Bookings
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default observer(Header);
