import moment from 'moment';
import { AsYouType } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import { trainingOption } from '../constants';
import { sport, Placeholder } from '../assets/img';
import ProgressiveImage from 'react-progressive-image';
import React, { Component } from 'react';
import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY)

export const isLoggedIn = () => {
  const authToken = localStorage.getItem('auth_token');
  return authToken ? true : false;
};

export const priceFormatter = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

export const formatDate = date => {
  let type = typeof date; // type check because 2 types of dates "2020-12-03T00:00:00.000Z" from the backend
  //and {object} from the date pickers.
  if (type === 'string') {
    let splitted = date && date.split('T');
    return splitted.length > 0 ? splitted[0] : '';
  } else {
    return moment(date).format('YYYY-MM-DD');
  }
};

export const formatTime = time => {
  return moment(time).utc().format('h:mm A');
};

export const getLink = url => {
  if (!url) return '';
  else if (url.includes('https://') || url.includes('http://')) return url;
  else return 'https://' + url;
};

export const notify = (text, status = 'warn') => {
  const settings = {
    autoClose: 5000,
  };
  if (status === 'success') return toast.success(text, settings);
  return toast.warn(text, settings);
};

export const isValidZip = zip => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
};

export const isValidContactNumber = contactNumber => {
  let asYouType = new AsYouType();
  asYouType.defaultCountry = 'US';
  asYouType.reset();
  asYouType.input(contactNumber);
  return asYouType.getNumber() && asYouType.getNumber().isValid();
};

export const isValidEmail = email => {
  let pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};

export const convertDate = date => {
  return moment(date).format('MMM D');
};

export const backgroundColor = trainingType => {
  return trainingType === 'group'
    ? 'bg-training-group'
    : trainingType === 'individual'
      ? 'bg-training-individual'
      : 'bg-training-virtual';
};

export const borderColor = trainingType => {
  return trainingType === 'group'
    ? 'border-training-group'
    : trainingType === 'individual'
      ? 'border-training-individual'
      : 'border-training-virtual';
};

export const getTrainingType = entryColor => {
  return entryColor === 'individual_color'
    ? trainingOption.INDIVIDUAL
    : entryColor === 'virtual_color'
      ? trainingOption.VIRTUAL
      : trainingOption.GROUP;
};

export const getAthleteName = athlete => `${athlete.first_name} ${athlete.last_name}`;

export const getCollegeName = athlete =>
  (athlete.college && athlete.college.data && athlete.college.data.attributes.nickname) || '';

export const getHighSchoolName = athlete =>
  (athlete.high_school &&
    athlete.high_school.data &&
    athlete.high_school.data.attributes.nickname) ||
  '';

export const getAthleteImageSrc = (athlete, trainerBanner = false) => {
  return (
    <ProgressiveImage src={sport} placeholder={Placeholder}>
      {src => (
        <img
          src={athlete.profile_image ? athlete.profile_image : Placeholder}
          className="shadow object-cover bg-white"
          style={{
            height: trainerBanner ? '100px' : '70px',
            width: trainerBanner ? '100px' : '70px',
            borderRadius: '50px',
            borderColor: '#408BF9',
            borderWidth: '3px',
          }}
        />
      )}
    </ProgressiveImage>
  );
};

export const getSocialUrl = title => {
  switch (title) {
    case 'facebook':
      return 'https://facebook.com/';
    case 'twitter':
      return 'https://twitter.com/';
    case 'instagram':
      return 'https://instagram.com/';
    case 'tiktok':
      return 'https://tiktok.com/';
    case 'website':
      return '';
  }
};

export const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const validateSocialHandles = username => {
  return /^[a-zA-Z0-9._]+$/.test(username);
};

export const getPositionLabels = sportPositions => {
  return (
    sportPositions &&
    sportPositions.attributes.positions.map(({ name, display_name }) => ({
      label: `${name} (${display_name})`,
      value: display_name,
    }))
  );
};

export const formattedPositions = (positions = []) => {
  return positions.map(
    (position, index, arr) => `${[position]}${index !== arr.length - 1 ? ', ' : ''} `
  );
};

export const ifCurrentPlayer = passingYear => {
  return passingYear > 2025;
};

export const ifPassedIn = year => year > 2021 && year < 2026;

export const getLatLng = address => {
  Geocode.fromAddress(address ? address : '').then(
    response => {
      console.log('res', response)
      const { lat, lng } = response.results[0].geometry.location;
      return { lat, lng };
    },
    error => {
      console.error(error);
    }
  );
};

export const getParsedAddress = async address => {
  try {
    if (!address.length) return []

    let street, city, state, zip
    const response = await Geocode.fromAddress(address ? address : '')
    address = response.results[0].address_components

    address.forEach(component => {
      let types = component.types;

      if (types.indexOf('street_number') > -1) {
        street = component.long_name;
      }

      if (types.indexOf('locality') > -1 || types.indexOf('administrative_area_level_3') > -1) {
        city = component.long_name;
      }

      if (types.indexOf('administrative_area_level_1') > -1) {
        state = component.short_name;
      }

      if (types.indexOf('postal_code') > -1) {
        zip = component.long_name;
      }
    })

    return city ? `${city}/${state}` : state
  } catch (err) {
    console.error(err)
  }
}

export const removeUnderScoreFromString = string => string.split('_').join(' ');

export const removeLocalStorageKeysExcept = key => {
  if (localStorage.length) return;

  const localStorageKeys = Object.keys(localStorage).filter(
    localStorageKey => localStorageKey !== key
  );
  localStorageKeys.forEach(localStorageKey => localStorage.removeItem(localStorageKey));
};

export const removeSpecificLocalStorageKeys = keys => {
  keys.forEach(key => localStorage.removeItem(key));
};

export const appendSymInStart = (text, sym) =>
  text[0] !== sym && text !== '' ? `${sym}${text}` : text;
