import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'index';
import { Tiktok, TrayvoBlue } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/Shared/Loader';
import { TrainingBadge } from '../../Shared';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { observer } from 'mobx-react';
import { faPhone, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import {
  priceFormatter,
  formatDate,
  borderColor,
  formatTime,
  getSocialUrl,
  getAthleteName,
  getCollegeName,
  getAthleteImageSrc,
  getHighSchoolName,
  formattedPositions,
  getLatLng,
} from '../../../utils/utilities';
import { getFormattedLocalDate, getFormattedLocalTime, getTimeZone } from 'utils/helpers/timeZone';

const TextContainer = ({ title, value }) => (
  <div className="flex justify-between my-1 p-3 rounded-md" style={{ backgroundColor: '#F5F6FA' }}>
    <span className="text-xs font-sf-regular text-primary-color">{title}</span>
    <span className="text-xs font-sf-regular text-primary-color normal-case text-right">
      {value}
    </span>
  </div>
);

const SmallText = ({ text, black, icon, id }) => (
  <div>
    <FontAwesomeIcon icon={icon} size="xs" color="gray" className="mr-2" />

    {id === 'email' ? (
      <button
        className="primary-text-regualar text-xs underline lowercase text-blue-700"
        onClick={() => {
          window.open(`mailto:${text}`);
        }}
        title={text}>
        {text}
      </button>
    ) : (
      <span
        className={`font-sf-regular text-xs capitalize mt-2 pr-1 ${
          black ? 'text-black' : 'text-gray-700'
        }`}
        style={{ fontSize: 11 }}>
        {text}
      </span>
    )}
  </div>
);

const AthleteDetail = ({ label, value }) => (
  <div className="flex flex-col border-b mt-3 border-gray-400 w-full items-start justify-end content-end">
    <small className={`font-sf-regular text-xs capitalize pr-1 mb-1 text-gray-600`}>{label}</small>

    <small className="font-sf-regular text-xs normal-case pr-1 text-primary-color">
      {value ? value : '-'}
    </small>
  </div>
);

const RoundImage = ({ source, trainingType, blueBorder, style }) => {
  return (
    <div
      className={`rounded-full border-2 mt-5  ${
        blueBorder ? 'border-blue-700' : borderColor(trainingType)
      }`}>
      <img
        style={style && style}
        src={source !== null ? source : !style ? TrayvoBlue : null}
        className="h-16 w-16 border rounded-full object-cover"
      />
    </div>
  );
};

const Caption = ({ caption }) => (
  <small className="font-sf-regular text-black text-left text-sm capitalize">{caption}</small>
);

function BookingsDetail(props) {
  const [loader, setLoader] = useState(false);
  const [booking, setBooking] = useState(null);
  const store = useContext(StoreContext);

  useEffect(() => {
    let {
      match: { params },
    } = props;

    setLoader(true);
    store.userStore.getBooking(params.id).then(response => {
      if (response.id) setBooking(response);
      setLoader(false);
    });
  }, []);

  let service = booking && booking.provided_service && booking.provided_service.data;
  let athlete = booking && booking.athlete.data.attributes;

  return (
    <div className="bg-main-background pt-16">
      {!loader ? (
        <div className="w-full self-center flex flex-col items-center p-2 justify-center">
          {booking && service && athlete && (
            <div
              className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:p-0 justify-between bg-white shadow-md mt-5"
              style={{ borderRadius: 20 }}>
              <div className="mb-5 p-3 w-full md:w-1/2">
                <div className="flex flex-row">
                  {booking && (
                    <div className="px-2 flex flex-col w-full">
                      <div className="flex flex-col items-center mt-2">
                        <h4 className="font-sf-medium text-primary-color">Training Info</h4>

                        <RoundImage
                          source={service.attributes.image_url}
                          trainingType={service.attributes.training_type}
                        />

                        <div className="flex flex-col w-full items-center mt-3">
                          <Caption caption={service.attributes.name} />

                          <div className="text-center max-location-height overflow-hidden">
                            <small className="font-sf-regular text-primary-color font-normal text-xs uppercase">
                              {parseInt(service.attributes && service.attributes.duration)} mins
                            </small>

                            {service.attributes.training_type !== 'virtual' &&
                              service.attributes.provided_service_locations.data &&
                              service.attributes.provided_service_locations.data.length > 0 && (
                                <small className="font-sf-regular text-primary-color font-normal text-xs text-center uppercase">
                                  <small className="text-primary-color px-1">@</small>
                                  {booking.location}
                                  <br />
                                  {booking.address}
                                </small>
                              )}
                          </div>

                          <small
                            className="text-black text-right font-sf-medium"
                            style={{ fontSize: 16 }}>
                            {priceFormatter(service.attributes.price)}
                          </small>

                          <TrainingBadge trainingType={service.attributes.training_type} />
                        </div>
                      </div>

                      {service.attributes.training_type === 'virtual' && (
                        <span className="text-primary-color font-sf-regular text-center tex-xs capitalize">
                          Connection details: {service.attributes.connection_detail}
                        </span>
                      )}

                      <span
                        style={{ fontSize: 12 }}
                        className="font-sf-regular break-words text-t-gray text-xs mt-2 text-center">
                        {service.attributes.discription}
                      </span>
                    </div>
                  )}
                </div>

                <div className="py-3">
                  <div className="flex flex-col my-2 p-2">
                    <TextContainer
                      title={getFormattedLocalDate(booking.training_start_date_time, 'MM-DD-YYYY')}
                      value={`${getFormattedLocalTime(
                        booking.training_start_date_time
                      )} - ${getFormattedLocalTime(
                        booking.training_end_date_time
                      )} (${getTimeZone()})`}
                    />
                    <TextContainer
                      title="Price"
                      value={priceFormatter(
                        service && service.attributes && service.attributes.price
                          ? service.attributes.price
                          : 0
                      )}
                    />

                    <TextContainer title="Order" value={booking && booking.id} />

                    {/* <TextContainer title="Status" value={booking && booking.status} /> */}

                    <TextContainer title="Note" value={booking && booking.notes} />
                  </div>
                </div>
              </div>

              <div
                style={{ width: 1, backgroundColor: '#D7DAE2' }}
                className="my-10 hidden md:flex h-auto"
              />

              <div className="mb-5 p-2 h-auto w-full md:w-1/2">
                {athlete && (
                  <div className="w-full flex items-center flex-col p-3">
                    <h4 className="font-sf-medium text-primary-color mb-5">Trainer Info</h4>

                    {getAthleteImageSrc(athlete)}

                    <div className="flex flex-col w-full items-center mt-1">
                      <Caption caption={getAthleteName(athlete)} />

                      <SmallText text={athlete.email} icon={faEnvelope} id="email" />
                      <SmallText text={athlete.contact_number} icon={faPhone} />
                      <SmallText
                        text={`${athlete.city}${athlete.city && ', '} ${athlete.state}`}
                        icon={faLocationArrow}
                      />

                      <div className="flex items-center content-center pt-2">
                        <a
                          href={`${getSocialUrl('facebook')}${athlete.facebook}`}
                          className="fa fa-facebook"
                          target="_blank"
                        />

                        <a
                          href={`${getSocialUrl('twitter')}${athlete.twitter}`}
                          className="fa fa-twitter"
                          target="_blank"
                        />

                        <a
                          href={`${getSocialUrl('instagram')}${athlete.instagram}`}
                          className="fa fa-instagram"
                          target="_blank"
                        />

                        <a
                          href={`${getSocialUrl('tiktok')}${athlete.tiktok}`}
                          className="fa fa-tiktok"
                          target="_blank">
                          <img src={Tiktok} className="w-4 h-4" />
                        </a>

                        <a href={athlete.website} className="fa fa-link" target="_blank" />
                      </div>

                      <div className="w-3/4">
                        <AthleteDetail label="College" value={getCollegeName(athlete)} />

                        <AthleteDetail label="High School" value={getHighSchoolName(athlete)} />

                        <AthleteDetail label="Club" value={athlete.club} />

                        <AthleteDetail
                          label="Position"
                          value={formattedPositions(athlete.position)}
                        />

                        <AthleteDetail label="Class" value={athlete.passing_year} />

                        <AthleteDetail label="Sport" value={athlete.sport && athlete.sport.name} />

                        <AthleteDetail label="About" value={athlete.profile_description} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            className="flex flex-col w-full md:w-3/5 p-3 items-center bg-white shadow-md mt-2"
            style={{ borderRadius: 20 }}>
            <h5 className="text-black font-sf-regular">Location Info</h5>

            <div className="w-full">
              {athlete && (
                <div id="mapBox" className="m-3">
                  <Map
                    google={props.google}
                    zoom={15}
                    style={{
                      width: '100%',
                      height: '250px',
                      borderRadius: 10,
                      position: 'relative',
                    }}
                    initialCenter={
                      service.attributes.training_type === 'virtual'
                        ? { lat: athlete.latitude, lng: athlete.longitude }
                        : getLatLng(booking.address)
                    }>
                    <Marker
                      position={
                        service.attributes.training_type === 'virtual'
                          ? { lat: athlete.latitude, lng: athlete.longitude }
                          : getLatLng(booking.address)
                      }
                    />
                  </Map>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(observer(BookingsDetail));
