import React from 'react';
import SigninForm from './SignIn';
import { TRAINER_BASE_URL } from '../../../constant';
import { LogoRound } from '../../../assets/img';

function SigninModal() {
  return (
    <div className="min-h-screen bg-main-background pt-3">
      <img className="object-cover h-12 mx-4" src={LogoRound} />

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto py-16">
        <div
          className="w-full md:w-1/3 overflow-hidden rounded-lg shadow-md mx-4 md:mx-0 bg-white"
          style={{ minHeight: '480px' }}>
          <div className="flex w-full justify-between">
            <button
              disabled
              className="cursor-not-allowed rounded-md border-2 border-primary-color text-xs py-3 w-1/2 bg-white hover:text-blue-500 text-primary-color font-sf-bold md:my-0 focus:outline-none">
              For Players
            </button>

            <button
              onClick={() => {
                window.location.replace(TRAINER_BASE_URL);
              }}
              className="w-1/2 rounded-md border border-gray-500 text-xs py-3 bg-white hover:text-blue-500 text-primary-color font-sf-regular md:my-0 focus:outline-none">
              For Trainers
            </button>
          </div>

          <SigninForm />
        </div>
      </div>
    </div>
  );
}

export default SigninModal;
