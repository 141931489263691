import qs from 'qs';
import api from '../store/api';
import { decorate, observable, action } from 'mobx';
import { client } from '../store';
import { persist } from 'mobx-persist';
import { errorHandler } from 'utils/middlewares/errorHandler';
import { RESET_PASSWORD_URL } from '../constant';
import { toJS } from 'mobx';
import { notify, removeLocalStorageKeysExcept } from '../utils/utilities';

class Store {
  currentUser = {};
  userType = null;
  bookings = [];
  rejectedBookings = [];
  bookingsPagy = {};
  rejectedBookingsPagy = {};
  profilePopup = false;
  selectedLocation = null;
  sports = [];
  sportPositions = null;
  headerColor = null;
  headerHidden = null;

  logout() {
    this.currentUser = {};
  }

  updatePassword = async payload => {
    return client()
      .put(api.updatePassword(), { client: payload })
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data.data;

          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };

  updateProfile = async payload => {
    return client()
      .put(api.profile(), { client: payload })
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data.data;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };

  setProfilePopup(value) {
    this.profilePopup = value;
  }

  async signIn(payload) {
    removeLocalStorageKeysExcept('serviceLocation');

    return client()
      .post(api.signIn(), payload)
      .then(
        action('fetchSuccess', data => {
          localStorage.setItem('auth_token', data.data.data.attributes.auth_token);
          this.openMenu = true;
          this.currentUser = data.data;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async refreshClient() {
    return client()
      .get(api.profile())
      .then(
        action('fetchSuccess', data => {
          localStorage.setItem('auth_token', data.data.data.attributes.auth_token);
          this.openMenu = true;
          this.currentUser = data.data;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  signUp = async payload => {
    return client()
      .post(api.signUp(), { client: payload })
      .then(
        action('fetchSuccess', data => {
          localStorage.setItem('auth_token', data.data.data.attributes.auth_token);
          this.openMenu = true;
          this.currentUser = data.data;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };

  createGuest = async payload => {
    return client()
      .post(api.createGuest(), { guest_client: payload })
      .then(
        action('fetchSuccess', data => {
          localStorage.setItem('auth_token', data.data.data.attributes.auth_token);
          this.openMenu = true;
          this.currentUser = data.data;
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };

  getBookings = async (page = 1) => {
    let payload = {
      page,
      per_page: 10,
      q: {
        status_not_eq: 3,
      },
    };

    let paramsString = qs.stringify(payload);

    return client()
      .get(`${api.book()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          let accepted = toJS(this.bookings);
          let rejected = toJS(this.rejectedBookings);

          if (page > 1) {
            let acceptNext = data.data.filter(({ attributes }) => attributes.status !== 'rejected');
            let rejectNext = data.data.filter(({ attributes }) => attributes.status === 'rejected');

            accepted = accepted.concat(acceptNext);
            rejected = rejected.concat(rejectNext);
          } else {
            accepted = data.data.filter(({ attributes }) => attributes.status !== 'rejected');
            rejected = data.data.filter(({ attributes }) => attributes.status === 'rejected');
          }

          this.bookings = accepted;
          this.rejectedBookings = rejected;
          this.bookingsPagy = data.pagy;
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getRejectedBookings = async (page = 1) => {
    let payload = {
      page,
      per_page: 10,
      q: {
        status_eq: 3,
      },
    };
    let paramsString = qs.stringify(payload);
    return client()
      .get(`${api.book()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          let rejected = toJS(this.rejectedBookings);

          if (page > 1) {
            let rejectNext = data.data.filter(({ attributes }) => attributes.status === 'rejected');

            rejected = rejected.concat(rejectNext);
          } else {
            rejected = data.data.filter(({ attributes }) => attributes.status === 'rejected');
          }

          this.rejectedBookings = rejected;
          this.rejectedBookingsPagy = data.pagy;
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getBooking = async id => {
    return client()
      .get(`${api.book()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data && data.data && data.data.attributes;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  cancelBooking = async id => {
    return client()
      .put(`${api.book()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          notify('Cancelled successfuly!');
          this.getBookings();
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getProfileImageSignedUrl = async () => {
    return client()
      .get(api.profileSignedUrl())
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getResetPassword = async email => {
    let param = {
      client: { email, url: RESET_PASSWORD_URL },
    };

    let paramsString = qs.stringify(param);

    return client()
      .get(`${api.sendResetPassword()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  updateWithEmail = async payload => {
    return client()
      .post(api.resetPassword(), payload)
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data.data;

          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  };

  updateWithToken = async payload => {
    return client()
      .post(api.resetPassword(), payload)
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data.data;

          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  uploadImage = async (file, signedUrl) => {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    };

    return client()
      .put(signedUrl, file, options)
      .then(
        action('fetchSuccess', ({ response }) => {
          return response;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getSports = async () => {
    return client()
      .get(api.sports())
      .then(
        action('fetchSuccess', ({ data }) => {
          let arr = data.data.map(item => {
            return { label: item.attributes.name, value: item.attributes.name, id: item.id };
          });
          this.sports = arr;
          return arr;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  disableNotification = async id => {
    return client()
      .delete(`${api.subscribedTraining()}/${id}`)
      .then(
        action('fetchSuccess', response => {
          return response;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  enableNotifications = async payload => {
    return client()
      .post(api.subscribedTraining(), payload)
      .then(
        action('fetchSuccess', response => {
          return response;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  resetLocationAddress = () => {
    this.selectedLocation = null;
  };

  setLocationAddress = (loc, address, trainingId) => {
    this.selectedLocation = { location: loc, address, trainingId };
  };

  getSchoolsBySearch = payload => {
    let paramsString = qs.stringify(payload);
    return client()
      .get(`${api.highSchool()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return (
            data.data &&
            data.data.data.map(item => {
              return {
                label: `${item.attributes.nickname} (${item.attributes.city + ', ' + item.attributes.state
                  })`,
                value: item.id,
              };
            })
          );
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  getCollegesBySearch = payload => {
    let paramsString = qs.stringify(payload);
    return client()
      .get(`${api.getColleges()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return (
            data.data &&
            data.data.data.map(item => {
              return {
                label: `${item.attributes.nickname} (${item.attributes.city + ', ' + item.attributes.state
                  })`,
                value: item.id,
                colorCode: item.attributes.color_code,
              };
            })
          );
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  changeHeaderColor = color => {
    this.headerColor = color;
  };

  hideHeader = header => {
    this.headerHidden = header;
  };

  getSportPositions = async id => {
    return client()
      .get(`${api.sports()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (data?.data?.attributes?.positions) {
            let filteredPositions = data?.data?.attributes?.positions?.filter(position => !['Assistant Coach', 'Head Coach'].includes(position.name))
            data.data.attributes.positions = filteredPositions
          }
          this.sportPositions = data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };
}

decorate(Store, {
  currentUser: [persist('object'), observable],
  bookings: [persist('list'), observable],
  rejectedBookings: [persist('list'), observable],
  bookingsPagy: [persist('object'), observable],
  sports: [persist('list'), observable],
  selectedLocation: [persist('object'), observable],
  sportPositions: observable,
  logout: action,
  changeHeaderColor: action,
  hideHeader: action,
  setProfilePopup: action,
  profilePopup: observable,
  headerColor: observable,
  headerHidden: observable,
});

export default Store;
