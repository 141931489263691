import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { deleteIcon } from '../../assets/img';
import { StoreContext } from '../../index';
import { toJS } from 'mobx';

function CardPopup(props) {
  const store = useContext(StoreContext);
  let cards = toJS(store.paymentStore.creditCards);
  let defaultCard = toJS(store.paymentStore.defaultCard);

  return (
    <div
      style={{ zIndex: 11 }}
      className={
        props.isVisible
          ? `fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center`
          : 'hidden'
      }>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>

      <div
        className="bg-white rounded-lg shadow-xl transform transition-all w-full"
        role="dialog"
        aria-modal="true"
        style={{ width: '700px' }}
        aria-labelledby="modal-headline">
        <div className="p-4 overflow-scroll max-h-screen">
          <div className="px-4 pt-5">
            <div className="flex items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <div className="flex justify-between w-full items-start">
                  <div className="text-left">
                    <h3 className="medium-black" id="modal-headline">
                      Payment methods
                    </h3>

                    <small className="primary-text-regular">
                      Attach a new one or change the default
                    </small>
                  </div>

                  <span className="flex rounded-md shadow-sm ml-3 w-auto">
                    <button
                      onClick={() => props.addNewCard()}
                      type="submit"
                      className="primary-dark-button">
                      + New Card
                    </button>
                  </span>
                </div>

                {cards.map(item => {
                  return (
                    <div className="mx-5 my-10 shadow-xl" key={item}>
                      <a className="hover:bg-gray-800 ">
                        <div
                          onClick={() => {
                            store.paymentStore.changeDefaultCard(item);
                          }}
                          className={`hover:bg-gray-200 h-12 w-full rounded border border-gray-300 bg-white items-center justify-between content-center flex mt-4 px-4 ${
                            defaultCard.id === item.id && 'border-gray-600'
                          }`}>
                          <div className="flex flex-row items-center w-32 justify-between ">
                            <small className="text-xs">{item.attributes.brand}</small>

                            <small className="text-xs text-center">
                              **** {item.attributes.last4}
                            </small>
                          </div>

                          <div className="flex flex-row items-center w-32 justify-between ">
                            <small className="text-xs">{`${item.attributes.exp_month} / ${item.attributes.exp_year}`}</small>

                            {defaultCard.id === item.id && (
                              <small className="text-xs text-blue-500">{'Default'}</small>
                            )}

                            <a
                              onClick={() => props.deleteCard(item.id)}
                              className="border border-white hover:border-red-700 h-6 w-6 rounded-md items-center flex content-center justify-center">
                              <img src={deleteIcon} className="h-4 w-4 rounded-md" />
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse">
            <span className="mt-2 flex w-auto rounded-md shadow-sm">
              <button
                onClick={() => {
                  props.setCardModal(false);
                }}
                type="button"
                className="primary-button w-32">
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(CardPopup);
