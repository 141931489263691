import React, { useState, useContext, Fragment } from 'react';
import { StoreContext } from 'index';
import { Loader, LoginForm, TrainingBadge } from 'components/Shared';
import { useHistory, useLocation } from 'react-router-dom';
import { TrayvoBlue } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import ButtonLoader from 'react-loader-spinner';
import { Paymentpopup, CardPopup } from '../../Popup';
import ProgressiveImage from 'react-progressive-image';
import {
  priceFormatter,
  formatTime,
  notify,
  borderColor,
  removeSpecificLocalStorageKeys,
} from '../../../utils/utilities';
import { CustomerDetail, AuthModal } from './index';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import './card.scss';
import { getFormattedLocalDate, getFormattedLocalTime, getTimeZone } from 'utils/helpers/timeZone';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);

const TextContainer = ({ title, value, bold }) => (
  <div className="flex justify-between my-1 p-3 rounded-md" style={{ backgroundColor: '#F5F6FA' }}>
    <span className={`text-xs ${bold ? 'font-sf-bold' : 'font-sf-regular'} text-primary-color`}>
      {title}
    </span>
    <span className={`text-xs ${bold ? 'font-sf-bold' : 'font-sf-regular'} text-primary-color`}>
      {value}
    </span>
  </div>
);

function Checkout(props) {
  const history = useHistory();
  const location = useLocation();
  const store = useContext(StoreContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [note, setNote] = useState('');
  const [bookLoader, setBookLoader] = useState(false);
  const [showModal, setModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const [loginModal, showLoginModal] = useState(true);
  const [guest, setGuest] = useState(false);

  if (!location.state) {
    const serviceLocation = JSON.parse(localStorage.getItem('serviceLocation'));
    return serviceLocation ? history.replace(serviceLocation) : history.replace('/');
  }

  const service = location?.state?.providedService;
  const bookingDate = location?.state?.bookingDate;
  const slot = location?.state?.selectedSlot;

  let athlete = toJS(store.atheleteStore.athelete);
  let user = toJS(store.userStore.currentUser);
  let cards = toJS(store.paymentStore.creditCards);
  let defaultCard = toJS(store.paymentStore.defaultCard);
  let selectedLocation = toJS(store.userStore.selectedLocation);

  const submit = async e => {
    e.preventDefault();
    if (error) {
      elements.getElement('card').focus();
      return;
    }
    setLoader(true);

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result && result.token) {
      await store.paymentStore.createCard(result.token.id);
      await store.paymentStore.getCards();
      setLoader(false);
      setModal(false);
    } else {
      notify('Invalid card details');
      setLoader(false);
    }
  };

  const checkout = () => {
    if (user.data) {
      if (cards.length === 0) {
        notify('Add payment method');
      } else {
        setBookLoader(true);
        store.atheleteStore
          .createBookingRequest(
            {
              booking: {
                notes: note,
                from_time: slot.from_time,
                to_time: slot.to_time,
                booking_date: bookingDate,
                provided_service_id: service.id,
                location: selectedLocation.location,
                address: selectedLocation.address,
              },
            },
            defaultCard.attributes.stripe_id
          )
          .then(result => {
            if (result.attributes) {
              notify('You are all booked! See below for your training details.', 'success');
              store.atheleteStore.clearRequestedBookings();
              store.userStore.resetLocationAddress();
              setBookLoader(false);
              removeSpecificLocalStorageKeys(['serviceLocation']);

              if (user.data.attributes.guest_user) {
                localStorage.clear();
                store.userStore.logout();
                history.push('/');
              } else history.push('/bookings');
            } else setBookLoader(false);
          });
      }
    } else setAuthModal(true);
  };

  const editDetails = id => {
    if (id === 'card') setCardModal(true);
    else history.push('/profile');
  };

  const addNewCard = () => {
    setCardModal(false);
    setModal(true);
  };

  const deleteCard = async id => {
    await store.paymentStore.deleteCard(id);
    store.paymentStore.getCards();
  };

  return (
    <div>
      <div className="bg-main-background min-h-screen pt-24 pb-64 md:pb-0">
        {!loader && athlete ? (
          <div className="flex flex-col items-center justify-center content-center">
            <div className="flex flex-col items-start md:w-3/4">
              <button
                className="secondary-button w-24"
                onClick={() => {
                  history.goBack();
                }}>
                Back
              </button>

              <div className="flex flex-col w-full md:flex-row p-4 md:p-0 justify-between mt-3">
                <div
                  style={{ borderRadius: 20 }}
                  className="bg-white mb-5 shadow-md p-2 h-auto w-full md:w-5/12">
                  <div className="flex flex-row">
                    {athlete && (
                      <div className="px-2 flex flex-col w-full">
                        <div className="flex flex-col items-center mt-2">
                          <ProgressiveImage
                            src={
                              service.attributes.image_url
                                ? service.attributes.image_url
                                : TrayvoBlue
                            }
                            placeholder={TrayvoBlue}>
                            {src => (
                              <img
                                src={src}
                                className={`h-24 w-24 rounded-full object-cover mr-3 border-2 ${borderColor(
                                  service.attributes.training_type
                                )}`}
                              />
                            )}
                          </ProgressiveImage>

                          <div className="flex flex-col w-full mt-4 items-center">
                            <small className="regular-black text-left capitalize">
                              {service.attributes.name}
                            </small>

                            {service.attributes.training_type !== 'virtual' &&
                              service.attributes.provided_service_locations.data
                                .slice(0, 1)
                                .map(({ attributes: { location, address, id } }) => {
                                  return (
                                    <Fragment key={id}>
                                      <small className="text-primary-color uppercase font-sf-regular font-normal text-xs text-center mt-2">
                                        {location}
                                      </small>

                                      <small className="text-primary-color uppercase font-sf-regular font-normal text-xs text-center mt-2">
                                        {address}
                                      </small>
                                    </Fragment>
                                  );
                                })}

                            {service.attributes.training_type === 'virtual' && (
                              <small className="text-primary-color uppercase font-sf-regular font-normal text-md underline">
                                {service.attributes.connection_detail}
                              </small>
                            )}

                            <div className="text-center max-location-height overflow-hidden">
                              <small className="primary-text-regular text-md mt-1 uppercase">
                                {parseInt(service.attributes && service.attributes.duration)} mins
                              </small>

                              <small className="primary-text-regular text-md mt-1 uppercase ml-1">
                                @ {`$${parseInt(service.attributes && service.attributes.price)}`}
                              </small>
                            </div>

                            <TrainingBadge trainingType={service.attributes.training_type} />
                          </div>
                        </div>

                        <span style={{ fontSize: 14 }} className="md:flex text-md font-sf-regular text-t-gray text-center opacity-75 my-1 mt-2">
                          {service.attributes.discription}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="py-3">
                    <div className="flex flex-col my-2 p-2">
                      <TextContainer
                        title={getFormattedLocalDate(slot.training_start_date_time, 'MM-DD-YYYY')}
                        value={`${getFormattedLocalTime(slot.training_start_date_time)} - ${getFormattedLocalTime(slot.training_end_date_time)} (${getTimeZone()})`}
                      />
                      <TextContainer title="Tax" value="$0.00" />

                      <TextContainer
                        title="Subtotal"
                        value={priceFormatter(service.attributes.price)}
                      />

                      <TextContainer
                        bold
                        title="Total"
                        value={priceFormatter(service.attributes.price)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: 1, backgroundColor: '#D7DAE2' }}
                  className="my-10 hidden md:flex h-auto"
                />

                <div className="flex flex-col w-full md:w-1/3">
                  <div className="w-full">
                    <div className="mt-5 mr-5 w-full">
                      {user.data ? (
                        <div>
                          <div className="w-full flex justify-between mb-4">
                            <span className="primary-text-regular normal-case text-lg">
                              Player Details Review
                            </span>

                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={() => editDetails()}>
                              <div>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  size="xs"
                                  color="#2E83F5"
                                  className="mr-1"
                                />
                                <span className="text-blue-500 hover:text-gray-500 text-right text-xs">
                                  Edit
                                </span>
                              </div>
                            </button>
                          </div>

                          <p className="primary-text-regular normal-case text-sm mb-2">
                            Please review the details of the player you would like to book for this training
                          </p>

                          <CustomerDetail
                            key={cards}
                            cards={cards}
                            defaultCard={defaultCard}
                            editDetails={id => editDetails(id)}
                            user={user}
                            addCard={() => {
                              setModal(true);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="mt-2">
                          <LoginForm
                            onSuccess={() => {
                              setAuthModal(false);
                              setGuest(false);
                            }}
                            showSignup={guest => {
                              setAuthModal(true);
                              showLoginModal(false);
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {user.data && (
                      <div className="py-1 mt-4 mr-2">
                        <div className="w-full">
                          <textarea
                            placeholder="Add details for trainer"
                            value={note}
                            onChange={e => setNote(e.target.value)}
                            autoFocus
                            className="shadow w-full text-xs flex-1 p-2 bg-white rounded-md"></textarea>
                        </div>
                      </div>
                    )}

                    {user.data && (
                      <button
                        disabled={bookLoader}
                        onClick={() => {
                          !bookLoader && checkout();
                        }}
                        className="primary-dark-button w-full my-4">
                        Book Now
                        <ButtonLoader
                          visible={bookLoader}
                          type="ThreeDots"
                          color="#fff"
                          className="ml-3"
                          height={20}
                          width={30}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-container">
            <Loader />
          </div>
        )}

        <AuthModal
          visible={showAuthModal}
          guest={guest}
          loginModal={loginModal}
          showLoginModal={value => showLoginModal(value)}
          setAuthModal={value => {
            setAuthModal(value);
            setGuest(false);
          }}
          onSuccess={() => {
            setAuthModal(false);
            setGuest(false);
            setModal(true);
          }}
        />
      </div>

      <Paymentpopup
        visible={showModal}
        setModal={value => setModal(value)}
        submit={e => submit(e)}
        loader={loader}
        setError={error => setError(error)}
      />

      <CardPopup
        isVisible={cardModal}
        cards={cards}
        loader={loader}
        addNewCard={() => addNewCard()}
        setCardModal={value => setCardModal(value)}
        deleteCard={id => deleteCard(id)}
      />
    </div>
  );
}

const WrappedCheckout = observer(Checkout);

const CheckoutPage = props => {
  return (
    <div className="bg-white row justify-center" fluid>
      <div className="AppWrapper">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <WrappedCheckout {...props} />
        </Elements>
      </div>
    </div>
  );
};

export default observer(CheckoutPage);
