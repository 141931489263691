import { trimAfterFirstMatch } from './util'

// Regular expression of characters typically used to start a second phone number for the purposes
// of parsing. This allows us to strip off parts of the number that are actually the start of
// another number, such as for: (530) 583-6985 x302/x2303 -> the second extension here makes this
// actually two phone numbers, (530) 583-6985 x302 and (530) 583-6985 x2303. We remove the second
// extension so that the first number is parsed correctly.
//
// Matches a slash (\ or /) followed by a space followed by an `x`.
//
const SECOND_NUMBER_START_PATTERN = /[\\/] *x/

export default function parsePreCandidate(candidate)
{
	// Check for extra numbers at the end.
	// TODO: This is the place to start when trying to support extraction of multiple phone number
	// from split notations (+41 79 123 45 67 / 68).
	return trimAfterFirstMatch(SECOND_NUMBER_START_PATTERN, candidate)
}