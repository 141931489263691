import React, { useState, useContext, useEffect, useRef } from 'react';
import Loader from 'components/Shared/Loader';
import { useHistory } from 'react-router-dom';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';

function TrainingAndLocationSelect({ visible, closeModal, trainings, slug }) {
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [training, setTraining] = useState(null);
  const [isVirtual, setIsVirtual] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(false);
  const history = useHistory();
  const store = useContext(StoreContext);
  let continueDivRef = useRef(null);
  let selectLocationDivRef = useRef(null);

  useEffect(() => {
    store.userStore.resetLocationAddress();
  }, []);

  const onSelectTrainingOption = event => {
    setTraining(event.target.value);
    setLocation(null);
    setAddress(null);

    let selectedTraining = trainings.data.filter(
      item => item.attributes.slug === event.target.value
    )[0];

    setSelectedTraining(selectedTraining);

    setIsVirtual(selectedTraining && selectedTraining.attributes.training_type === 'virtual');

    setTimeout(
      () =>
        selectLocationDivRef &&
        selectLocationDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        }),
      500
    );
  };

  const onSelectTrainingLocation = event => {
    let split = event.target.value.split('|');
    setLocation(split[0].trim());
    setAddress(split[1].trim());
    store.userStore.setLocationAddress(split[0].trim(), split[1].trim(), selectedTraining.id);

    setTimeout(
      () =>
        continueDivRef &&
        continueDivRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        }),
      500
    );
  };

  const continueButton = () => {
    closeModal();
    history.push({
      pathname: `/${slug}/${training.split('-')[1]}`,
    });
  };

  return (
    <div
      style={{ zIndex: 2 }}
      className={
        visible
          ? `modal fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center`
          : 'hidden'
      }>
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
      <div
        className="bg-white rounded-lg shadow-md transform transition-all w-full"
        role="dialog"
        aria-modal="true"
        style={{ width: '500px' }}
        aria-labelledby="modal-headline">
        <div className="flex items-center flex-col p-2 bg-white rounded-md overflow-scroll max-70-vh">
          <button
            onClick={closeModal}
            className="hover:bg-secondary text-blue-900 text-sm hover:text-blue-500 py-2 px-2 rounded absolute top-0 right-0 font-bold focus:outline-none">
            X
          </button>

          <div className="flex flex-col">
            <div className="flex flex-col w-full text-center" onChange={onSelectTrainingOption}>
              <span className="regular-black my-4">Select Training Option</span>

              {trainings &&
                trainings.data.map(item => (
                  <div className="flex items-center my-2 primary-text-regular">
                    <input
                      type="radio"
                      checked={training && training === item.attributes.slug}
                      className="text-black mr-2 h-4 w-4 cursor-pointer"
                      value={item.attributes.slug}
                    />

                    {`${item.attributes.name}`}
                  </div>
                ))}
            </div>

            <div ref={selectLocationDivRef}>
              {training && (
                <div className="flex flex-col w-full" onChange={onSelectTrainingLocation}>
                  {!isVirtual && (
                    <div className="flex flex-col text-center">
                      <span className="my-2 regular-black">Select Training Location</span>

                      {trainings &&
                        trainings.data
                          .filter(item => item.attributes.slug === training)
                          .map(item =>
                            item.attributes.provided_service_locations.data
                              .filter(item => item.attributes.location && item.attributes.address)
                              .map((loc, index) => (
                                <div
                                  key={index}
                                  className="flex my-2 items-center primary-text-regular">
                                  <input
                                    type="radio"
                                    checked={
                                      location &&
                                      location === loc.attributes.location.trim() &&
                                      address &&
                                      address === loc.attributes.address.trim()
                                    }
                                    className="text-black mr-2 h-4 w-4 cursor-pointer"
                                    value={`${loc.attributes.location} | ${loc.attributes.address}`}
                                    name="location"
                                  />

                                  {`${loc.attributes.location}  |  ${loc.attributes.address}`}
                                </div>
                              ))
                          )}
                    </div>
                  )}

                  {((location && address) || isVirtual) && (
                    <button
                      type="button"
                      onClick={() => {
                        continueButton();
                      }}
                      className="primary-dark-button w-32 self-center mt-4">
                      Continue
                    </button>
                  )}

                  <div ref={continueDivRef} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(TrainingAndLocationSelect);
