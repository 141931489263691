import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from '../../index';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import { profileIcon, profileIcon_light } from '../../assets/img';
import ProgressiveImage from 'react-progressive-image';

function MyAccount(props) {
  const store = useContext(StoreContext);
  const history = useHistory();

  const logoutPressed = () => {
    localStorage.clear();
    store.userStore.logout();
    history.push('/');
  };
  let currentUser = toJS(store.userStore.currentUser);

  return (
    <div
      style={{ top: '80px' }}
      className="fixed top-0 right-0 px-4 pb-4  sm:flex sm:items-center sm:justify-center">
      <div
        className="my-10"
        className="bg-white rounded-lg  shadow-xl transform transition-all overflow-scroll"
        role="dialogue"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div className="bg-white rounded overflow-hidden shadow-lg">
          <div className="text-center p-6  border-b">
            <ProgressiveImage
              src={
                currentUser.data && currentUser.data.attributes.profile_image
                  ? currentUser.data.attributes.profile_image
                  : profileIcon
              }
              placeholder={profileIcon_light}>
              {src => <img src={src} className="h-24 w-24 rounded-full mx-auto object-cover" />}
            </ProgressiveImage>

            <p className="pt-2 text-lg font-sf-semibold">
              {currentUser.data && currentUser.data.attributes.first_name}{' '}
              {currentUser.data && currentUser.data.attributes.last_name}
            </p>

            <p className="text-sm text-gray-600 font-sf-regular">
              {currentUser.data && currentUser.data.attributes.email}
            </p>

            {currentUser.data && currentUser.data.attributes.guest_user && (
              <p className="text-sm text-gray-600 font-sf-regular">(Guest User)</p>
            )}
          </div>

          {currentUser.data && !currentUser.data.attributes.guest_user && (
            <div className="border-b">
              <a href="/profile" className="px-4 py-2 pb-4 hover:bg-gray-100 flex">
                <p className="primary-text-regular text-sm">Profile</p>
              </a>

              <a href="/payment_settings" className="px-4 py-2 pb-4 hover:bg-gray-100 flex">
                <p className="primary-text-regular text-sm">Payment</p>
              </a>

              <a
                onClick={() => {
                  logoutPressed();
                }}
                className="px-4 py-2 pb-4 hover:bg-gray-100 flex">
                <p className="primary-text-regular text-sm">Logout</p>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(MyAccount);
