import React, { useContext, useEffect, useState } from 'react';
import { notify } from '../../../utils/utilities';
import Loader from '../../Shared/Loader';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { InputLabel } from '../../Shared';

function NewPassword() {
  const history = useHistory();
  const store = useContext(StoreContext);

  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    let url = window.location.href;
    setResetPasswordToken(url.split('=')[1]);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== confirmPassword) {
      notify('Password does’t match!');
      return;
    }

    setLoader(true);

    store.userStore
      .updateWithToken({
        client: {
          reset_password_token: resetPasswordToken,
          password,
          password_confirmation: confirmPassword,
        },
      })
      .then(result => {
        setLoader(false);

        if (result.data && result.data.id) {
          notify('Password Updated Successfully!');
          setPassword('');
          setConfirmPassword('');
          history.push('/');
        }
      });
  };

  return (
    <div className="bg-main-background">
      <div className="min-h-screen h-full flex justify-center">
        <div className="flex justify-center md:w-1/3">
          <div className="w-full flex justify-center items-center min-h-full h-full">
            <div className="w-full p-5  rounded-lg bg-white min-w-full shadow-lg">
              <div className="px-2 my-2 text-center">
                <h3 className="medium-black text-xl">New password</h3>

                <p className="primary-text-regular normal-case">
                  Enter new password for your account.
                </p>
              </div>

              <form className="pt-6 pb-8 mb-4 bg-white rounded" onSubmit={e => onSubmit(e)}>
                <div className="mb-4">
                  <InputLabel label="Password" />
                  <input
                    className="text-input"
                    id="pass"
                    type="password"
                    minLength={7}
                    placeholder="New password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoFocus={true}
                    required
                  />
                </div>

                <div className="mb-4">
                  <InputLabel label="Confirm password" />
                  <input
                    className="text-input"
                    id="confirmPass"
                    type="password"
                    minLength={7}
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="mt-8 text-center">
                  <button className="primary-dark-button w-full" type="submit">
                    Reset Password
                    {loader && (
                      <div className="ml-2">
                        <Loader small />
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(NewPassword);
