import React from 'react';
import { useHistory } from 'react-router-dom';

const FAQ = ({ ques, ans }) => (
  <div className="mt-8 border-t border-gray-400 pt-6 md:grid md:grid-cols-12 md:gap-8">
    <dt className="leading-6 text-primary-color SF-medium md:col-span-5 text-sm font-bold">
      {ques}
    </dt>

    <dd className="mt-2 md:mt-0 md:col-span-7">
      <p className="leading-6 text-primary-color opacity-75 SF-regular text-sm">{ans}</p>
    </dd>
  </div>
);

function Faq(props) {
  return (
    <div className="bg-main-background">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 pt-40 md:pt-16">
        <h2 className="text-3xl leading-9 font-extrabold text-primary-color sm:text-2xl sm:leading-10">
          Frequently asked questions
        </h2>

        <div className="mt-6 border-t-2 border-gray-400 pt-6">
          <dl>
            <div className="md:grid md:grid-cols-12 md:gap-8">
              <dt className="leading-6 text-primary-color SF-medium font-bold md:col-span-5">
                FAQ Question 1?
              </dt>

              <dd className="mt-2 md:mt-0 md:col-span-7">
                <p className="leading-6 text-primary-color opacity-75 SF-regular text-sm">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to make a type specimen
                  book. It has survived not only five centuries
                </p>
              </dd>
            </div>

            <FAQ
              ques={'FAQ Question 2?'}
              ans={
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an'
              }
            />

            <FAQ
              ques={'FAQ Question 3?'}
              ans={
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an'
              }
            />

            <FAQ
              ques={'FAQ Question 4?'}
              ans={
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an'
              }
            />

            <FAQ
              ques={'FAQ Question 5?'}
              ans={
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an'
              }
            />

            <FAQ
              ques={'FAQ Question 6?'}
              ans={
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an'
              }
            />
          </dl>
        </div>
      </div>
    </div>
  );
}

export default Faq;
