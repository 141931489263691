import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import Loader from 'components/Shared/Loader';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: 300,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '13px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: 'gray',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement type="text" id="name" options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({ label, id, type, placeholder, required, autoComplete, value, onChange }) => (
  <div className="FormRow">
    <input
      className="FormRowInput focus:outline-none"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      style={{ border: 'none' }}
    />
  </div>
);

const Paymentpopup = props => {
  return (
    <div
      style={{ zIndex: 2 }}
      className={
        props.visible
          ? `fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center`
          : 'hidden'
      }>
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>

      <div
        className="bg-white rounded-lg shadow-xl transform transition-all max-w-lg w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div className="overflow-scroll max-h-screen">
          <form className="w-full" onSubmit={e => props.submit(e)}>
            <div className="px-0 md:px-4 pt-5 pb-4">
              <div>
                <div className="mt-3 text-center">
                  <h3 style={{ fontSize: 22 }} className="medium-black my-4" id="modal-headline">
                    Add card
                  </h3>

                  <fieldset className="FormGroup" id="fieldSet">
                    <Field
                      id="name"
                      type="text"
                      placeholder="Name on card"
                      required
                      autoComplete="name"
                    />
                  </fieldset>

                  <fieldset className="FormGroup" id="fieldSet">
                    <CardField
                      onChange={e => {
                        props.setError(e.error);
                      }}
                    />
                  </fieldset>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button type="submit" className="primary-dark-button w-full md:w-32">
                  Submit
                </button>
              </span>

              <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => {
                    props.setModal(false);
                  }}
                  type="button"
                  className="primary-button w-full md:w-32">
                  Cancel
                </button>
              </span>
            </div>

            {props.loader && (
              <div className="flex justify-center items-center my-5">
                <Loader small />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Paymentpopup;
