import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import SweetAlert from 'react-bootstrap-sweetalert';

function DisableNotifications(props) {
  const store = useContext(StoreContext);
  const [confirm, setConfirm] = useState(false);
  const [success, showSuccess] = useState(false);

  useEffect(() => {
    setConfirm(true);
  }, []);

  const onConfirm = async () => {
    let {
      match: { params },
    } = props;

    setConfirm(false);

    store.userStore.disableNotification(params.id).then(result => {
      if (result.status === 200) showSuccess(true);
    });
  };

  return (
    <div className="bg-auth">
      <SweetAlert
        custom
        show={confirm}
        showCancel
        confirmBtnText="Sure"
        cancelBtnText="No"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="light"
        title="Do you want to disable?"
        onConfirm={onConfirm}
        onCancel={() => window.close()}>
        You will not be notified for any of the future availability against this training.
      </SweetAlert>

      <SweetAlert
        show={success}
        success
        title="Notifications disabled!"
        onConfirm={() => {
          window.close();
        }}></SweetAlert>
    </div>
  );
}

export default observer(DisableNotifications);
