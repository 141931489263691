import React, { useState, useRef, useContext, useReducer } from 'react';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Shared/Loader';
import { appendSymInStart, isValidContactNumber } from '../../../utils/utilities';
import 'react-datepicker/dist/react-datepicker.css';
import { toJS } from 'mobx';
import moment from 'moment';
import {
  getLink,
  notify,
  getPositionLabels,
  validateSocialHandles,
} from '../../../utils/utilities';
import { AccountInfo, SocialInfo, PlayerInfo } from './index';
import { customStyles } from '../../../constants';

const ProgressCircle = ({ step, fill }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={`w-6 h-6 font-sf-regular items-center justify-center text-white text-xs ${fill ? 'bg-primary-color' : 'bg-gray-500 border-2 border-gray-500'
          }  mx-auto rounded-full flex items-center`}>
        {fill && step}
      </div>
    </div>
  );
};

const ProgressBar = ({ fill }) => {
  return (
    <div className={`${'w-1/3 trans'} align-center items-center align-middle content-center flex`}>
      <div
        className={`w-full ${fill ? 'bg-primary-color' : 'bg-gray-500 '
          } rounded items-center align-middle align-center flex-1`}>
        <div
          style={{ height: 2 }}
          className="text-xs leading-none text-center text-grey-darkest rounded"
        />
      </div>
    </div>
  );
};

function SignupForm(props) {
  const history = useHistory();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(false);
  const [invalidPhone, setInvalid] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  let sports = toJS(store.userStore.sports);
  let sportPositions = toJS(store.userStore.sportPositions);

  let { guest, onSuccess, showLogin } = props;

  const initialState = {
    step: 1,
    firstName: '',
    lastName: '',
    email: '',
    currentLocation: '',
    currentLocationData: null,
    hometownLocation: '',
    hometownLocationData: null,
    about: '',
    website: '',
    instagram: '',
    facebook: '',
    twitter: '',
    tiktok: '',
    sport: null,
    contact: '',
    password: '',
    passwordConfirm: '',
    gender: '',
    passing_year: null,
    positionFirst: null,
    positionSecond: null,
    positionThird: null,
    jerseyNumber: null,
    school: null,
    college: null,
    middleSchool: null,
    club: '',
    createAccount: false,
    dob: '',
    img: null,
    imgFile: null,
    socialError: '',
    timeZone: 'America/New_York',
  };

  let mainDivRef = useRef(null);

  function reducer(state, action) {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const incrementStep = () => {
    dispatch({ field: 'step', value: provider.step + 1 });
    mainDivRef.current.scrollTo(0, 0);
  };

  const decrementStep = () => {
    dispatch({ field: 'step', value: provider.step - 1 });
  };

  const onImageChange = async e => {
    const file = e.target.files[0];
    const image = URL.createObjectURL(file);
    dispatch({ field: 'img', value: image });
    dispatch({ field: 'imgFile', value: file });
  };

  const submitSignup = async e => {
    e.preventDefault();

    if (provider.step === 1) {
      if ((!guest || provider.createAccount) && provider.password !== provider.passwordConfirm)
        notify('Password does not match!');
      else if (invalidPhone || provider.contact === '') notify('Invalid contact number!');
      else if (!provider.dob) notify('Please select date of birth!');
      else if (!provider.gender) notify('Please select your gender!');
      else incrementStep();
    } else if (provider.step === 2) {
      if (!provider.currentLocationData) notify('Enter current location!');
      // else if (!provider.hometownLocationData) notify('Enter hometown location!');
      else if (provider.sport == null) notify('Select sport!');
      else if (!provider.positionFirst && !provider.positionSecond && !provider.positionThird)
        notify('Select position!');
      // else if (!provider.imgFile || !provider.img) notify('Upload a profile picture!');
      else incrementStep();
    } else if (provider.step === 3) {
      if (provider.socialError !== '') {
        notify('Enter correct social username!');
      } else {
        let payload = {
          first_name: provider.firstName,
          last_name: provider.lastName,
          email: provider.email,
          contact_number: provider.contact,
          city: provider.currentLocationData.city,
          state: provider.currentLocationData.state,
          postal_code: provider.currentLocationData.zip,
          dob: moment(provider.dob).format('YYYY-MM-DD'),
          passing_year: provider.passing_year ? provider.passing_year.value : 2021,
          high_school: provider.school && provider.school.label,
          college_id: provider.college && provider.college.value,
          club: provider.club,
          jersey_number: provider.jerseyNumber,
          position: getPositions(),
          gender: provider.gender.value,
          // hometown_city: provider.hometownLocationData.city,
          // hometown_state: provider.hometownLocationData.state,
          // hometown_postal_code: provider.hometownLocationData.zip,
          about: provider.about,
          sport_id: provider.sport.id,
          website: getLink(provider.website),
          tiktok: provider.tiktok,
          facebook: provider.facebook,
          instagram: provider.instagram,
          twitter: provider.twitter,
          middle_school: provider.middleSchool,
          time_zone: provider.timeZone,
        };

        if (!guest || provider.createAccount) {
          payload.password = provider.password;
          payload.password_confirmation = provider.passwordConfirm;
        }

        setLoader(true);

        if (provider.imgFile) {
          let data = await store.userStore.getProfileImageSignedUrl();
          await store.userStore.uploadImage(provider.imgFile, data.signed_url);
          payload.profile_image = data.url;
        }

        if (!guest) {
          store.userStore.signUp(payload).then(result => {
            setLoader(false);

            if (result.data && result.data.attributes) {
              store.paymentStore.getCards();
              dispatch({ field: 'step', value: 1 });
              onSuccess ? onSuccess() : history.push('/bookings');
            }
          });
        } else {
          store.userStore.createGuest(payload).then(result => {
            setLoader(false);
            dispatch({ field: 'step', value: 1 });
            props.closePopup();
          });
        }
      }
    }
  };

  const validatePhone = value => {
    if (isValidContactNumber(value)) {
      setInvalid(false);
      dispatch({ field: 'contact', value });
    } else setInvalid(true);
  };

  const onChange = e => {
    const { name, value } = e.target;
    dispatch({ field: name, value: name === 'tiktok' ? appendSymInStart(value, '@') : value });

    if (e.target.name === 'facebook')
      dispatch({
        field: 'socialError',
        value:
          e.target.value !== '' ? (!validateSocialHandles(e.target.value) ? 'facebook' : '') : '',
      });
    else if (e.target.name === 'instagram')
      dispatch({
        field: 'socialError',
        value:
          e.target.value !== '' ? (!validateSocialHandles(e.target.value) ? 'instagram' : '') : '',
      });
    else if (e.target.name === 'twitter')
      dispatch({
        field: 'socialError',
        value:
          e.target.value !== '' ? (!validateSocialHandles(e.target.value) ? 'twitter' : '') : '',
      });
    // else if (e.target.name === 'tiktok')
    //   dispatch({
    //     field: 'socialError',
    //     value:
    //       e.target.value !== '' ? (!validateSocialHandles(e.target.value) ? 'tiktok' : '') : '',
    //   });
  };

  const getSchoolsBySearch = async search => {
    let data = await store.userStore.getSchoolsBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  const getCollegesBySearch = async search => {
    let data = await store.userStore.getCollegesBySearch({ q: { nickname_or_city_start: search } });
    return data;
  };

  const getSportPositions = async id => {
    await store.userStore.getSportPositions(id);
  };

  const resetPositions = () => {
    dispatch({ field: 'positionFirst', value: null });
    dispatch({ field: 'positionSecond', value: null });
    dispatch({ field: 'positionThird', value: null });
  };

  const getPositions = () => {
    let { positionFirst, positionSecond, positionThird } = provider;
    let positions = [];
    if (positionFirst !== null) positions = [...positions, positionFirst.value];
    if (positionSecond !== null) positions = [...positions, positionSecond.value];
    if (positionThird !== null) positions = [...positions, positionThird.value];

    const uniquePositions = [...new Set(positions)];
    return uniquePositions;
  };

  return (
    <div className="ml-auto mr-auto content-center flex justify-center items-center rounded-lg max-h-screen">
      <div
        ref={mainDivRef}
        className={`bg-white max-90-vh overflow-scroll flex-row d-flex justify-center w-full shadow-md my-3 ${!onSuccess && 'sm:w-1/2 md:w-2/5'
          }`}
        style={{ borderRadius: 12 }}>
        <div className="container">
          <div className="flex flex-col content-center items-center justify-center w-full py-3 pb-5">
            <h3
              style={{ fontSize: '18px' }}
              className="font-sf-regular text-primary-color text-center mb-3">
              {provider.step === 1
                ? 'Player Info'
                : provider.step === 2
                  ? 'Team Info'
                  : 'Social Info'}
            </h3>

            <div className="pt-3 w-full">
              <div className="flex">
                <div className="flex-1" />

                <ProgressCircle fill step={'1'} />

                <ProgressBar fill={provider.step >= 2} />

                <ProgressCircle fill={provider.step >= 2} step={'2'} />

                <ProgressBar fill={provider.step >= 3} />

                <ProgressCircle fill={provider.step >= 3} step={'3'} />

                <div className="flex-1" />
              </div>

              <div className="flex text-xs content-center text-center justify-center px-8">
                <div className="w-1/3 text-xs text-primary-color text-left">Player Info</div>

                <div className="w-1/4 text-xs text-primary-color">Team Info</div>

                <div className="w-1/3 text-xs text-primary-color text-right">Social Info</div>
              </div>
            </div>
          </div>

          <form className="rounded px-8 pb-4" onSubmit={e => submitSignup(e)}>
            {provider.step === 1 && (
              <AccountInfo
                provider={provider}
                dispatch={dispatch}
                onChange={onChange}
                validatePhone={validatePhone}
                invalidPhone={invalidPhone}
                guest={guest}
                customStyles={customStyles}
                setDob={date => {
                  dispatch({ field: 'dob', date });
                }}
                changePrivacyChecked={setPrivacyChecked}
                privacyChecked={privacyChecked}
              />
            )}

            {provider.step === 2 && (
              <PlayerInfo
                provider={provider}
                dispatch={dispatch}
                onChange={onChange}
                onImageChange={onImageChange}
                guest={guest}
                sports={sports}
                customStyles={customStyles}
                getSchoolsBySearch={getSchoolsBySearch}
                getCollegesBySearch={getCollegesBySearch}
                getSportPositions={getSportPositions}
                resetPositions={resetPositions}
                sportPositions={getPositionLabels(sportPositions)}
              />
            )}

            {provider.step === 3 && (
              <SocialInfo
                provider={provider}
                dispatch={dispatch}
                onChange={onChange}
                guest={guest}
                customStyles={customStyles}
              />
            )}

            {loader && (
              <div className="flex justify-center items-center">
                <Loader small />
              </div>
            )}

            <div className="flex flex-col md:flex-row w-full justify-between mt-10">
              {provider.step === 1 ? (
                <div className="flex my-2 md:my-0 items-center">
                  <span className="font-sf-Regular text-xs text-gray-600">
                    Already have an account?
                  </span>

                  <button
                    onClick={() => {
                      if (showLogin) showLogin();
                      else history.push('/login');
                    }}
                    className="text-xs text-blue-500 hover:text-primary-color font-normal underline px-1 hover:underline rounded focus:outline-none focus:shadow-outline">
                    Sign in
                  </button>
                </div>
              ) : (
                <div className="flex my-2 md:my-0 items-center" />
              )}

              <div className="flex items-center">
                {provider.step === 3 && (
                  <button
                    type="submit"
                    value="skip"
                    name="skip"
                    className="px-3 focus:outline-none underline rounded text-primary-color hover:text-blue-500 text-xs">
                    Skip
                  </button>
                )}

                {provider.step != 1 && (
                  <button
                    onClick={() => {
                      decrementStep();
                    }}
                    className="font-sf-regular w-full md:w-24 mr-1 border border-gray-600 text-primary-color hover:bg-primary-color hover:text-white text-xs py-2 rounded-lg focus:outline-none"
                    type="button">
                    Back
                  </button>
                )}

                <button
                  disabled={loader || !privacyChecked}
                  className={`font-sf-regular w-full md:w-24 border border-gray-600 text-primary-color hover:bg-primary-color hover:text-white text-xs py-2 rounded-lg focus:outline-none ${!privacyChecked ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  type="submit">
                  {provider.step != 3 ? 'Continue' : 'Sign up'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(SignupForm);
