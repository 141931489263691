export const brand = 'trayvo';
export const loginDescription = 'Please sign in to book this training option';
export const aboutUsHeading = `About ${brand} trainer`;
export const aboutUsWelcome =
  "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins";
export const aboutUsDescription =
  'trayvo will revolutionise sport specific athletic training. By working directly with the nation’s top college athletes, aspiring young athletes are able to book one on one training with an athlete of their choice. Whether it is speed/quickness work, weight training, positional mechanics or even academics, college athletes will help every young athlete be the best they can be.';
export const newAccountSubHead = 'Please create an account to book this training option';

export const notificationEnabled = (serviceName, athleteName) =>
  `You will receive a notification when ${athleteName} adds new openings for ${serviceName}`;
