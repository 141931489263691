import api from '../store/api';
import { decorate, observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import { errorHandler } from 'utils/middlewares/errorHandler';
import { client } from 'store';
import { notify } from '../utils/utilities';

class PaymentStore {
  creditCards = [];
  defaultCard = {};

  getCards = async => {
    return client()
      .get(api.cards())
      .then(
        action('fetchSuccess', ({ data }) => {
          this.creditCards = data.data;
          if (data.data.length > 0) {
            this.defaultCard = data.data[0];
          }
          return data.data;
        }),
        action('fetchError', error => {
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  createCard = async id => {
    return client()
      .post(api.cards(), { token: id })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          console.log(error.response);
          errorHandler(error.response);
          return error;
        })
      );
  };

  deleteCard = async id => {
    return client()
      .delete(api.cardDelete(id))
      .then(
        action('fetchSuccess', data => {
          notify('Deleted successfuly!');
          return data;
        }),
        action('fetchError', error => {
          console.log(error.response);
          errorHandler(error.response);
          return error;
        })
      );
  };

  changeClientStatus = async (id, status) => {
    let payload = { booking: { status } };
    return client()
      .put(`${api.book()}/${id}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          notify('Status updated successfuly!');
          return data.data;
        }),
        action('fetchError', error => {
          console.log(error.response);
          errorHandler(error.response);
          return error.response;
        })
      );
  };

  transferCharge = async (id, accountId) => {
    let payload = {
      payment: {
        account_id: accountId,
      },
    };
    return client()
      .post(api.transferCharge(id), payload)
      .then(
        action('fetchSuccess', data => {
          notify('Status updated successfuly!');
          return data;
        }),
        action('fetchError', error => {
          console.log(error.response);
          errorHandler(error.response);
          return error;
        })
      );
  };

  changeDefaultCard = defaultCard => {
    this.defaultCard = defaultCard;
  };
}

decorate(PaymentStore, {
  creditCards: [persist('list'), observable],
  defaultCard: [persist('object'), observable],
  getCards: action,
  deleteCard: action,
  createCard: action,
  changeDefaultCard: action,
});

export default PaymentStore;
