import React from 'react';
import Select from 'react-select';
import { InputLabel, GooglePlacesInput } from '../../Shared';
import 'react-datepicker/dist/react-datepicker.css';
import { passingYear } from '../../../constants';
import { customStyles, removeArrowInSelect, removeSeparator } from '../../../constants';
import Async from 'react-select/async';
import { ifCurrentPlayer, ifPassedIn } from '../../../utils/utilities';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

function PlayerInfo({
  provider,
  onChange,
  dispatch,
  currentUser,
  sports,
  getSchoolsBySearch,
  getCollegesBySearch,
  getSportPositions,
  resetPositions,
  sportPositions,
}) {
  let passingClass = provider.passingClass
    ? provider.passingClass.value
    : currentUser && currentUser.passing_year;

  return (
    <div>
      <div className="mt-8 pb-8">
        <div className="container-wrap">
          <div className={ifPassedIn(passingClass) ? 'container-half pr-2' : 'w-full'}>
            <InputLabel
              label={ifCurrentPlayer(passingClass) ? 'Middle School' : 'School'}
              color="black"
            />

            {ifCurrentPlayer(passingClass) ? (
              <div>
                <input
                  className="text-input form-control"
                  name="middleSchool"
                  type="text"
                  value={
                    provider.middleSchool
                      ? provider.middleSchool
                      : currentUser && currentUser.middle_school
                  }
                  placeholder="Middle School"
                  onChange={e => onChange('middleSchool', e.target.value)}
                  required
                />
              </div>
            ) : (
              <Async
                components={removeSeparator}
                className="primary-text-regular"
                placeholder="Select school"
                backspaceRemovesValue={true}
                styles={Object.assign(customStyles, removeArrowInSelect)}
                value={provider.school && provider.school.label ? provider.school : null}
                loadOptions={getSchoolsBySearch}
                isSearchable
                isClearable
                onChange={highSchool => onChange('school', highSchool)}
              />
            )}
          </div>
          {ifPassedIn(passingClass) && (
            <div className="container-half">
              <InputLabel label="College Commitment" color="black" />

              <Async
                components={removeSeparator}
                className="primary-text-regular"
                placeholder="Select college"
                styles={Object.assign(customStyles, removeArrowInSelect)}
                value={provider.college && provider.college.label ? provider.college : null}
                loadOptions={getCollegesBySearch}
                isSearchable
                isClearable
                onChange={college => onChange('college', college)}
              />
            </div>
          )}
        </div>

        <div className="container-wrap mt-3">
          <InputLabel label="Club" />

          <input
            className="text-input form-control"
            placeholder="Enter Club"
            value={provider.club !== null ? provider.club : currentUser && currentUser.club}
            onChange={e => onChange('club', e.target.value)}
          />
        </div>

        <div className="flex flex-col w-full mt-3">
          <InputLabel label="Sport" />

          <div>
            <Select
              styles={customStyles}
              className="primary-text-regular bg-main-background"
              components={removeSeparator}
              placeholder="Select sport"
              value={
                provider.sport
                  ? provider.sport
                  : currentUser &&
                    currentUser.sport_id && {
                      label: sports.filter(item => parseInt(item.id) === currentUser.sport_id)[0]
                        .label,
                      value: sports.filter(item => parseInt(item.id) === currentUser.sport_id)[0]
                        .value,
                    }
              }
              isSearchable
              onChange={selectedOption => {
                getSportPositions(selectedOption.id);
                resetPositions();
                onChange('sport', selectedOption);
              }}
              options={sports}
            />
          </div>
        </div>

        <div className="container-wrap mt-3">
          <div className="container-half pr-0 md:pr-3">
            <InputLabel label="Grad Year" />

            <div className="w-full">
              <Select
                styles={customStyles}
                className="primary-text-regular bg-main-background"
                isSearchable={false}
                placeholder={'Select class'}
                components={removeSeparator}
                value={
                  provider.passingClass
                    ? provider.passingClass
                    : currentUser && {
                        label: currentUser.passing_year,
                        value: currentUser.passing_year,
                      }
                }
                onChange={selectedOption => onChange('passingClass', selectedOption)}
                options={passingYear}
                menuPosition="fixed"
              />
            </div>
          </div>

          <div className="container-half">
            <InputLabel label="Jersey #" />

            <div>
              <input
                className="text-input form-control"
                name="jerseyNumber"
                type="number"
                value={
                  provider.jerseyNumber !== null
                    ? provider.jerseyNumber
                    : currentUser && currentUser.jersey_number
                }
                placeholder="Jersey #"
                onChange={e => onChange('jerseyNumber', e.target.value)}
              />
            </div>
          </div>
        </div>

        <InputLabel label="Position" />
        <div className="flex w-full">
          <div className="w-full">
            <Select
              styles={customStyles}
              placeholder="Position"
              className="primary-text-regular bg-main-background"
              components={removeSeparator}
              value={provider.positionFirst}
              onChange={selectedOption => onChange('positionFirst', selectedOption)}
              options={sportPositions}
              required
              menuPosition="fixed"
            />
          </div>

          <div className="w-full px-2">
            <Select
              styles={customStyles}
              placeholder="Position 2"
              className="primary-text-regular bg-main-background"
              components={removeSeparator}
              value={provider.positionSecond}
              onChange={selectedOption => onChange('positionSecond', selectedOption)}
              options={sportPositions}
              required
              menuPosition="fixed"
            />
          </div>

          <div className="w-full">
            <Select
              styles={customStyles}
              placeholder="Position 3"
              className="primary-text-regular bg-main-background"
              components={removeSeparator}
              value={provider.positionThird}
              onChange={selectedOption => onChange('positionThird', selectedOption)}
              options={sportPositions}
              required
              menuPosition="fixed"
            />
          </div>
        </div>

        <div className="w-full mt-3">
          <InputLabel label="Time Zone" />

          <TimezonePicker
            className="w-full"
            absolute={false}
            value={
              provider.timeZone !== null ? provider.timeZone : currentUser && currentUser.time_zone
            }
            placeholder="Select timezone..."
            onChange={timeZone => dispatch({ field: 'timeZone', value: timeZone })}
          />
        </div>

        <div className="flex w-full mt-3">
          <div className="flex flex-col w-full">
            <InputLabel label="Current City" />

            <div className="w-full">
              <GooglePlacesInput
                value={
                  provider.currentLocation === ''
                    ? {
                        label: `${currentUser.city} ${currentUser.state} ${currentUser.postal_code}`,
                        value: { place_id: '' },
                      }
                    : provider.currentLocation
                }
                setValue={val => dispatch({ field: 'currentLocation', value: val })}
                setData={val => dispatch({ field: 'currentLocationData', value: val })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerInfo;
