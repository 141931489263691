import React from 'react';
import { brand } from '../../stringConstants';
import { PLAYER_BASE_URL } from '../../constant';

const TrayvoBadge = ({ color }) => {
  return (
    <div
      style={color && { backgroundColor: color }}
      className="bg-primary-color rounded-md hover:bg-gray-700">
      <a href={PLAYER_BASE_URL} target="_blank" className="flex font-sf-regular text-sm px-3 py-2">
        <small style={{ color: '#CECECE' }}>fueled by</small>

        <small className="text-white pl-1">{brand}</small>
      </a>
    </div>
  );
};

export default TrayvoBadge;
