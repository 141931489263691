import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'index';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { loadStripe } from '@stripe/stripe-js';
import Paymentpopup from '../../Popup/Paymentpopup';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import '../Checkout/card.scss';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);

function PaymentSettings() {
  const store = useContext(StoreContext);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [showModal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [delAlert, setDelAlert] = useState(false);
  const [cardToDelete, saveDeleteCard] = useState(null);
  const [error, setError] = useState(null);

  let cards = toJS(store.paymentStore.creditCards);
  let defaultCard = toJS(store.paymentStore.defaultCard);

  useEffect(() => {
    store.userStore.setProfilePopup(false);
  }, []);

  const submit = async e => {
    e.preventDefault();
    if (error) {
      elements.getElement('card').focus();
      return;
    }
    setLoader(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    await store.paymentStore.createCard(result.token.id);
    await store.paymentStore.getCards();
    setLoader(false);
    setModal(false);
  };

  const addNewCard = () => {
    setModal(true);
  };

  const showDelAlert = id => {
    setDelAlert(true);
    saveDeleteCard(id);
  };

  const deleteCard = async () => {
    setDelAlert(false);
    await store.paymentStore.deleteCard(cardToDelete);
    store.paymentStore.getCards();
  };

  return (
    <div>
      <div className="bg-main-background min-h-screen py-5 px-2 pt-24 justify-center flex">
        <div className="w-full md:w-3/4 flex flex-col mb-3 p-4">
          <span
            style={{ fontFamily: 'SF-regular', fontSize: 18 }}
            className="text-xs text-primary-color mb-3">
            Payment settings
          </span>
          <div className="bg-white shadow-md">
            {cards.map(item => {
              return (
                <div className=" border-b border-gray-600">
                  <div
                    onClick={() => {
                      store.paymentStore.changeDefaultCard(item);
                    }}
                    className={`cursor-pointer h-12 w-full rounded items-center justify-between content-center xs:flex-col sm:flex px-4 ${
                      defaultCard.id === item.id && 'border-gray-600'
                    }`}>
                    <div className="flex flex-row items-center xs:w-full sm:w-48 justify-between">
                      <small
                        style={{ fontFamily: 'SF-regular', fontSize: 13 }}
                        className="text-xs text-black">
                        {item.attributes.brand}
                      </small>

                      <small
                        style={{ fontFamily: 'SF-regular', fontSize: 13 }}
                        className="text-xs text-center text-black">
                        **** **** **** {item.attributes.last4}
                      </small>
                    </div>

                    <div className="flex flex-row items-center xs:w-full sm:w-32 justify-between ">
                      <small
                        style={{ fontFamily: 'SF-regular', fontSize: 12 }}
                        className="text-xs text-black">{`${item.attributes.exp_month} / ${item.attributes.exp_year}`}</small>

                      {defaultCard.id === item.id && (
                        <small
                          style={{ fontFamily: 'SF-regular', fontSize: 12 }}
                          className="text-xs text-blue-800">
                          {'Default'}
                        </small>
                      )}

                      <a
                        onClick={() => showDelAlert(item.id)}
                        className="hover:bg-light-gray h-6 w-6 rounded-md items-center flex content-center justify-center">
                        <FontAwesomeIcon icon={faTrash} style={{ color: 'black' }} />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="bg-primary-color p-3 flex justify-end">
              <span className="flex rounded-md shadow-sm ml-3 w-auto">
                <button
                  style={{ fontFamily: 'SF-regular', fontSize: 16 }}
                  onClick={() => addNewCard()}
                  type="submit"
                  className="secondary-button">
                  + Add New
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Paymentpopup
        visible={showModal}
        setModal={value => setModal(value)}
        loader={loader}
        submit={e => submit(e)}
        setError={error => setError(error)}
      />

      <SweetAlert
        show={delAlert}
        warning
        showCancel
        confirmBtnStyle={{ padding: 10 }}
        cancelBtnStyle={{ padding: 10 }}
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => deleteCard()}
        onCancel={() => setDelAlert(false)}>
        You will not be able to recover!
      </SweetAlert>
    </div>
  );
}

const PaymentSettingsObserver = observer(PaymentSettings);

const PaymentsSettingsScreen = props => {
  return (
    <div className="bg-white row justify-center" fluid>
      <div className="AppWrapper">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <PaymentSettingsObserver {...props} />
        </Elements>
      </div>
    </div>
  );
};

export default observer(PaymentsSettingsScreen);
