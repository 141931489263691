import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../utils/utilities';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() && restricted ? <Redirect to="/bookings" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
