import React from 'react';
import { InputLabel } from '../../Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { formattedPositions } from '../../../utils/utilities';

const DetailBlock = ({ value, id, exist, addCard }) => (
  <div className="w-full pr-2">
    <InputLabel label={id === 'card' ? 'Card number' : id} />

    <div
      style={{ minHeight: '38px' }}
      className="py-1 px-2 bg-white mb-2 flex items-center shadow rounded-md">
      <div className="row flex w-full items-center px-1">
        <div className="flex-1">
          {id === 'card' && !exist ? (
            <a onClick={() => addCard()}>
              <small className="text-xs ml-3 text-blue-400 hover:text-blue-200">
                {'Add a payment method'}
              </small>
            </a>
          ) : (
            <small className={`primary-text-regular ${id === 'email' && 'lowercase'}`}>
              {value}
            </small>
          )}
        </div>
      </div>
    </div>
  </div>
);

const CustomerDetail = ({ editDetails, user, cards, addCard, defaultCard }) => {
  const getRespectiveAttribute = item => {
    switch (item) {
      case 'firstName':
        return user.data.attributes.first_name;
      case 'lastName':
        return user.data.attributes.last_name;
      case 'address':
        return `${user.data.attributes.city}, ${user.data.attributes.state}`;
      case 'email':
        return user.data.attributes.email;
      case 'position':
        return formattedPositions(user.data.attributes.position);
      case 'highSchool':
        return user.data.attributes.high_school;
      case 'college':
        return user?.data?.attributes?.college?.data?.attributes?.nickname
      case 'club':
        return user.data.attributes.club;
      case 'class':
        return user.data.attributes.passing_year;
      case 'phoneNumber':
        return user.data.attributes.contact_number;
      case 'card':
        return cards.length > 0 ? `**** **** **** ${defaultCard.attributes.last4}` : '';
    }
  };

  return (
    <div>
      <div className="flex justify-between w-full">
        <DetailBlock
          id={'player first Name'}
          value={getRespectiveAttribute('firstName')}
          editDetails={editDetails}
        />

        <DetailBlock
          id={'player last Name'}
          value={getRespectiveAttribute('lastName')}
          editDetails={editDetails}
        />
      </div>

      {getRespectiveAttribute('college') && (
        <DetailBlock
          id={'college'}
          value={getRespectiveAttribute('college')}
          editDetails={editDetails}
          exist={false}
          addCard={addCard}
        />
      )}

      <DetailBlock
        id={'high school'}
        value={getRespectiveAttribute('highSchool')}
        editDetails={editDetails}
        exist={false}
        addCard={addCard}
      />

      <div className="flex justify-between w-full">
        <DetailBlock id={'club'} value={getRespectiveAttribute('club')} editDetails={editDetails} />

        <DetailBlock
          id={'High School Grad Year'}
          value={getRespectiveAttribute('class')}
          editDetails={editDetails}
        />
      </div>

      <DetailBlock
        id={'position'}
        value={getRespectiveAttribute('position')}
        editDetails={editDetails}
        exist={false}
        addCard={addCard}
      />

      <DetailBlock
        id={'Current Location'}
        value={getRespectiveAttribute('address')}
        editDetails={editDetails}
      />

      <DetailBlock id={'email'} value={getRespectiveAttribute('email')} editDetails={editDetails} />

      <DetailBlock
        id={'Phone Number'}
        value={getRespectiveAttribute('phoneNumber')}
        editDetails={editDetails}
      />

      <div className="w-full flex justify-between">
        <span className="primary-text-regular normal-case text-lg">Payment method</span>

        <button className="focus:outline-none" type="button" onClick={() => editDetails('card')}>
          <div>
            <FontAwesomeIcon icon={faEdit} size="xs" color="#2E83F5" className="mr-1" />
            <span className="text-blue-500 hover:text-gray-500 text-right text-xs">Edit</span>
          </div>
        </button>
      </div>

      <DetailBlock
        id={'card'}
        value={getRespectiveAttribute('card')}
        editDetails={editDetails}
        exist={cards.length > 0}
        addCard={addCard}
      />
    </div>
  );
};

export default CustomerDetail;
