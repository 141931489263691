import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { formatTime } from '../../../utils/utilities';
import { getFormattedLocalTime } from 'utils/helpers/timeZone';

const TimeSlot = ({
  entries,
  selectedTime,
  setSelectedTime,
  setSelectedSlot,
  trainingType,
  bookAthelete,
  scrollToBottom,
}) => {
  entries = entries ? entries.filter((v, i, a) => a.findIndex(t => t.training_start_date_time === v.training_start_date_time) === i) : []

  return (
    <div>
      <div className="flex flex-col overflow-y-scroll">
        {entries && entries.length > 0 ? (
          entries.map((item, index) => {
            if (item.from_time && item.free_slot > 0)
              return (
                <div key={index} className="flex flex-col" style={{ width: '160px' }}>
                  {trainingType === 'group' && selectedTime === item.from_time && (
                    <p4 className="text-primary-color mt-1 font-sf-regular text-left text-xs capitalize px-2">
                      <strong>Free Slots: </strong> {item.free_slot}
                      {'/'}
                      {item.max_seats}
                    </p4>
                  )}

                  <div className="flex justify-between">
                    <button
                      onClick={() => {
                        scrollToBottom();
                        setSelectedSlot(item);
                        setSelectedTime(item.from_time);
                      }}
                      style={{ borderLeftColor: '#042E60', borderLeftWidth: 3 }}
                      className={cx(
                        ' bg-white flex flex-col w-full p-2 m-1 hover:text-white border-light-gray border-t border-b hover:bg-light-gray focus:outline-none',
                        { 'bg-light-gray text-white px-2': selectedTime === item.from_time }
                      )}>
                      <small className="text-xs text-primary-color font-sf-regular">
                        Available
                      </small>

                      <small className="text-xs text-primary-color font-sf-regular">
                        {getFormattedLocalTime(item.training_start_date_time)} - {getFormattedLocalTime(item.training_end_date_time)}
                      </small>
                    </button>
                  </div>
                </div>
              );
            else if (entries.length === 1)
              return (
                <div key={index} className="flex flex-col" style={{ width: '200px' }}>
                  <p4 className="text-primary-color mt-1 text-center font-sf-bold text-sm capitalize px-2">
                    Sold Out
                  </p4>

                  <p4 className="text-primary-color mt-1 text-center font-sf-regular text-xs px-2">
                    All available timeslots booked
                  </p4>
                </div>
              );
          })
        ) : (
          <div className="flex flex-col" style={{ width: '200px' }}>
            <p4 className="text-primary-color mt-1 text-center font-sf-bold text-xs px-2">
              All available timeslots passed
            </p4>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeSlot;
