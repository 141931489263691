import React from 'react';
import { getAthleteName, getAthleteImageSrc } from '../../../utils/utilities';
import { TrainingBadge } from '../../Shared';
import ButtonLoader from 'react-loader-spinner';
import { getFormattedLocalDate, getFormattedLocalTime } from 'utils/helpers/timeZone';
import ToolTip from "./ToolTip";

const Booking = ({ attributes, history, index, cancelLoader, cancelBooking, selectedCancelId }) => {
  const contactTrainerTooltipText = "Reach out to your trainer to discuss details of session or to cancel the session.";
  const contactTrainerTooltipHeading = "Contact trainer to discuss";

  return (
    <div
      style={{ position: 'relative' }}
      className="bg-white py-2 px-2 w-full flex flex-col md:flex-row my-2 border-b-2">
      <div className="flex flex-col md:flex-row flex-wrap w-full md:w-1/3 items-center md:items-start">
        {getAthleteImageSrc(attributes.athlete.data.attributes)}

        <div className="flex flex-col items-center md:items-start ml-4">
          <span style={{ fontSize: 17 }} className="font-sf-regular capitalize text-black">
            {getAthleteName(attributes.athlete.data.attributes)}
          </span>

          <small className="opacity-75 primary-text-regular">
            {attributes.provided_service.data.attributes.name}
          </small>

          <small className="opacity-75 primary-text-regular">
            {attributes.athlete.data.attributes.contact_number}
          </small>

          <TrainingBadge
            trainingType={attributes.provided_service.data.attributes.training_type}
            small
          />
        </div>

        <div
          style={{
            position: 'absolute',
            top: index == 0 ? -7 : 0,
            right: -12,
          }}
          className="flex flex-col">
          <div
            style={{
              borderTopRightRadius: index === 0 && 7,
              width: '100px',
            }}
            className={`overflow-hidden items-center justify-center flex  ${
              attributes.status === 'pending'
                ? 'bg-blue-500 text-primary-color'
                : attributes.status === 'accepted' || attributes.status === 'completed'
                ? 'bg-sharp-green text-white'
                : 'bg-red-800 text-white'
            } w-full text-xs py-1 font-normal capitalize`}>
            {attributes.status === 'pending' || attributes.status === 'incomplete'
              ? 'Upcoming'
              : attributes.status}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center md:items-start w-full md:w-1/3 border-dotted border-l-2 text-t-gray justify-center pl-5 mb-1 border-gray-700">
        <div>
          <small className="primary-text-regular text-t-gray">
            {attributes.provided_service.data.attributes.training_type !== 'virtual'
              ? `${attributes.location} ${attributes.address}`
              : 'Virtual Training'}
          </small>
        </div>

        <div>
          <small className="text-xs">
            {getFormattedLocalDate(attributes?.training_start_date_time)}
          </small>

          <small className="ml-3 text-xs">
            {getFormattedLocalTime(attributes?.training_start_date_time)}
            {' to '}
            {getFormattedLocalTime(attributes?.training_end_date_time)}
          </small>
        </div>
      </div>

      <div className="flex flex-col w-full md:w-1/3 md:flex-row items-center md:justify-end md:ml-4">
        <button
          onClick={() => {
            history.push(`/bookings/${attributes.id}`);
          }}
          className="primary-button w-full md:w-32 mr-0 md:mr-3">
          Details
        </button>

        {(attributes.status === 'incomplete' && attributes.allowed_cancel) && (
          <button
            disabled={cancelLoader}
            onClick={() => cancelBooking(attributes.id)}
            className="primary-button w-full md:w-32 mr-0 md:mr-3">
            Cancel
            <ButtonLoader
              visible={cancelLoader && selectedCancelId === attributes.id}
              type="ThreeDots"
              color="#042E60"
              className="ml-1"
              height={20}
              width={20}
            />
          </button>
        )}

        {(attributes.status === 'incomplete' && !attributes.allowed_cancel) && (
          <ToolTip tooltipHeading={contactTrainerTooltipHeading} tooltipText={contactTrainerTooltipText}>
            <button
              className="primary-button w-full md:w-32 mr-0 md:mr-3"
              onClick={() => window.open(`mailto:${ attributes.athlete.data.attributes.email }`)}>
              Contact Trainer
            </button>
          </ToolTip>
        )}
      </div>
    </div>
  );
};

export default Booking;
