import React from 'react';
import SignUpForm from 'components/Client/SignUp/SignUpForm';
import LoginForm from 'components/Shared/LoginForm';

const AuthModal = props => {
  return (
    <div
      className={
        props.visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <div className="fixed inset-0 transition-opacity" onClick={() => props.setAuthModal(false)}>
        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>

      <div
        className="rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div>
          {props.loginModal ? (
            <div className="p-5 bg-main-background">
              <LoginForm
                onSuccess={() => props.onSuccess()}
                showSignup={() => props.showLoginModal(false)}
              />
            </div>
          ) : (
            <div>
              <SignUpForm
                guest={props.guest}
                closePopup={() => props.setAuthModal(false)}
                onSuccess={() => props.onSuccess()}
                showLogin={() => props.showLoginModal(true)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
