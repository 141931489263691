import React from 'react';
import { backgroundColor } from '../../utils/utilities';

function TrainingBadge({ trainingType, small }) {
  return (
    <div className={`flex items-center ${!small && 'self-center'}`}>
      <div className={`h-2 w-2 rounded-full ${backgroundColor(trainingType.toLowerCase())}`} />

      <small
        className={`font-sf-regular ml-1  ${
          !small ? 'uppercase text-black' : 'capitalize text-t-gray text-xs'
        }`}>
        {trainingType}
      </small>
    </div>
  );
}

export default TrainingBadge;
