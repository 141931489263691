import React, { useState, useContext, useEffect } from 'react';
import Loader from '../../Shared/Loader';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { TrainingAndLocationSelect } from '../../Shared';
import {
  formatTime,
  getAthleteImageSrc,
  getAthleteName,
  priceFormatter,
} from '../../../utils/utilities';
import { toJS } from 'mobx';
import InfiniteScroll from 'react-infinite-scroller';

const BookNow = ({ bookNow }) => (
  <button
    onClick={bookNow}
    className="rounded-md border bg-highlight-color border-light-gray text-primary-color text-xs py-2 w-full md:w-24 hover:bg-primary-color hover:text-white font-normal my-3 md:my-0">
    Book now
  </button>
);

const AthleteInfo = ({ athlete, history, bookNow, selectedTrainings, fromTime, toTime }) => {
  let includedTrainings = selectedTrainings;
  let trainings =
    (athlete && athlete.attributes && athlete.attributes.provided_services.data) || [];

  const matchedTrainings = id => {
    return trainings.filter(training => parseInt(training.id) === id);
  };

  const getTrainings = () => {
    return includedTrainings.map((id, index) =>
      matchedTrainings(id).map(training => (
        <small className="text-t-gray text-xs font-sf-regular pr-1 capitalize">
          {'  '}
          {training.attributes.name} {index !== trainings.length - 1 && '•'}
        </small>
      ))
    );
  };

  const getDurations = () => {
    return includedTrainings.map(id =>
      matchedTrainings(id).map(training => (
        <div className="flex flex-wrap items-center justify-center">
          <div className="flex items-center">
            <small style={{ fontSize: 11 }} className="text-t-gray font-sf-regular pr-2">
              {training.attributes.duration} min {'•'}
            </small>
          </div>
        </div>
      ))
    );
  };

  const getPrices = () => {
    let prices = [];

    includedTrainings.forEach(id =>
      matchedTrainings(id).forEach(({ attributes: { price } }) => {
        prices = [...prices, priceFormatter(price)];
      })
    );

    return (
      <small style={{ fontSize: 11 }} className="text-t-gray text-xs font-sf-regular pr-2">
        {prices.join(' • ')}
      </small>
    );
  };

  const getLocations = () => {
    return includedTrainings.map(id =>
      matchedTrainings(id).map(training => {
        if (training.attributes.training_type !== 'virtual') {
          return (
            <div className="flex flex-wrap items-center">
              {training.attributes.provided_service_locations.data.map(
                (location, locationIndex) => (
                  <small className="text-xs opacity-75 text-t-gray font-sf-regular capitalize pr-2">
                    {' '}
                    {location.attributes.location}
                    {locationIndex !==
                      training.attributes.provided_service_locations.data.length - 1 && ' •'}
                  </small>
                )
              )}
            </div>
          );
        } else {
          return (
            <div className="flex flex-wrap items-center">
              <small className="text-xs opacity-75 text-t-gray font-sf-regular pr-2">
                {training?.attributes?.connection_detail}
              </small>
            </div>
          );
        }
      })
    );
  };

  return (
    <div className="w-full pr-5 pl-5">
      <div className="p-2 border-b border-light-gray mr-1 flex flex-col items-center md:flex-row justify-between">
        <div className="flex flex-wrap items-center w-full justify-center md:justify-start">
          <div className="flex flex-col w-full md:w-10 justify-center items-center md:mr-5 mb-2 md:mb-0">
            <span className="text-xs text-t-gray">{athlete.attributes.sport_name}</span>

            <div className="flex flex-col font-sf-regular" style={{ fontSize: 11 }}>
              <div className="flex items-center">
                <div
                  className="h-2 w-2 rounded-full mr-1"
                  style={{
                    backgroundColor:
                      athlete.attributes.training_type === 'individual'
                        ? '#408BF9'
                        : athlete.attributes.training_type === 'group'
                        ? '#FD8924'
                        : '#149617',
                  }}></div>

                <span className="text-black capitalize">{athlete.attributes.training_type}</span>
              </div>
            </div>
          </div>

          <div className="flex md:w-3/4 flex-col md:flex-row md:border-dots content-start md:items-center pl-0 md:pl-5 justify-between">
            <div
              style={{ minWidth: '300px' }}
              className="flex w-full flex-col md:flex-row md:w-1/2">
              <div className="flex w-full justify-center md:justify-start md:w-32 mb-3 md:mb-0">
                {getAthleteImageSrc(athlete.attributes)}
              </div>

              <div className="flex flex-wrap flex-col pl-0 md:pl-5 w-full md:w-8/12 md:w-full">
                <div className="flex md:justify-between justify-center">
                  <span
                    style={{ fontSize: 15 }}
                    className="font-sf-regular capitalize text-primary-color text-left">
                    {getAthleteName(athlete.attributes)}
                  </span>
                </div>

                <div className="w-full flex md:items-center flex-row md:justify-start justify-center">
                  {athlete.attributes.position.map(position => (
                    <small
                      style={{ fontSize: 11 }}
                      className="text-primary-color text-xs font-sf-regular">
                      {`${position} •`}
                    </small>
                  ))}

                  <small
                    style={{ fontSize: 11 }}
                    className="text-primary-color text-xs font-sf-regular">
                    {athlete.attributes.passing_year}
                  </small>

                  {(athlete.attributes.jersey_number || athlete.attributes.jersey_number === 0) && (
                    <small
                      style={{ fontSize: 11 }}
                      className="text-primary-color text-xs font-sf-regular">
                      {' • '} {`#${athlete.attributes.jersey_number.toString()}`}
                    </small>
                  )}
                </div>

                <div className="w-full flex flex-col md:flex-row">
                  {includedTrainings && (
                    <div className="flex flex-wrap md:flex-row md:justify-start justify-center">
                      {getTrainings()}
                    </div>
                  )}
                </div>

                <span className="text-xs text-primary-color flex md:justify-start justify-center">
                  {formatTime(fromTime)} {' - '}
                  {formatTime(toTime)}
                </span>

                <div className="md:hidden block">
                  <div className="flex flex-wrap md:justify-start justify-center">
                    {includedTrainings && getDurations()}
                    {includedTrainings && getPrices()}
                  </div>

                  {athlete.attributes.training_type !== 'virtual' && (
                    <div className="flex flex-wrap md:justify-start justify-center">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        style={{ color: 'gray' }}
                        size="sm"
                        className="mr-1"
                      />
                      {includedTrainings && getLocations()}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-3 md:mt-0 flex-col border-dots pl-5 md:flex hidden md:ml-2 ml-0">
              <div className="flex flex-wrap md:justify-start justify-center">
                {includedTrainings && getDurations()}
                {includedTrainings && getPrices()}
              </div>

              <div>
                {athlete.attributes.training_type !== 'virtual' && (
                  <div className="flex flex-wrap">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{ color: 'gray' }}
                      size="sm"
                      className="mr-1"
                    />
                    {includedTrainings && getLocations()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full md:w-auto justify-center md:items-center">
          <BookNow bookNow={bookNow} />
        </div>
      </div>
    </div>
  );
};

function FullSchedule(props) {
  const store = useContext(StoreContext);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [availData, setAvailData] = useState(null);
  const [selectionModal, setSelectionModal] = useState(false);
  const [selectedTrainings, setSelectedTrainings] = useState(null);
  const [nextSchedulesPage, setNextSchedulesPage] = useState(null);
  const [reachedToEnd, setReachedToEnd] = useState(false);

  let athlete = toJS(store.atheleteStore.athelete);
  let {
    match: { params },
  } = props;

  useEffect(() => {
    store.atheleteStore.getCollegeSchedule(params.school_slug).then(schedules => {
      setAvailData(schedules.data);
      schedules?.pagy?.next
        ? setNextSchedulesPage(schedules.pagy.next)
        : setNextSchedulesPage(null);
      setLoader(false);
    });
  }, []);

  const bookNow = (slug, selectedTrainings, providedServices) => {
    let selected = {
      data: selectedTrainings.map(
        id => providedServices.data.filter(training => parseInt(training.id) === id)[0]
      ),
    };

    setSelectedTrainings(selected);

    if (selected.data.length === 0)
      history.push({
        pathname: `/${slug}`,
      });
    else if (
      selected.data &&
      selected.data.length === 1 &&
      selected.data[0].attributes.provided_service_locations.data.length === 1
    ) {
      if (selected.data[0].attributes.training_type !== 'virtual')
        store.userStore.setLocationAddress(
          selected.data[0].attributes.provided_service_locations.data[0].attributes.location.trim(),
          selected.data[0].attributes.provided_service_locations.data[0].attributes.address.trim(),
          selected.data[0].id
        );

      moveToTrainerProfile(slug, selected.data[0].attributes.slug.split('-')[1]);
    } else {
      setSelectionModal(slug);
    }
  };

  const moveToTrainerProfile = (athleteSlug, trainingSlug) => {
    history.push({
      pathname: `/${athleteSlug}/${trainingSlug}`,
    });
  };

  const loadMoreSchedule = () => {
    if (nextSchedulesPage) {
      let lastDate = Object.keys(availData[availData.length - 1])[0];
      store.atheleteStore
        .getCollegeSchedule(params.school_slug, nextSchedulesPage, lastDate)
        .then(schedules => {
          schedules.data && setAvailData([...availData, ...schedules.data]);
          schedules?.pagy?.next
            ? setNextSchedulesPage(schedules.pagy.next)
            : setNextSchedulesPage(null);
        })
        .catch(err => {
          setReachedToEnd(true);
        });
    }
  };

  if (loader) return <h1>Loading...</h1>;

  return (
    <div className="flex flex-col content-center items-center p-4 pt-24 md:pt-20 bg-main-background min-h-screen">
      {!loader && availData ? (
        <div className="w-full flex flex-col items-center content-center justify-center my-3">
          {availData && availData.length > 0 ? (
            <InfiniteScroll
              pageStart={1}
              loadMore={loadMoreSchedule}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              hasMore={nextSchedulesPage && !reachedToEnd}
              className="flex flex-col w-full md:w-3/4 items-center pb-2 justify-between">
              {availData.map(entry => {
                return Object.entries(entry).map(([date, calenderEntries]) => {
                  return (
                    <div className="flex flex-col w-full">
                      <span className="font-sf-regular text-xs my-3 text-primary-color">
                        {moment(date).format('dddd, MMMM D, YYYY')}
                      </span>
                      <div
                        style={{ borderRadius: 25 }}
                        className="flex flex-col w-full bg-white shadow-md flex-wrap my-2">
                        {calenderEntries.data.map(
                          ({ attributes: { athlete, selected_trainings, from_time, to_time } }) => {
                            return (
                              <div className="flex w-full">
                                <AthleteInfo
                                  athlete={athlete?.data}
                                  fromTime={from_time}
                                  toTime={to_time}
                                  selectedTrainings={selected_trainings}
                                  history={history}
                                  bookNow={() =>
                                    bookNow(
                                      athlete.data.attributes.slug,
                                      selected_trainings,
                                      athlete.data.attributes.provided_services
                                    )
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                });
              })}
            </InfiniteScroll>
          ) : (
            <span className="font-sf-regular text-sm my-3 text-primary-color">
              No Availabilities yet.
            </span>
          )}
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {selectionModal && selectedTrainings && (
        <TrainingAndLocationSelect
          visible={selectionModal}
          slug={selectionModal}
          trainings={selectedTrainings}
          closeModal={() => {
            setSelectionModal(null);
            setSelectedTrainings(null);
          }}
        />
      )}
    </div>
  );
}

export default observer(FullSchedule);
