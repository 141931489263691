import React from 'react';
import { aboutUsHeading, aboutUsWelcome, aboutUsDescription } from '../../../stringConstants';

function AboutUs(props) {
  return (
    <div className="flex overflow-scroll bg-main-background min-h-screen">
      <div className="divide-y min-h-screen overflow-scroll flex flex-col">
        <div className="flex flex-col items-center justify-center h-40 md:h-64 mt-40 md:mt-16">
          <p
            style={{ fontSize: '30px' }}
            className="text-primary-color text-bold text-2xl font-bold">
            {aboutUsHeading}
          </p>
        </div>

        <div className="bg-secondary-color h-full border-none px-12">
          <p className="pt-12 text-primary-color text-bold text-xl font-bold my-4">Welcome</p>

          <p className="text-primary-color text-sm SF-regular text-left">{aboutUsWelcome}</p>

          <p className="text-primary-color text-bold text-xl font-bold my-4">About</p>

          <p className="pb-6 text-primary-color text-sm SF-regular text-left">
            {aboutUsDescription}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
