import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputMask from 'react-input-mask';
import { notify, isValidContactNumber, isValidEmail } from '../../utils/utilities';
import { InputLabel } from '../Shared';

function NotifyEmail({ show, onConfirm, disableNotify, athlete, serviceName }) {
  const [email, setEmail] = useState(null);
  const [contactNumber, setContactNumber] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    setEmail(null);
    setContactNumber('');
    setName('');
  }, []);

  const validate = () => {
    if (!email) return;
    if (!isValidEmail(email)) notify('Please enter valid email address.');
    else if (name === '') notify('Please enter your full name.');
    else if (contactNumber !== '' && !isValidContactNumber(contactNumber))
      notify('Sorry! Can not proceed with the provided contact number.');
    else onConfirm(email, contactNumber, name);
  };

  return (
    <SweetAlert show={show} showConfirm={false} onCancel={disableNotify}>
      <div className="px-4">
        <h5 className="text-primary-color font-sf-medium">New Training Notifications</h5>

        <small className="text-xs text-t-gray font-sf-regular">
          If you would like to receive email and/or text notifications for when {athlete.first_name}{' '}
          {athlete.last_name} adds new openings for {serviceName}, enter your details below
        </small>

        <div className="justify-start items-start flex flex-col">
          <InputLabel label="Email" />
          <input
            className="text-input"
            id="email"
            name="email"
            type="email"
            onChange={e => setEmail(e.target.value)}
            placeholder="johndoe@treyvo.com"
            required
            value={email}
          />
        </div>

        <div className="justify-start items-start flex flex-col mt-3">
          <InputLabel label="Full Name" />
          <input
            className="text-input focus:border-primary-color"
            id="name"
            name="name"
            onChange={e => setName(e.target.value)}
            placeholder="John Doe"
            required
            value={name}
          />
        </div>

        <div className="justify-start items-start flex flex-col mt-3">
          <InputLabel label="Phone Number" />
          <InputMask
            name="contact"
            placeholder="(555) 555-5555 *Optional"
            style={{ outline: 0, fontFamily: 'SF-light' }}
            className="font-sf-regular form-control rounded-md my-1 py-3 md:py-4 appearance-none text-xs border border-light-gray w-full px-3 text-primary-color leading-tight"
            mask="+1 (999) 999-9999"
            maskChar=" "
            value={contactNumber}
            onChange={e => setContactNumber(e.target.value)}
          />
        </div>

        <div className="flex justify-end my-8">
          <button
            onClick={validate}
            className="rounded-md border border-light-gray text-xs py-3 w-full md:w-32 bg-primary-color hover:text-gray-400 text-white font-normal my-3 md:my-0">
            Confirm
          </button>
        </div>
      </div>
    </SweetAlert>
  );
}

export default observer(NotifyEmail);
