import React from 'react';
import { Link } from 'react-router-dom';
import { CaliforniaResidentsNoticeTableData } from '../../constants';
import { NormalText, Heading } from './Typography';

const CaliforniaResidentsNotice = () => {
  return (
    <div className="min-h-screen bg-main-background pt-3 text-black pt-20 terms-and-privacy-container">
      <div className="container mx-auto mb-3 md:mb-5">
        <h2 className="primary-text-regular text-center text-black md:text-4xl text-xl">
          SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS
        </h2>
        <hr />

        <div className="md:mx-5 mx-4 mt-5">
          <p className="text-lg mb-1">
            <b>Last Updated:</b> April 21, 2021
          </p>

          <p className="text-lg">
            <b>Last Reviewed:</b> April 21, 2021
          </p>

          <NormalText>
            This Supplemental Privacy Notice for California Residents (this “Supplemental Notice”)
            supplements Trayvo’s Privacy Policy found{' '}
            <a href="/privacy-policy" className="text-primary">
              here
            </a>{' '}
            and applies to you if you reside in California. This Supplemental Notice is adopted to
            comply with the California Consumer Privacy Act of 2018 (the “CCPA”). It also addresses
            requirements of certain other California laws. Capitalized terms used without definition
            shall have the meanings ascribed to them in the Privacy Policy and other undefined terms
            shall have the meanings ascribed to them in the CCPA.
          </NormalText>

          <Heading className="inline">1. Information We Collect.</Heading>

          <NormalText classes="inline ml-1">
            The Site collects information that identifies, relates to, describes, references, is
            capable of being associated with, or could reasonably be linked to a consumer or
            personal device. For purposes of this Supplemental Notice, such information constitutes
            personal information.{' '}
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(a)</b> <u>Personal Information Collected</u>. Below are categories
            of personal information recognized by the CCPA along with examples (provided by the
            CCPA) of types of personal information in each category:
          </NormalText>

          <ul className="list-disc list-inside">
            <li className="text-lg text-gray-700 ml-4">
              Identifiers. Real name, alias, postal address, unique personal identifier, online
              identifier, Internet Protocol address, email address, account name, Social Security
              number, driver's license number, passport number, or other similar identifiers
            </li>

            <li className="text-lg text-gray-700 ml-4">
              CA Customer Records Statute categories. Name, signature, Social Security number,
              physical characteristics or description, address, telephone number, passport number,
              driver's license or state identification card number, insurance policy number,
              education, employment, employment history, bank account number, credit card number,
              debit card number, or any other financial information, medical information, or health
              insurance information
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Protected Class Characteristics. Age (40 years or older), race, color, ancestry,
              national origin, citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, sex (including gender, gender identity, gender
              expression, pregnancy or childbirth and related medical conditions), sexual
              orientation, veteran or military status, genetic information (including familial
              genetic information)
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Commercial Information. Records of personal property, products or services purchased,
              obtained, or considered, or other purchasing or consuming histories or tendencies
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Biometric Information. Genetic, physiological, behavioral, and biological
              characteristics, or activity patterns used to extract a template or other identifier
              or identifying information, such as, fingerprints, faceprints, and voiceprints, iris
              or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Internet/network activity. Browsing history, search history, information on a
              consumer's interaction with a website, application, or advertisement
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Geolocation data. Physical location or movements
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Sensory data. Audio, electronic, visual, thermal, olfactory, or similar information
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Professional/employment-related information. Current or past job history or
              performance evaluations
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Non-public education information. Education records directly related to a student
              maintained by an educational institution or party acting on its behalf, such as
              grades, transcripts, class lists, student schedules, student identification codes,
              student financial information, or student disciplinary records
            </li>

            <li className="text-lg text-gray-700 ml-4">
              Inferences drawn from other personal information. Profile reflecting a person's
              preferences, characteristics, psychological trends, predispositions, behavior,
              attitudes, intelligence, abilities, and aptitudes
            </li>
          </ul>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(b)</b> <u>Exclusions</u>. Personal information does not include
            publicly available information from government records, deidentified or aggregated
            consumer information, and information excluded from the CCPA’s scope (such has
            HIPAA–protected information and information protected by the Gramm-Leach Bliley Act).
            Personal information collected as part of a communication or transaction between Trayvo
            and a representative of a government agency or other business, where the transaction is
            made for providing or receiving a good or service to or from such government agency or
            other business or conducting due diligence in connection with the foregoing is also
            excluded from the scope of this Supplemental Notice except with respect to{' '}
            <i>Section 4(c) (non-discrimination)</i>.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(c)</b> <u>Sources</u>. We obtain Personal Information from you,
            including from you directly when you provide Trayvo with the information, from you
            indirectly when you interact with the Site, and from you when you make electronic
            communications to Trayvo.
          </NormalText>

          <Heading className="inline">2. Use of Personal Information</Heading>

          <NormalText classes="inline ml-1">
            Trayvo uses the personal information it collects in the ways described in
            <a href="/privacy-policy/#section-5" className="text-primary ml-1">
              Section 5
            </a>{' '}
            of the Privacy Policy. Trayvo may use the personal information for commercial purposes
            as well as business purposes.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">3. Disclosure of Personal Information.</Heading>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(a)</b> <u>Disclosure</u>. Trayvo uses the personal information it
            collects in the ways described in
            <a href="/privacy-policy/#section-6" className="text-primary mx-1">
              Section 6
            </a>
            of the Privacy Policy. Trayvo has shared or disclosed Personal Information within the
            past twelve months as described below. The following chart identifies the categories of
            personal information recognized by the CCPA and collected by Trayvo, provides examples
            of the types of personal information within each category collected by Trayvo, indicates
            whether Trayvo has collected such categories of personal information during the past
            twelve months, the source of such personal information, how Trayvo uses such personal
            information, and to whom Trayvo has disclosed such personal information within the past
            twelve months:
          </NormalText>

          <div className="mt-3 overflow-x-scroll w-full">
            <div className="table">
              <div className="table-row-group">
                <div className="table-row">
                  <div className="table-cell text-lg border p-4 font-bold bg-secondary">
                    Category of Personal Information
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    Source
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    Business Purpose
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    To Whom Disclosed (categories)
                  </div>
                </div>

                {CaliforniaResidentsNoticeTableData.map(
                  ({ statement, sources, businessPurposes, toWhomDisclosedList }) => (
                    <div className="table-row" key={statement}>
                      <div className="table-cell text-lg border p-3 w-1/4">{statement}</div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {sources.map(source => (
                            <li key={source}>{source}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {businessPurposes.map(businessPurpose => (
                            <li key={businessPurpose}>{businessPurpose}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {toWhomDisclosedList.map(toWhomDisclosed => (
                            <li key={toWhomDisclosed}>{toWhomDisclosed}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(b)</b> <u>Sale</u>. Other than as disclosed herein, Trayvo does not
            sell personal information, including of minors under 18 years of age.
          </NormalText>

          <Heading className="inline">4. California Rights.</Heading>

          <NormalText classes="inline ml-1">
            The CCPA provides you with specific rights as a California resident. This section
            identifies those rights and how to exercise them.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(a)</b> <u>Access and Data Portability</u>. Subject to certain
            exceptions, you have the right to request certain information about Trayvo’s collection,
            use, and/or disclosure of your personal information over the past 12 months. After
            receipt of a verifiable consumer request from you, Trayvo will disclose to you the
            categories of personal information collected, categories of sources for the categories
            of personal information collected, business or commercial purpose for collecting such
            personal information, categories of third parties with whom Trayvo shared such personal
            information, and specific pieces of personal information collected about you.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(b)</b> <u>Deletion</u>. Subject to certain exceptions, you may
            request that Trayvo delete Personal Information about you that it has collected and
            retained.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(c)</b> <u>Non-Discrimination</u>. You have the right not to receive
            discriminatory treatment for the exercise of your privacy rights under the CCPA.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(d)</b> <u>Opt-Out.</u>. You have the right not to have your
            information disclosed, shared, or sold. If you are a California resident and wish to
            exercise this right, you may contact us by email at
            <span className="text-primary mx-1">support@trayvo.com</span> or by phone at
            <span className="text-primary mx-1">+1 (860) 378-5653</span>.
          </NormalText>

          <Heading>5. California Request Process.</Heading>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(a)</b> <u>Instructions</u>. If you reside in California, only you
            or someone legally authorized to act on your behalf may make a verifiable consumer
            request related to your personal information. You may also make a verifiable consumer
            request on behalf of your minor child. You may make a verifiable consumer request for
            access or data portability no more than twice within a 12-month period. Such a request
            must provide sufficient information to allow Trayvo to reasonably verify that you are
            (or are an authorized representative of) the person about whom Trayvo collected the
            personal information, and describe your request with sufficient detail to allow Trayvo
            to properly understand, evaluate, and respond to it. Please note that Trayvo cannot
            respond to your request or provide you with information if Trayvo cannot verify your
            identity or authority to make the request and confirm the personal information relates
            to you. Making a verifiable consumer request does not require you to create an account
            with Trayvo. Trayvo will only use personal information provided in a verifiable consumer
            request to verify your identity or authority to make the request. If you reside in
            California, you may exercise your personal information rights by submitting verifiable
            consumer requests in one or more of the following manners:
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(i)</b> If you are a California resident, you may make verifiable
            requests to exercise your personal information rights via emailing support@trayvo.com.
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(ii)</b> If you prefer to submit your verifiable request to exercise
            your personal information rights by phone, you may contact Trayvo by phone toll free at
            +1 (860) 378-5653 or by submitting a request using any of the contact information set
            forth in Section 18 below.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(b)</b> <u>Verifying Requests</u>. Trayvo will confirm receipt of
            your request within the time period prescribed by the CCPA and/or its regulations, let
            you know how Trayvo will process the request, and let you know Trayvo’s verification
            process and anticipated timeframe for response. Trayvo may try to verify your request by
            asking you to provide certain information and matching it to the information Trayvo
            maintains about you. Trayvo will request at least two data points about you. Depending
            on the nature of your request, Trayvo may require more data points to match and/or may
            require you to sign a declaration confirming under penalty of perjury that you are the
            consumer whose personal information is the subject of the request.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(c)</b> <u>Authorized Agent</u>. If you are a California resident,
            you may designate an authorized agent to make requests on your behalf to exercise your
            personal information rights. The authorized agent must be registered with California’s
            Secretary of State. The authorized agent making any request on your behalf to know or
            delete certain personal information about you must submit a copy of the written
            permission you granted them to act as authorized agent or a copy of the document showing
            you granted them a power of attorney for this purpose. If the authorized agent does not
            have a power of attorney in compliance with California’s probate law, you must also
            directly verify your identity with Trayvo. If adequate proof is not presented, Trayvo
            may deny the request.
          </NormalText>

          <NormalText classes="ml-4 mt-3">
            <b className="mr-2">(d)</b> <u>Response Timing and Format</u>. Trayvo will try to
            respond to a verified consumer request within forty five (45) days of receipt, but if
            Trayvo requires more time to respond, it will let you know how long and why in writing.
            Trayvo will respond to you by mail or electronically at your option. Any disclosures
            Trayvo provides in response to your request will pertain only to the 12-month period
            immediately preceding receipt of the verifiable consumer request. If Trayvo is unable to
            respond to the request, Trayvo will explain why, if applicable. If Trayvo is unable to
            verify your identity in connection with a request for specific pieces of information
            about you, Trayvo will let you know (but will not disclose the specific pieces of
            information) and will evaluate the request as though it were a request for categories of
            personal information disclosed to third parties during the past 12 months. If Trayvo
            denies a request due to conflict with federal or state law or an exception to the CCPA,
            Trayvo will explain the basis for the denial. If Trayvo cannot verify your identity in
            connection with a request to disclose categories of information disclosed to third
            parties, Trayvo will let you know, and will provide you information about its general
            business practices regarding collection, maintenance, and sale of personal information.
            If Trayvo cannot verify the identity of an individual requesting deletion of personal
            data, Trayvo will deny your request and will treat the request as a request to opt-out
            of sale. Trayvo has no obligation to provide you with specific personal information if
            such disclosure creates a substantial, articulable, and unreasonable risk to security.
            For any data portability requests, Trayvo will provide your personal information in the
            following format: by pdf. Unless your request is excessive, repetitive, or manifestly
            unfounded, Trayvo will not charge a fee to process or respond to your request. If Trayvo
            determines that a fee is warranted, Trayvo will explain why it reached that decision and
            provide you with a cost estimate before responding.
          </NormalText>

          <Heading className="inline">6. Changes to Privacy Policy.</Heading>

          <NormalText classes="inline ml-1">
            Changes to the Supplemental Notice will be posted on this page. If Trayvo makes material
            changes to the way it treats users personal information, it may choose to notify you by
            email or through a notice in addition to posting. The date the Supplemental Notice was
            last revised appears at the top of the Supplemental Notice. You are responsible for
            ensuring that Trayvo has a current email address for you and for periodically visiting
            the Site and the Supplemental Notice to check for any changes.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">7. Third Party Marketing.</Heading>

          <NormalText classes="inline ml-1">
            California Civil Code Section 1798.83 permits users of the Site who are California
            residents to request certain information regarding our disclosure of Personal
            Information to third parties for their direct marketing purposes. To make such a
            request, please contact Trayvo at the contact information set forth in Section 8 below.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">8. Contact.</Heading>

          <NormalText classes="inline ml-1">
            If you have any questions or comments about this Supplemental Notice and Trayvo’s
            privacy practices, or if you wish to exercise your rights as a California resident,
            please contact Trayvo at: Trayvo Holdings, LLC., Ph: 844-487-2986 , email:
            support@trayvo.com.
          </NormalText>
        </div>
      </div>
    </div>
  );
};

export default CaliforniaResidentsNotice;
