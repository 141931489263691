import React from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import moment from 'moment';
import SelectDatepicker from 'react-select-datepicker';
import { InputLabel } from '../../Shared';
import { passingYear, removeSeparator } from '../../../constants';
import PrivacyText from 'components/Shared/PrivacyText';

function AccountInfo({
  provider,
  onChange,
  invalidPhone,
  guest,
  dispatch,
  validatePhone,
  customStyles,
  privacyChecked,
  changePrivacyChecked
}) {
  return (
    <div>
      <div className="container-wrap">
        <div className="container-half pr-0 md:pr-4">
          <InputLabel label="Player First Name" />
          <div>
            <input
              className="text-input form-control"
              name="firstName"
              value={provider.firstName}
              placeholder="First Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>

        <div className="container-half mt-3 md:mt-0">
          <InputLabel label="Player Last Name" />
          <div>
            <input
              className="text-input form-control"
              name="lastName"
              value={provider.lastName}
              placeholder="Last Name"
              onChange={e => onChange(e)}
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <InputLabel label="Email" />
        <div>
          <input
            className="text-input form-control"
            name="email"
            type="email"
            value={provider.email}
            onChange={e => onChange(e)}
            placeholder="Email"
            required
          />
        </div>
      </div>

      <div className="container-wrap mt-3">
        <div className="container-half pr-0 md:pr-4 ">
          <InputLabel label="Gender" />
          <Select
            name="gender"
            styles={customStyles}
            maxMenuHeight={180}
            className="bg-white text-xs mt-1 rounded-md focus:outline-none"
            isSearchable={false}
            components={removeSeparator}
            value={provider.gender}
            placeholder="Select gender"
            onChange={selectedOption => dispatch({ field: 'gender', value: selectedOption })}
            options={[
              { label: 'Female', value: 2 },
              { label: 'Male', value: 1 },
            ]}
            required
          />
        </div>

        <div className="container-half">
          <InputLabel label="Phone Number" />
          <InputMask
            placeholder="(555) 555-5555"
            className="text-input mt-1 form-control"
            mask="+1 (999) 999-9999"
            maskChar=" "
            // value={provider.contact}
            onChange={e => validatePhone(e.target.value)}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <span
          className={`${invalidPhone ? 'text-red-700 primary-text-regular self-end' : 'hidden'}`}>
          Invalid phone number
        </span>
      </div>

      <div className="mt-3">
        <InputLabel label="HS Grad Year" />
        <Select
          name="class"
          styles={customStyles}
          maxMenuHeight={180}
          className="primary-text-regular bg-main-background rounded-md"
          isSearchable={false}
          components={removeSeparator}
          value={provider.passing_year ? provider.passing_year : passingYear[51]}
          placeholder="Select HS Grad Year"
          onChange={selectedOption => dispatch({ field: 'passing_year', value: selectedOption })}
          options={passingYear}
          required
        />
      </div>

      <div className="flex mt-3 items-start w-full justify-start flex-col pt-2">
        <InputLabel label="Birthdate" />
        <div className="w-full">
          <SelectDatepicker
            className="pt-1 text-sm"
            format="month/day/year"
            onDateChange={date => {
              dispatch({ field: 'dob', value: date });
            }}
            required
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(moment().subtract(6, 'years').calendar())}
            showLabels={false}
            value={provider.dob}
          />
        </div>
      </div>

      {(!guest || provider.createAccount) && (
        <div className="mt-3">
          <InputLabel label="Password" />
          <input
            className="font-sf-regular form-control appearance-none text-xs border border-light-gray rounded-md w-full py-3 px-3 text-primary-color leading-tight focus:outline-none"
            name="password"
            type="password"
            value={provider.password}
            placeholder="Password"
            onChange={e => onChange(e)}
            required
          />
        </div>
      )}

      {(!guest || provider.createAccount) && (
        <div className="mb-6 mt-3">
          <InputLabel label="Confirm Password" />
          <input
            className="font-sf-regular form-control appearance-none text-xs border border-light-gray rounded-md w-full py-3 px-3 text-primary-color leading-tight focus:outline-none"
            name="passwordConfirm"
            type="password"
            value={provider.passwordConfirm}
            placeholder="Password Confirmation"
            onChange={e => onChange(e)}
            required
          />
        </div>
      )}

      {guest && (
        <label className="inline-flex items-center py-1 mt-3">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4"
            checked={provider.createAccount}
            onClick={() => dispatch({ field: 'createAccount', value: !provider.createAccount })}
          />

          <span className="ml-2 text-primary-color text-sm">Create a trayvo Account</span>
        </label>
      )}

      <label class="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox text-primary-color h-6 w-6"
          checked={privacyChecked}
          onClick={() => changePrivacyChecked(!privacyChecked)}
        />
        <PrivacyText className="text-left mx-3" />
      </label>
    </div>
  );
}

export default AccountInfo;
