import React from 'react';

function ModalBackground() {
  return (
    <div className="fixed inset-0 transition-opacity pin">
      <div style={{ opacity: 0.2 }} className="modal-overlay absolute w-full h-full bg-black"></div>
    </div>
  );
}

export default ModalBackground;
