import React, { useState, useEffect, useContext, useLayoutEffect, useMemo } from 'react';
import { StoreContext } from 'index';
import { TrayvoBlue, TrayvoTrainingLogo } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/Shared/Loader';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { priceFormatter } from '../../../utils/utilities';
import { NotFound } from '../NotFound';
import SelectLocationModal from './SelectLocationModal';
import AuthModal from '../Checkout/AuthModal';
import { NotifyEmail, NotificationsEnabled } from '../../Popup';
import { TrainingBadge, TrainerBanner } from '../../Shared';

function TrainerProfile(props) {
  const [loader, setLoader] = useState(false);
  const [focusedService, setFocusedService] = useState(null);
  const [showSelectModal, setSelectModal] = useState(null);
  const [selectedLocation, setLocation] = useState(null);
  const [selectedAddress, setAddress] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showAuthModal, setAuthModal] = useState(false);
  const [loginModal, showLoginModal] = useState(true);
  const [guest, setGuest] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceName, setSelectedServiceName] = useState('');
  const [athelete, setAthlete] = useState(null)
  const [services, setServices] = useState([])

  const history = useHistory();
  const store = useContext(StoreContext);

  let user = toJS(store.userStore.currentUser);

  let {
    match: { params },
  } = props;

  useEffect(() => {
    store.userStore.hideHeader(true);
    store.userStore.resetLocationAddress();
    store.atheleteStore.getAthelete(params.athleteSlug).then(res => {
      setLoader(false);
      let athlete = toJS(store.atheleteStore.athelete)

      if (athlete) {
        let fetchedServices =
          (athlete &&
            athlete.provided_services.data.length > 0 &&
            athlete.provided_services.data.filter(
              item => item.attributes.privacy_setting === 'public'
            )) ||
          [];

        setServices(fetchedServices)
        setAthlete(athlete)
      }
    });


    return () => {
      store.userStore.hideHeader(null);
    };
  }, []);

  useLayoutEffect(() => {
    setLoader(true);
  }, []);

  const servicePressed = service => {
    setFocusedService(service);

    if (
      service.attributes.training_type !== 'virtual' &&
      !selectedLocation &&
      service.attributes.provided_service_locations.data.length > 1
    ) {
      setSelectModal(true);
    } else {
      if (service.attributes.provided_service_locations.data.length === 1)
        store.userStore.setLocationAddress(
          service.attributes.provided_service_locations.data[0].attributes.location,
          service.attributes.provided_service_locations.data[0].attributes.address,
          service.id
        );
      else store.userStore.setLocationAddress(selectedLocation, selectedAddress, service.id);
      history.push({
        pathname: `/${athelete.slug}/${service.attributes.slug.split('-')[1]}`,
      });
    }
  };

  useMemo(() => {
    if (focusedService) servicePressed(focusedService);
  }, [selectedLocation]);

  const notifyMe = async (id, name) => {
    setSelectedServiceName(name);
    setSelectedService(id);
    if (user && user.data && user.data.id) {
      sendNotificationCredentials(
        id,
        user.data.attributes.email,
        user.data.attributes.contact_number,
        user.data.attributes.first_name + ' ' + user.data.attributes.last_name
      );
    } else setNotifyEmail(true);
  };

  const sendNotificationCredentials = (providedServiceId, email, contactNumber, name) => {
    setNotifyEmail(false);
    let payload = {
      subscribed_provided_service: {
        contact_number: contactNumber,
        email: email,
        provided_service_id: providedServiceId,
        name,
      },
    };
    store.userStore.enableNotifications(payload).then(response => {
      if (response.status === 201) setSuccess(true);
    });
  };

  const checkAvailabilityAssociated = (id, status) => {
    const included_trainings = (athelete && athelete.included_trainings) || [];
    return included_trainings.includes(parseInt(id)) && status === 'published';
  };

  const viewFullSchedule = () => {
    history.push(`/profile/schedule/${athelete.id}`);
  };

  return (
    <div className="bg-main-background min-h-screen">
      {!loader && athelete ? (
        <div className="flex flex-col content-center items-center mx-auto p-4 pt-16 md:pt-12 md:w-2/3">
          <div className="flex flex-col px-0">
            <TrainerBanner athlete={athelete} padding />

            <div className="flex rounded-md pt-3">
              <div className="w-full flex my-1 flex-wrap mx-1 flex-row non-gird-small-view md:grid md:grid-cols-3 md:gap-1">
                {services.length > 0 ? (
                  services.map(
                    ({
                      id,
                      attributes: {
                        privacy_setting,
                        name,
                        image_url,
                        duration,
                        provided_service_locations,
                        training_type,
                        price,
                        discription,
                        all_slots_booked,
                        slug,
                        status,
                        connection_detail,
                      },
                    }) => {
                      return (
                        <div
                          className="flex flex-col items-center py-3 px-1 w-full mt-2 md:mt-0 shadow-md bg-white rounded-lg relative min-card-width mx-3"
                          key={id}>
                          <div className="flex mb-3 items-center justify-center w-full">
                            <div className="bg-white self-center p-1 rounded-full items-center">
                              <img
                                style={{ borderWidth: 4 }}
                                src={image_url ? image_url : TrayvoTrainingLogo}
                                className="object-cover shadow-md h-24 w-24 rounded-full border-white"
                              />
                            </div>
                          </div>

                          <div className="flex flex-col items-center mx-3 training-card-height">
                            <div className="flex items-center leading-tight">
                              {privacy_setting == 'private' && (
                                <FontAwesomeIcon
                                  icon={faLock}
                                  size="lg"
                                  color="gray"
                                  className="mr-1 p-1"
                                />
                              )}
                              <span className="regular-black text-center">
                                {name.length > 35 ? `${name.substring(0, 35)}` : name}
                              </span>
                            </div>

                            {/* locations */}

                            <span className="text-center uppercase">
                              {training_type !== 'virtual' && provided_service_locations?.data?.slice(0, 1)?.map(({ attributes: { location, address, city_and_state, id } }, index) => {
                                return (
                                  <>
                                    <small
                                      className="font-sf-regular text-primary-color font-normal text-xxs"
                                      key={id}>
                                      {location}
                                    </small><br />

                                    <small
                                      className="font-sf-regular text-primary-color font-normal text-xxs"
                                      key={id}>
                                      {city_and_state}
                                    </small>
                                  </>
                                )
                              })}
                            </span>

                            {training_type === 'virtual' && (
                              <a
                                href={connection_detail ? connection_detail : 'https://zoom.us/'}
                                target="_blank">
                                <small className="text-primary-color uppercase font-sf-regular font-normal text-xxs underline">
                                  {connection_detail ? connection_detail : 'https://zoom.us/'}
                                </small>
                              </a>
                            )}

                            <div className="text-center mt-1 max-location-height overflow-hidden">
                              <small className="font-sf-regular text-primary-color font-normal text-xxs uppercase">
                                {parseInt(duration)} mins
                              </small>

                              <small className="font-sf-regular text-primary-color font-normal text-xxs uppercase"> @ {priceFormatter(price)}</small>
                            </div>

                            <div className="mt-1">
                              <TrainingBadge trainingType={training_type} />
                            </div>

                            <span
                              style={{ fontSize: 12 }}
                              className="text-t-gray mt-2 text-xxs text-center font-sf-regular normal-case">
                              {discription && discription.substring(0, 160)}
                            </span>

                            {focusedService &&
                              focusedService.attributes &&
                              focusedService.attributes.provided_service_locations.data.length >
                              0 && (
                                <SelectLocationModal
                                  service={focusedService}
                                  visible={showSelectModal}
                                  locations={
                                    focusedService.attributes.provided_service_locations.data
                                  }
                                  selectedLocation={selectedLocation}
                                  selectedAddress={selectedAddress}
                                  setSelectModal={value => {
                                    setSelectModal(value);
                                  }}
                                  setLocationChoice={(location, address) => {
                                    setAddress(address);
                                    setLocation(location);
                                  }}
                                />
                              )}

                            <div className="w-full absolute bottom-0 left-0 p-2 bg-white rounded-lg">
                              {!all_slots_booked ? (
                                <button
                                  onClick={e => {
                                    e.stopPropagation();
                                    checkAvailabilityAssociated(id, status)
                                      ? servicePressed({
                                        id,
                                        attributes: {
                                          privacy_setting,
                                          name,
                                          image_url,
                                          duration,
                                          provided_service_locations,
                                          training_type,
                                          price,
                                          discription,
                                          all_slots_booked,
                                          slug,
                                        },
                                      })
                                      : notifyMe(id, name);
                                  }}
                                  className={`training-cta shadow-md ${checkAvailabilityAssociated(id, status) && 'bg-highlight-color'
                                    }`}>
                                  {checkAvailabilityAssociated(id, status)
                                    ? 'Book Now'
                                    : 'Notify Me'}
                                </button>
                              ) : (
                                <div className="flex w-full">
                                  <button
                                    disabled={true}
                                    className="training-cta cursor-not-allowed mr-1 opacity-25">
                                    Sold Out
                                  </button>

                                  <button
                                    onClick={e => {
                                      e.stopPropagation();
                                      notifyMe(id, name);
                                    }}
                                    className="training-cta">
                                    Notify me
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div fluid>
                    {loader ? (
                      <Loader />
                    ) : (
                      <h3 className="text-center text-black text-sm">No Trainings Available.</h3>
                    )}
                  </div>
                )}
              </div>
            </div>

            <button
              onClick={viewFullSchedule}
              className="training-cta w-48 self-center bg-transparent border-2 border-gray-400 shadow-md my-4 capitalize">
              View full schedule
            </button>
          </div>
        </div>
      ) : (
        <div>
          {!loader && !athelete ? (
            <NotFound />
          ) : (
            <div className="loader-container">
              <Loader />
            </div>
          )}
        </div>
      )}

      <AuthModal
        visible={showAuthModal}
        guest={guest}
        loginModal={loginModal}
        showLoginModal={value => showLoginModal(value)}
        setAuthModal={value => {
          setAuthModal(value);
          setGuest(false);
        }}
        onSuccess={() => {
          setAuthModal(false);
          setGuest(false);
        }}
      />

      <NotificationsEnabled
        show={success}
        serviceName={serviceName}
        athlete={athelete}
        close={() => {
          setSuccess(false);
        }}
      />

      {notifyEmail && (
        <NotifyEmail
          serviceName={serviceName}
          athlete={athelete}
          show={notifyEmail}
          onConfirm={(email, contactNumber, name) => {
            sendNotificationCredentials(selectedService, email, contactNumber, name);
          }}
          disableNotify={() => setNotifyEmail(false)}
        />
      )}
    </div>
  );
}

export default observer(TrainerProfile);
