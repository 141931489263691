import React, { useState, useContext } from 'react';
import Loader from '../../Shared/Loader';
import { notify } from '../../../utils/utilities';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { InputLabel } from '../../Shared';

function ForgotPassword(props) {
  const store = useContext(StoreContext);

  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setLoader(true);

    store.userStore.getResetPassword(email).then(result => {
      setLoader(false);

      if (result && result.response) {
        setEmail('');
        notify(result.response);
      }
    });
  };

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-8 bg-main-background">
      <div className="w-full px-10 sm:px-0 sm:w-1/2 md:w-1/3">
        <div className="bg-white ml-auto mr-auto rounded-lg overflow-scroll max-h-screen shadow-lg">
          <div className="flex-row d-flex justify-center">
            <div className="">
              <div className="justify-center w-full py-3">
                <h5 className="medium-black text-xl text-center">Forgot Your Password?</h5>
              </div>

              <div>
                <div className="px-8 mb-4 text-center">
                  <p className="primary-text-regular normal-case">
                    We get it, stuff happens. Just enter your email address below and we'll send you
                    a link to reset your password!
                  </p>
                </div>

                <form className="px-8 pt-6 pb-8 rounded" onSubmit={e => onSubmit(e)}>
                  <div className="mb-6">
                    <InputLabel label="Email" />

                    <input
                      className="text-input"
                      id="email"
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Enter Email Address..."
                      required
                    />
                  </div>

                  <div className="my-4 text-center">
                    <button className="primary-dark-button w-full" type="submit">
                      Reset Password
                      {loader && (
                        <div className="ml-2">
                          <Loader small color="white" />
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ForgotPassword);
