import React, { useState, useContext, useEffect } from 'react';
import Loader from '../../Shared/Loader';
import { observer } from 'mobx-react';
import { sport_light } from '../../../assets/img';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { useHistory, Link } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-image';
import Select from 'react-select';
import { athleteTypes, genders, gender, customStyles, removeSeparator } from '../../../constants';
import { TrainingAndLocationSelect, SearchLocationInput } from '../../Shared';
import {
  formatTime,
  formatDate,
  convertDate,
  getAthleteName,
  ifCurrentAthlete,
  removeUnderScoreFromString,
} from '../../../utils/utilities';
import InfiniteScroll from 'react-infinite-scroller';
import { SchoolBanner, NextAvailability } from './index';

const trainerOtherInfo = trainerInfo =>
  trainerInfo
    .filter(info => info)
    .map((info, index) => (info && index === 0 ? info : ` • ${info}`));

const ActionButton = ({ nextAvailability, bookButtonClick }) => (
  <button
    onClick={e => {
      e.stopPropagation();
      if (nextAvailability) bookButtonClick();
    }}
    className="bg-highlight-color primary-button md:w-32 w-full mt-3 md:mt-0">
    {nextAvailability ? 'Book Now' : 'Notify'}
  </button>
);

const SectionHeading = ({ title, primary }) => {
  return (
    <small className={`medium-black text-lg ${primary && 'text-primary-color'}`}>{title}</small>
  );
};

const AthleteInfo = props => {
  return props.athletes && props.athletes.length > 0 ? (
    props.athletes.map(
      (
        {
          attributes: {
            id,
            profile_image,
            first_name,
            last_name,
            sport_name,
            position,
            passing_year,
            city,
            state,
            next_availability,
            provided_services,
            sport,
            slug,
            jersey_number,
            athlete_type,
          },
        },
        index
      ) => (
        <div key={index}>
          <div className="p-5 pl-5 pr-5 border-b border-light-gray px-2 mr-1 flex flex-col items-center md:flex-row justify-between w-full">
            <div className="flex md:flex-row flex-col items-center w-full md:w-3/4">
              <ProgressiveImage src={profile_image} placeholder={sport_light}>
                {src => (
                  <img
                    src={src ? src : sport_light}
                    className="h-24 w-24 rounded-full mb-2 border object-cover"
                  />
                )}
              </ProgressiveImage>

              <div className="flex flex-col md:flex-row justify-between flex-wrap ml-3 w-full md:text-left text-center">
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="md:text-left text-center capitalize">
                    <Link to={`/${slug}`}>{getAthleteName({ first_name, last_name, passing_year })}</Link>
                  </div>

                  <small className="primary-text-regular">{sport_name}</small>

                  <small className="primary-text-regular">{props.school.name}</small>

                  <small className="primary-text-regular">
                    {athlete_type && removeUnderScoreFromString(athlete_type)}
                  </small>

                  <div className="flex md:justify-start justify-center">
                    <small className="primary-text-regular text-t-gray">
                      {trainerOtherInfo([
                        ...position,
                        passing_year,
                        jersey_number && `#${jersey_number}`,
                      ])}
                    </small>
                  </div>
                </div>

                <div className="flex flex-col md:w-1/2 justify-center md:border-dots pl-0 md:pl-4">
                  <small className="primary-text-regular text-t-gray">
                    Current Location: {city + ', ' + state}
                  </small>

                  {next_availability && (
                    <small className="primary-text-regular text-t-gray">
                      {'Next Available: '}
                      {`${convertDate(
                        formatDate(next_availability.repetition_from)
                      )} from ${formatTime(next_availability.from_time)} to ${formatTime(
                        next_availability.to_time
                      )}`}
                    </small>
                  )}
                </div>
              </div>
            </div>

            <ActionButton
              id={id}
              history={props.history}
              bookButtonClick={() =>
                props.bookButtonClick(slug, provided_services, next_availability.selected_trainings)
              }
              nextAvailability={next_availability}
            />
          </div>
        </div>
      )
    )
  ) : (
    <div className="loader-container">
      <small className="primary-text-regular text-lg">No athletes available</small>
    </div>
  );
};

const Filter = ({ options, onChange, placeholder, props }) => (
  <div className="w-full md:flex-1 mr-0 mt-3 md:mr-3 md:mt-0 bg-white rounded-md">
    <Select
      {...props}
      styles={customStyles}
      components={removeSeparator}
      isSearchable={false}
      placeholder={placeholder}
      className="primary-text-regular"
      options={options}
      onChange={option => onChange(option)}
    />
  </div>
);

function School(props) {
  const store = useContext(StoreContext);
  const history = useHistory();
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [all, setAll] = useState(true);
  const [loader, setLoader] = useState(false);
  const [sportType, setSportType] = useState('');
  const [athleteType, setAthleteType] = useState(null);
  const [loc, setLoc] = useState('');
  const [parseSearch, setParseSearch] = useState(null);
  const [selectionModal, setSelectionModal] = useState(null);
  const [selectedAthleteTrainings, setSelectedAthleteTrainings] = useState(null);

  let athletes = toJS(store.atheleteStore.atheletes);
  let athlete = toJS(store.atheleteStore.athelete);
  let athletePagy = toJS(store.atheleteStore.athletePagy);
  let sports = toJS(store.userStore.sports);
  let school = toJS(store.atheleteStore.school);

  useEffect(() => {
    if (!male && !female && all && sportType === '' && athleteType === null && parseSearch === null)
      getSchoolFromUrl();
    else {
      if (!(school && school.slug === getSlug())) setLoader(true);
      checkFilters();
    }
    if (sports.length === 0) store.userStore.getSports();
  }, [male, female, all, sportType, athleteType, parseSearch]);

  const loadMore = async () => {
    if (athletePagy.next) {
      checkFilters(athletePagy.next);
    }
  };

  const showFullSchedule = () => {
    history.push(`/schools/${getSlug()}/schedule`);
  };

  const getSlug = () => {
    let {
      match: { params },
    } = props;
    return params.school_slug;
  };

  const getSchoolFromUrl = () => {
    if (!(school && school.slug === getSlug())) setLoader(true);
    store.atheleteStore.getSchool(getSlug()).then(response => {
      response.id && setLoader(false);
    });
  };

  const checkFilters = (page = 1) => {
    let payload = {
      q: {
        college_slug_eql: getSlug(),
      },
    };

    if (male || female) {
      Object.assign(payload, {
        q: {
          ...payload.q,
          gender_eq: male ? gender.MALE : gender.FEMALE,
        },
      });
    }

    if (male && female) {
      Object.assign(payload, {
        q: {
          ...payload.q,
          gender_eq: '',
        },
      });
    }

    if (sportType !== '' && sportType.id !== 0) {
      Object.assign(payload, {
        q: {
          ...payload.q,
          sport_id_eq: parseInt(sportType.id),
        },
      });
    }

    if (parseSearch !== null) {
      Object.assign(payload, {
        q: {
          ...payload.q,
          athlete_search_eql:
            parseSearch.state +
            ' ' +
            parseSearch.city +
            ' ' +
            parseSearch.street +
            ' ' +
            parseSearch.zip,
        },
      });
    }

    if (athleteType && athleteType.value !== 'all') {
      Object.assign(payload, {
        q: {
          ...payload.q,
          athlete_type_eq: athleteType.value,
        },
      });
    }
    store.atheleteStore.getAtheletes(page, payload).then(() => setLoader(false));
  };

  const allSelected = () => {
    setAll(!all);
    setMale(false);
    setFemale(false);
    setSportType('');
    setAthleteType(null);
    setLoc('');
    setParseSearch(null);
  };

  const genderSelection = gender => {
    if (gender === 1) {
      allSelected();
    } else if (gender === 2) {
      setMale(true);
      setFemale(false);
      setAll(false);
    } else if (gender === 3) {
      setFemale(true);
      setMale(false);
      setAll(false);
    }
  };

  const bookButtonClick = async (slug, providedServices, selectedTrainings) => {
    if (
      providedServices.data &&
      providedServices.data.length === 1 &&
      providedServices.data[0].attributes.provided_service_locations.data.length === 1
    ) {
      if (providedServices.data[0].attributes.training_type !== 'virtual')
        store.userStore.setLocationAddress(
          providedServices.data[0].attributes.provided_service_locations.data[0].attributes.location.trim(),
          providedServices.data[0].attributes.provided_service_locations.data[0].attributes.address.trim(),
          providedServices.data[0].id
        );

      history.push({
        pathname: `/${slug}/${providedServices.data[0].attributes.slug.split('-')[1]}`,
      });
    }
    setSelectionModal(slug);

    let trainings =
      providedServices &&
      providedServices.data.filter(({ attributes: { id } }) => selectedTrainings.includes(id));

    setSelectedAthleteTrainings({ data: trainings });
  };

  if (sports && sports[0] && sports[0].label !== 'All')
    sports.unshift({ label: 'All', value: 'All', id: 0 });

  return (
    <div className="flex flex-col content-center items-center p-4 pt-24 md:pt-16 bg-main-background min-h-screen">
      <div className="shadow-md mt-3 overflow-hidden w-full md:w-3/4 rounded-lg">
        {school && <SchoolBanner school={school} />}
      </div>

      <div className="bg-main-background w-full md:w-3/4 mt-6 overflow-x-scroll py-2">
        <SectionHeading title="Upcoming Trainings" primary />

        {!loader && school && (
          <NextAvailability
            showFullSchedule={() => showFullSchedule()}
            bookButtonClick={bookButtonClick}
            athletes={
              male || female || sportType !== '' || athleteType || parseSearch
                ? athletes
                : school && school.athletes && school.athletes.data
            }
          />
        )}
      </div>

      <div className="w-full rounded-lg flex items-start content-center justify-center my-3">
        <div className="flex flex-col w-full md:w-3/4 direction-change items-start rounded-l-md rounded py-2 justify-between">
          <div style={{ height: 1, backgroundColor: '#D7DAE2' }} className="w-full mb-3" />

          <SectionHeading title="Trainers" />

          <div className="container-wrap">
            <Filter
              {...props}
              placeholder="Select gender"
              options={genders}
              onChange={gender => genderSelection(gender.value)}
            />

            <Filter
              {...props}
              placeholder="Select sport"
              options={sports}
              onChange={sport => setSportType(sport)}
            />

            <Filter
              {...props}
              placeholder="Athlete type"
              options={athleteTypes}
              onChange={type => setAthleteType(type)}
            />

            <div className="w-full md:w-2/5 md:mr-3 mt-3 md:mt-0">
              <SearchLocationInput
                value={loc}
                setLoc={loc => setLoc(loc)}
                setParseSearch={search => setParseSearch(search)}
              />
            </div>
          </div>
        </div>
      </div>

      {!loader && school ? (
        <div className="w-full flex flex-col items-center content-center justify-center my-3">
          <div className="flex flex-col w-full md:w-3/4 items-center pb-2 justify-between">
            <div
              style={{ borderRadius: 25 }}
              className="flex w-full bg-white shadow-md flex-wrap justify-between">
              <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                className="w-full"
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                hasMore={athletePagy && athletePagy.next}>
                <AthleteInfo
                  athletes={
                    male || female || sportType !== '' || athleteType || parseSearch
                      ? athletes
                      : school && school.athletes && school.athletes.data
                  }
                  history={history}
                  bookButtonClick={(slug, providedServices, selectedTrainings) =>
                    bookButtonClick(slug, providedServices, selectedTrainings)
                  }
                  school={school}
                />
              </InfiniteScroll>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      {selectionModal && (
        <TrainingAndLocationSelect
          visible={selectionModal}
          slug={selectionModal}
          trainings={selectedAthleteTrainings}
          closeModal={() => {
            setSelectionModal(null);
            setSelectionModal(null);
            setSelectedAthleteTrainings(null);
          }}
        />
      )}
    </div>
  );
}

export default observer(School);
