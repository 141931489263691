import React, { useState, useContext, useEffect, useRef, useMemo, Fragment } from 'react';
import { StoreContext } from 'index';
import Loader from 'components/Shared/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { TrayvoBlue } from '../../../assets/img';
import { observer } from 'mobx-react';
import moment from 'moment';
import { toJS } from 'mobx';
import ProgressiveImage from 'react-progressive-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import {
  priceFormatter,
  formatDate,
  getAthleteName,
  getAthleteImageSrc,
} from '../../../utils/utilities';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import TimeSlot from '../Profile/TimeSlot';
import { borderColor } from '../../../utils/utilities';
import SelectLocationModal from '../Profile/SelectLocationModal';
import { TrayvoBadge, TrainingBadge } from '../../Shared';
import momentTimezone from 'moment-timezone';
import { findWindows } from 'windows-iana';
import { getFormattedLocalDate, getFormattedLocalDateTime } from 'utils/helpers/timeZone';

const AthleteInfo = ({ name, athlete }) => (
  <div className="flex flex-col text-center overflow-hidden mt-2 items-center">
    {getAthleteImageSrc(athlete, 'trainerBanner')}

    <small
      style={{ fontSize: 15 }}
      className="font-sf-regular px-2 break-words w-32 capitalize mt-5 text-primary-color">
      {name}
    </small>
  </div>
);

function BookService(props) {
  const history = useHistory();
  let location = useLocation();
  const store = useContext(StoreContext);
  const [loader, setLoader] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [service, setService] = useState(null);
  const [showSelectModal, setSelectModal] = useState(null);
  const [selectedLocation, setLocation] = useState(null);
  const [selectedAddress, setAddress] = useState(null);

  let athelete = toJS(store.atheleteStore.athelete);
  let storeSelectedLocation = toJS(store.userStore.selectedLocation);
  let requestedBookings = toJS(store.atheleteStore.requestedBookings);
  let proccedDivRef = useRef(null);

  useEffect(() => {
    let {
      match: { params },
    } = props;
    store.atheleteStore
      .getAthelete(params.athleteSlug, `${params.athleteSlug}-${params.serviceSlug}`)
      .then(response => {
        let serviceFiltered =
          response.attributes &&
          response.attributes.provided_services.data.filter(
            item => item.attributes.slug === `${params.athleteSlug}-${params.serviceSlug}`
          );
        setService(serviceFiltered && serviceFiltered[0]);
        setLoader(false);
      });
  }, []);

  const scrollToBottom = () => {
    proccedDivRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const bookAthelete = () => {
    if (
      service.attributes.training_type !== 'virtual' &&
      !(service.id == (storeSelectedLocation && storeSelectedLocation.trainingId))
    ) {
      store.userStore.resetLocationAddress();
      setSelectModal(true);
    } else moveToCheckout();
  };

  const moveToCheckout = () => {
    localStorage.setItem('serviceLocation', JSON.stringify(location));

    history.push({
      pathname: `/bookings/checkout`,
      state: {
        selectedSlot: selectedSlot,
        bookingDate: selectedSlot.referenceDate,
        providedService: service,
      },
    });
  };

  useMemo(() => {
    if (service) {
      store.userStore.setLocationAddress(selectedLocation, selectedAddress, service.id);
      moveToCheckout();
    }
  }, [selectedLocation]);

  const rearrangeSlots = slots => {
    let newSlots = {};

    slots.forEach((slot, index) => {
      const slotDate = slot[0];
      slot[1].forEach(timeSlot => {
        const localDate = getFormattedLocalDate(timeSlot.training_start_date_time);
        const localDateTime = getFormattedLocalDateTime(timeSlot.training_start_date_time);

        if (!newSlots[localDate]) {
          newSlots[localDate] = [{ localDateTime, referenceDate: slotDate, ...timeSlot }];
        } else {
          newSlots[localDate].push({ localDateTime, referenceDate: slotDate, ...timeSlot });
        }
      });
    });

    return Object.entries(newSlots);
  };

  let slots = [];
  let rearrangedSlots = [];
  if (athelete && athelete.generated_slots) {
    slots = Object.entries(athelete && athelete.generated_slots).filter(([key, value]) => {
      return athelete.generated_slots[key].length > 0;
    });
  }

  //Below filter method is to filter all the slots which does not have the provided_service id inside the selected trainings array
  //received. Work for availability tags feature
  if (service) {
    slots = slots.filter(slot => {
      let temp =
        slot[1] &&
        slot[1].filter(({ selected_trainings, from_time, free_slot }) => {
          return selected_trainings.includes(parseInt(service.id)) && from_time && free_slot > 0;
        });
      return temp && temp.length > 0;
    });

    rearrangedSlots = rearrangeSlots(slots);
  }

  console.log('service', service);

  return (
    <div>
      <div className="bg-main-background min-h-screen px-5 py-3 overflow-scroll flex justify-center">
        {!loader ? (
          <div className="flex flex-col justify-center px-2 items-center mt-24 md:mt-20">
            {service && (
              <div
                className="bg-white absolute h-48 w-32 hide-athlete-info shadow-md"
                style={{
                  left: 30,
                  top: 65,
                  borderBottomLeftRadius: 100,
                  borderBottomRightRadius: 100,
                }}>
                <AthleteInfo athlete={athelete} name={getAthleteName(athelete)} />
              </div>
            )}

            {service ? (
              <div className="flex flex-col pb-16">
                <div className="flex flex-col md:flex-row bg-white md:py-12 md:px-10 shadow-lg w-auto overflow-scroll rounded-lg relative">
                  <div className="absolute top-0 right-0">
                    <TrayvoBadge />
                  </div>

                  <div className="flex justify-center md:p-10">
                    <div
                      className="flex flex-col mt-3 justify-center items-center md:mt-0"
                      style={{ width: '300px' }}>
                      <ProgressiveImage
                        src={
                          service.attributes.image_url ? service.attributes.image_url : TrayvoBlue
                        }
                        placeholder={TrayvoBlue}>
                        {src => (
                          <img
                            src={src}
                            className={`h-24 w-24 rounded-full object-cover mr-3 border-2 ${borderColor(
                              service.attributes.training_type
                            )}`}
                          />
                        )}
                      </ProgressiveImage>

                      <div className="flex flex-col w-full mt-4 justify-center items-center">
                        <small className="text-left regular-black text-xl capitalize">
                          {service.attributes.name}
                        </small>

                        {service.attributes.training_type !== 'virtual' &&
                          service.attributes.provided_service_locations.data
                            .slice(0, 1)
                            .map(({ attributes: { location, address, id } }) => {
                              return (
                                <Fragment key={id}>
                                  <small className="text-primary-color uppercase font-sf-regular font-normal text-xs text-center mt-2">
                                    {location}
                                  </small>

                                  <small className="text-primary-color uppercase font-sf-regular font-normal text-xs text-center mt-2">
                                    {address.replace(', USA', '')}
                                  </small>
                                </Fragment>
                              );
                            })}

                        {service.attributes.training_type === 'virtual' && (
                          <small className="text-primary-color uppercase font-sf-regular font-normal text-md underline">
                            {service.attributes.connection_detail}
                          </small>
                        )}

                        <div className="text-center max-location-height overflow-hidden">
                          <small className="primary-text-regular text-md mt-1 uppercase">
                            {parseInt(service.attributes && service.attributes.duration)} mins
                          </small>

                          <small className="primary-text-regular text-md mt-1 uppercase ml-1">
                            @ {`$${parseInt(service.attributes && service.attributes.price)}`}
                          </small>
                        </div>

                        <TrainingBadge trainingType={service.attributes.training_type} />

                        {service.attributes.training_type === 'group' && (
                          <span className="md:flex primary-text-regular opacity-50 my-1">
                            Max number of spots: {service.attributes.max_seats}
                          </span>
                        )}

                        <span className="md:flex text-md font-sf-regular text-t-gray text-center opacity-75 my-1 mt-2">
                          {service.attributes.discription}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ width: '1px', height: 'auto' }}
                    className="bg-gray-400 my-8 hidden md:flex"
                  />

                  <div className="flex flex-col md:flex-row md:p-10">
                    <div>
                      {slots.length > 0 ? (
                        <div>
                          <p className="text-red-400 text-center p-2">
                            Click date and then select time to complete booking.
                          </p>

                          <Flatpickr
                            hidden={true}
                            onDayCreate={function (dObj, dStr, fp, dayElem) {
                              if (
                                moment().format('YYYY-MM-DD') ===
                                moment(dayElem.dateObj).format('YYYY-MM-DD')
                              )
                                dayElem.className += ' current-date';
                              else if (
                                Object.keys(athelete.generated_slots)
                                  .filter(function (key, index) {
                                    return athelete.generated_slots[key].length === 0;
                                  })
                                  .includes(formatDate(dayElem.dateObj)) ||
                                moment(dayElem.dateObj).format() <
                                moment().subtract(1, 'days').format()
                              ) {
                                dayElem.className += ' booked-date';
                              } else {
                                dayElem.className += ' bold-date';
                              }
                            }}
                            options={{
                              inline: true,
                              defaultDate:
                                rearrangedSlots.length > 0 &&
                                rearrangedSlots
                                  .slice()
                                  .sort(
                                    (slotA, slotB) => new Date(slotA[0]) - new Date(slotB[0])
                                  )[0][0],
                              // minDate: new Date(),
                              enable: rearrangedSlots.map(slot => slot[0]),
                              monthSelectorType: 'static',
                              yearSelectorType: 'static',
                            }}
                            value={selectedDate ? selectedDate : moment().format('YYYY-MM-DD')}
                            onChange={(date, dateStr) => {
                              setSelectedDate(formatDate(date[0]));

                              if (
                                moment(date[0]).format() >= moment().subtract(1, 'days').format()
                              ) {
                                let filteredSlots = rearrangedSlots.filter(item => {
                                  return formatDate(item[0]) === formatDate(date[0]);
                                })[0][1];

                                let trainingSpecific = filteredSlots.filter(item => {
                                  return item.selected_trainings.includes(parseInt(service.id));
                                });

                                setSelectedTime(null);
                                setSelectedEntry(trainingSpecific);
                                scrollToBottom();
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <div className="mt-5 mr-5">
                          <small className="text-black font-sf-regular">
                            No slots available for the following athlete yet!
                          </small>
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col overflow-scroll max-h-screen ml-2">
                      {selectedDate && (
                        <div className="my-3">
                          <p className="regular-black text-primary-color">
                            {selectedDate
                              ? moment(formatDate(selectedDate)).format('dddd, MMMM Do')
                              : ' - - '}
                          </p>

                          <p className="text-xs text-t-gray">
                            <FontAwesomeIcon
                              icon={faMapPin}
                              size="md"
                              className="primary-color-icon mr-1 fa-w-18"
                            />
                            {findWindows(momentTimezone.tz.guess())}
                          </p>
                        </div>
                      )}

                      {selectedDate && (
                        <TimeSlot
                          entries={
                            selectedEntry && selectedEntry.filter(item => item.from_time).length > 0
                              ? selectedEntry.filter(
                                item =>
                                  item.from_time &&
                                  moment(item.localDateTime, 'YYYY-MM-DD h:mm A').isAfter(
                                    moment()
                                  )
                              )
                              : selectedEntry
                          }
                          trainingType={service.attributes.training_type}
                          selectedTime={selectedTime}
                          bookAthelete={() => bookAthelete()}
                          setSelectedTime={time => setSelectedTime(time)}
                          setSelectedSlot={entry => {
                            setSelectedSlot(entry);
                          }}
                          scrollToBottom={() => scrollToBottom()}
                          requestedBookings={requestedBookings}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  ref={proccedDivRef}
                  className="flex justify-between bg-white shadow-md p-3 my-2 w-auto rounded-lg">
                  <button
                    className="secondary-button w-32"
                    onClick={() => {
                      history.goBack();
                    }}>
                    Back
                  </button>

                  {selectedTime && (
                    <button
                      disabled={selectedTime === null}
                      className={`primary-button w-32 ${!selectedTime && 'cursor-not-allowed'}`}
                      style={{ backgroundColor: '#042e60', color: 'white' }}
                      onClick={() => bookAthelete()}>
                      Next
                    </button>
                  )}
                </div>

                {service &&
                  service.attributes &&
                  service.attributes.provided_service_locations.data.length > 0 && (
                    <SelectLocationModal
                      visible={showSelectModal}
                      locations={service.attributes.provided_service_locations.data}
                      selectedLocation={selectedLocation}
                      selectedAddress={selectedAddress}
                      setSelectModal={value => {
                        setSelectModal(value);
                      }}
                      setLocationChoice={(location, address) => {
                        setAddress(address);
                        setLocation(location);
                      }}
                    />
                  )}

                <div ref={proccedDivRef} className="flex justify-center w-auto font-sf-regular">
                  <a
                    class="bg-white shadow-md p-3 rounded-lg text-grey-lighter"
                    style={{ fontSize: 13, color: 'grey' }}
                    href="mailto:train@trayvo.com"
                    target="_blank">
                    Need Help? Contact us
                  </a>
                </div>
              </div>
            ) : (
              <div className="h-64 flex bg-white flex-col text-center px-20 justify-center content-center items-center">
                <h3>Hi!</h3>
                <h2 className="font-bold">This Trayvo URL is not valid.</h2>

                <small className="mt-3 text-sm">
                  Looks like this training is temporarily disabled by the athlete.
                </small>
              </div>
            )}
          </div>
        ) : (
          <div className="loader-container">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}
export default observer(BookService);
